import Button from '@/components/Button/Button';
import { useState } from 'react';
import { DifficultiesFormType } from '../../types';

export const DifficultiesForm = ({ nextTab }: DifficultiesFormType) => {
  const [difficultiesFeedback, setDifficultiesFeedback] = useState('');

  return (
    <div className="mt-[32px]">
      <span className="block font-F37Bolton-Medium text-[20px]">
        Can you tell us what happened?
      </span>
      <span className="mt-[16px] block font-F37Bolton-Medium">
        Please describe the problem in a few words to help us do better next time
      </span>

      <textarea
        className="mt-4 h-[120px] w-full rounded-md border p-2 text-base leading-6 text-gray-700"
        placeholder="Add text"
        value={difficultiesFeedback}
        onChange={e => setDifficultiesFeedback(e.target.value)}
      />
      <Button
        variant="primary"
        type="submit"
        children="Next"
        width="w-[335px] lg:w-[400px]"
        parentClasses="mt-[32px]"
        isDisabled={!difficultiesFeedback.length}
        onPress={() => nextTab({ difficultiesFeedback, setDifficultiesFeedback })}
      />
    </div>
  );
};
