import { useAuth0 } from '@auth0/auth0-react';
import { ArrowRightEndOnRectangleIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useCalendlyEventListener } from 'react-calendly';
import { useSelector } from 'react-redux';

import { RootState } from '@/redux/store';
import { checkAccountForSubscriptionReminder, impersonationLogoutHandler } from '@/utils/helpers';

import { ACCOUNT_BILLING, LOGOUT } from './constants';

export const usePublicHeader = () => {
  const { logout } = useAuth0();
  const [isOpenedLogoutModal, setIsOpenedLogoutModal] = useState(false);
  const [isOpenMyAccountModal, setIsOpenMyAccountModal] = useState(false);
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);
  const [isCalendlyLoading, setIsCalendlyLoading] = useState(true);

  const user = useSelector((state: RootState) => state?.auth?.user?.account);
  const subscriptionInfo = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const handleLogout = async () => {
    try {
      localStorage.clear();
      impersonationLogoutHandler();
      logout();
      window.fcWidget.user.clear().then(
        function () {
          console.log('FcWidget User cleared');
        },
        function () {
          console.error('FcWidget User Not cleared');
        },
      );
      window.FreshworksWidget('destroy');
    } catch (error) {
      alert(error);
    }
  };

  const handleHeaderMenuActions = (action: string | number) => {
    if (action === LOGOUT) {
      setIsOpenedLogoutModal(true);
    } else if (action === ACCOUNT_BILLING) setIsOpenMyAccountModal(true);
  };

  const handleGotoDashboardClick = () => {
    setIsOpenMyAccountModal(false);
  };

  useCalendlyEventListener({ onEventTypeViewed: () => setIsCalendlyLoading(false) });

  const menuItemList = [
    {
      label: 'Accounts and billing',
      key: ACCOUNT_BILLING,
      startIcon: <Cog6ToothIcon className="size-[20px]" />,
      className: '',
    },
    {
      label: 'Logout',
      key: LOGOUT,
      startIcon: <ArrowRightEndOnRectangleIcon className="size-[20px]" />,
      className: '',
    },
  ];
  const handleCalendlyModal = () => {
    setIsCalendlyModalOpen(!isCalendlyModalOpen);
    setIsCalendlyLoading(true);
  };

  const bannerInfo = checkAccountForSubscriptionReminder(subscriptionInfo);

  const {
    bannerVariantToShow,
    gracePeriodEndDate,
    nextProcessingDate,
    remainingDays,
    isFreeTrailOver,
  } = bannerInfo || {};
  return {
    handleLogout,
    isOpenedLogoutModal,
    setIsOpenedLogoutModal,
    handleHeaderMenuActions,
    isOpenMyAccountModal,
    setIsOpenMyAccountModal,
    user,
    menuItemList,
    handleGotoDashboardClick,
    handleCalendlyModal,
    isCalendlyModalOpen,
    isCalendlyLoading,
    bannerVariantToShow,
    gracePeriodEndDate,
    nextProcessingDate,
    remainingDays,
    isFreeTrailOver,
  };
};
