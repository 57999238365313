// import url from 'utils/url';
import axios from '../axios';

export interface contactorProfile {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  businessName: string;
  ssn: string;
  ein: string;
  contractorType: number;
}

export interface Address {
  streetAddress: string;
  aptSteNumber: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface EmploymentInformation {
  jobTitle: string;
  startDate: Date;
  phoneNumber: string;
  address: Address;
  email: string;
}

export interface CompensationInformation {
  wageType: number;
  hourlyRate: number;
  isPaidThisYear: boolean;
  totalPaymentsYtd: number;
}

// Fetch Contractor’s Details

export const getContractorsDetails = (companyId: string, id: string) => {
  return axios.get(`/company/${companyId}/contractor/${id}`);
};

// Fetch Contractor’s employment information
export const getEmploymentInformation = (companyId: string, contractorId: string) => {
  return axios.get(`/company/${companyId}/contractor/${contractorId}/employment`);
};

// Fetch Contractor’s compensation information
export const getCompensationInformation = (companyId: string, contractorId: string) => {
  return axios.get(`/company/${companyId}/contractor/${contractorId}/compensation`);
};

// Update Contractor’s Details
export const updateContractorsDetails = (
  companyId: string,
  id: string,
  payload: contactorProfile,
) => {
  return axios.put(`/company/${companyId}/contractor/${id}`, payload);
};

// Activate a Contractor
export const activateContractor = (companyId: string, id: string) => {
  return axios.patch(`/company/${companyId}/contractor/${id}/activate`, {});
};

// Deactivate a Contractor
export const deactivateContractor = (companyId: string, contractorId: string) => {
  return axios.patch(`/company/${companyId}/contractor/${contractorId}/de-activate`, {});
};

// Create an incomplete Contractor
export const postCreateIncompleteContractor = (companyId: string, payload: contactorProfile) => {
  return axios.post(`/company/${companyId}/contractor/create-incomplete`, payload);
};

// Update Contractor’s employment information
export const putUpdateContractorsEmploymentInformation = (
  companyId: string,
  contractorId: string,
  payload: EmploymentInformation,
) => {
  return axios.put(`/company/${companyId}/contractor/${contractorId}/employment`, payload);
};

// Update Contractor’s compensation information
export const putUpdateCompensationInformation = (
  companyId: string,
  contractorId: string,
  payload: CompensationInformation,
) => {
  return axios.put(`/company/${companyId}/contractor/${contractorId}/compensation`, payload);
};
