import BillingHistoryCard from '../BillingHistoryCard/BillingHistoryCard';
import BillingHistoryTableRow from '../BillingHistoryTableRow/BillingHistoryTableRow';
import useBillingHistoryView from './useBillingHistoryView';

const BillingHistoryView = ({ data, isCardView = true }: any) => {
  const { isLoadingInvoice, isLoadingReceipt, handleAction } = useBillingHistoryView({
    invoice: data,
  });
  return isCardView ? (
    <BillingHistoryCard
      data={data}
      handleAction={handleAction}
      isLoading={isLoadingInvoice || isLoadingReceipt}
    />
  ) : (
    <BillingHistoryTableRow
      billData={data}
      handleAction={handleAction}
      isLoading={isLoadingInvoice || isLoadingReceipt}
    />
  );
};

export default BillingHistoryView;
