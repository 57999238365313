import { CreditCardIcon } from '@heroicons/react/24/outline';

import { ReactComponent as ChevronRightIcon } from '@/assets/chevronRight.svg';
import { ReactComponent as PayPalLogo } from '@/assets/PayPallLogoFull.svg';
import { ReactComponent as StripeLogo } from '@/assets/stripeLogo.svg';

import { LoaderComponent } from '@/components/ui';
import { AddStripeCard } from './components';
import usePaymentMethod from './usePaymentMethod';

type PaymentMethodProps = {
  onSuccess: () => void;
};

const PaymentMethod = ({ onSuccess }: PaymentMethodProps) => {
  const {
    handlePayPalClick,
    handleStripeClick,
    isStripeSelected,
    isPaypalLoading,
    cancelStripeCardHandler,
  } = usePaymentMethod();

  return isStripeSelected ? (
    <div>
      <AddStripeCard onSuccess={onSuccess} onClose={cancelStripeCardHandler} />
    </div>
  ) : (
    <div className="flex size-full flex-col items-start">
      <div className="flex w-full flex-col items-center">
        <div className="rounded-full bg-green-100 p-3">
          <CreditCardIcon className="size-6 text-green-500" aria-label="Credit Card Icon" />
        </div>

        <div className="mt-3 flex flex-col gap-2">
          <h3 className="text-center font-F37Bolton-Medium text-2xl">Update payment method</h3>
          <p className="text-center text-gray-400">Choose your preferred payment method.</p>
        </div>
      </div>

      <div className="my-5 w-full space-y-3">
        <div
          onClick={handlePayPalClick}
          className="flex w-full cursor-pointer items-center justify-between rounded-2xl border p-5 transition hover:bg-gray-50"
          role="button"
          tabIndex={0}
          aria-label="Select PayPal as payment method"
        >
          <PayPalLogo className="h-8 w-20" aria-label="PayPal Logo" />

          {isPaypalLoading ? (
            <LoaderComponent classNames="w-7" height={30} />
          ) : (
            <ChevronRightIcon />
          )}
        </div>

        <div
          onClick={handleStripeClick}
          className="flex w-full cursor-pointer items-center justify-between rounded-2xl border p-6 transition hover:bg-gray-50"
          role="button"
          tabIndex={0}
          aria-label="Select Stripe as payment method"
        >
          <StripeLogo className="h-6 w-16" aria-label="Stripe Logo" />

          <ChevronRightIcon />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
