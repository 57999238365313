import { useCallback, useEffect, useMemo, useState } from 'react';

import { TabType, UseTabsType } from './types';

const useTabs = ({ initialTab, tabs: initialTabs }: UseTabsType) => {
  const [currentTab, setCurrentTab] = useState<string | null>(null);
  const tabs: TabType[] = useMemo(
    () => (Array.isArray(initialTabs) ? initialTabs : []),
    [initialTabs],
  );

  const visibleTabs: TabType[] = useMemo(() => tabs.filter(tab => !tab.hidden), [tabs]);

  const visibleTabsKey = useMemo(() => {
    const tabIds = Array.isArray(visibleTabs) ? visibleTabs.map(item => item.id) : [];
    return tabIds.toString();
  }, [visibleTabs]);

  const currentVisibleTabIndex = visibleTabs.findIndex(tab => tab.id === currentTab);

  useEffect(() => {
    // Check if the provided currentTab or the initialTab exist in the tabs or not.
    // If not, then set first tab to the currentTab
    const isCurrentTabExist = tabs.some(tab => tab.id === currentTab);
    const isInitialTabExist = tabs.some(tab => tab.id === initialTab);

    if (tabs.length) {
      if (!currentTab) {
        if (initialTab && isInitialTabExist) {
          setCurrentTab(initialTab);
        } else if (tabs.length) {
          setCurrentTab(tabs[0].id);
        }
      } else {
        if (!isCurrentTabExist) {
          setCurrentTab(tabs[0].id);
        }
      }
    }
  }, [initialTab, currentTab, tabs]);

  const navigateToNextTab = useCallback(() => {
    const nextTabIndex = currentVisibleTabIndex + 1;
    const isNextTabAvailable = visibleTabs.length > nextTabIndex;

    if (isNextTabAvailable) {
      setCurrentTab(visibleTabs[nextTabIndex].id);
    }
  }, [visibleTabsKey, currentTab, currentVisibleTabIndex]);

  const navigateToPreviousTab = useCallback(() => {
    const previousTabIndex = currentVisibleTabIndex - 1;
    const isPreviousTabAvailable = previousTabIndex >= 0;
    if (isPreviousTabAvailable) {
      setCurrentTab(visibleTabs[previousTabIndex].id);
    }
  }, [visibleTabsKey, currentTab, currentVisibleTabIndex]);

  const { isPreviousTabAvailable, isNextTabAvailable } = useMemo(() => {
    const nextTabIndex = currentVisibleTabIndex + 1;
    const isNextTabAvailable = visibleTabs.length > nextTabIndex;
    const previousTabIndex = currentVisibleTabIndex - 1;
    const isPreviousTabAvailable = previousTabIndex >= 0;

    return { isNextTabAvailable, isPreviousTabAvailable };
  }, [visibleTabsKey, currentTab, currentVisibleTabIndex]);

  const setCustomTab = (tab: string) => {
    setCurrentTab(tab);
  };

  return {
    navigateToNextTab,
    navigateToPreviousTab,
    isPreviousTabAvailable,
    isNextTabAvailable,
    tabs,
    currentTab,
    setCurrentTab: setCustomTab,
  };
};

export default useTabs;
