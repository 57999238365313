import { postCall } from '@/api/axios';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { POST_CANCEL_SUBSCRIPTION } from './apiRoutes';
import { Data, feedbackForCaseFour, reasonMappings, usePostCancelSubscriptionType } from './types';

const handleTransformReason = (reason: string) => {
  return reasonMappings[reason] || 'Unknown reason';
};

const handleTransformFeedback = (reason: string, options: string[] | number | string): string => {
  switch (reason) {
    case '1':
    case '2':
      return options instanceof Array
        ? `User faced difficulties with: ${options.join(', ')}`
        : `Missing features: ${options}`;
    case '3':
      return typeof options === 'number'
        ? `User pays ${options}$ to current payroll provider`
        : `User does not have a provider`;
    case '4':
      if (typeof options === 'number' || options === 'other') {
        return feedbackForCaseFour(options);
      } else {
        console.warn('Invalid type for case 4 feedback:', options);
        return 'Invalid feedback option';
      }
    default:
      return '';
  }
};

const usePostCancelSubscription = ({ onClose }: usePostCancelSubscriptionType) => {
  const dispatch = useDispatch();

  const postCancelSubscription = async ({ data, cancellationActionType }: Data) => {
    try {
      const { lookingForOther } = data;

      const result = await postCall(POST_CANCEL_SUBSCRIPTION(), {
        reason: handleTransformReason(data?.reason),
        otherReason: data?.otherReason,
        feedback: handleTransformFeedback(
          data?.reason,
          data?.features ??
            data?.difficulties ??
            (data?.reason === '3' && data?.amount ? data?.amount : data?.noProvider) ??
            data?.lookingFor,
        ),
        otherFeedback: lookingForOther ?? data?.otherInterest ?? data?.difficultiesFeedback ?? '',
        cancellationActionType,
      });

      return { result, cancellationActionType };
    } catch (error) {
      console.error('Error posting cancellation:', error);
      throw error;
    }
  };

  const { mutate, status } = useMutation({
    mutationFn: postCancelSubscription,
    onSuccess: async data => {
      if (data.cancellationActionType === 'cancel_subscription') {
        await dispatch.auth.getSubscriptionStatus();
        onClose();
      }
    },
  });

  return { mutate, status };
};

export default usePostCancelSubscription;
