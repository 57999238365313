import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UseVerticalStepperSidebarType } from './types';

const useVerticalStepperSidebar = ({
  steps,
  defaultStep,
  handleClick,
}: UseVerticalStepperSidebarType) => {
  const [currentStep, setCurrentStep] = useState(defaultStep);

  const location = useLocation();
  const currentPath = location.pathname;

  // Other logic that we can add later

  useEffect(() => {
    const selectedStep = steps.find(
      step =>
        currentPath?.includes(step.key) ||
        (step.additionalKeysForRoute &&
          Array.isArray(step.additionalKeysForRoute) &&
          step.additionalKeysForRoute.some(item => currentPath?.includes(item))),
    )?.key;
    setCurrentStep(selectedStep || defaultStep);
  }, [currentPath, steps, defaultStep]);
  return { steps, currentStep, handleClick };
};

export default useVerticalStepperSidebar;
