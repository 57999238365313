import React from 'react';
import { Controller } from 'react-hook-form';

import Dropdown from '@/components/Dropdown/Dropdown';

import { FormSelectProps } from './types';

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  isRequired,
  rules,
  isDisabled,
  defaultValue = '',
  shouldUnregister = true,
  ...rest
}) => (
  <Controller
    name={name}
    shouldUnregister={shouldUnregister}
    disabled={Boolean(isDisabled)}
    defaultValue={defaultValue}
    render={({ field: { value, ...restField }, fieldState: { error } }) => (
      <Dropdown
        errorMessage={error && !!error && error.message}
        isRequired={Boolean(isRequired || rules?.required)}
        isDisabled={isDisabled}
        {...rest}
        {...restField}
        value={value ?? ''}
      />
    )}
    rules={rules}
  />
);

export default FormSelect;
