import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageResizer from 'react-image-file-resizer';

import { ReactComponent as PlusIcon } from '@/assets/plus.svg';

interface Props {
  onChange: (file: FileObject) => void;
  preview: boolean;
}

interface FileObject extends File {
  preview?: string;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const InputDropZone: React.FC<Props> = ({ onChange, preview = true }) => {
  // eslint-disable-next-line
  const [file, setFile] = useState<FileObject | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    // Handle the dropped file
    const currentFile = acceptedFiles[0];
    if (!currentFile.type.startsWith('image/')) {
      setError('Selected file is not an image');
      setFile(null);
      setImageDataUrl(null);
      return;
    }
    if (!['image/jpeg', 'image/png'].includes(currentFile.type)) {
      setError('Selected file must be a JPEG or PNG image');
      setFile(null);
      setImageDataUrl(null);
      return;
    }
    if (currentFile.size > MAX_FILE_SIZE) {
      setError(`Selected file is too large (maximum size: ${MAX_FILE_SIZE / 1024 / 1024}MB)`);
      setFile(null);
      setImageDataUrl(null);
      return;
    }
    setFile(currentFile as FileObject);
    onChange(currentFile);
    setError(null);
    // Resize the image before displaying it
    ImageResizer.imageFileResizer(
      currentFile,
      480,
      480,
      'JPEG',
      80,
      0,
      uri => {
        setImageDataUrl(uri as string);
      },
      'base64',
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDeleteImage = () => {
    setFile(null);
    setImageDataUrl(null);
  };

  return (
    <div
      {...getRootProps()}
      className={`
        mt-[16px] rounded-2xl border-2 border-dashed border-gray-200 p-4 hover:cursor-pointer
        ${isDragActive ? 'border-blue-500' : ''}
        ${error ? 'border-red-500' : ''}
      `}
    >
      <input {...getInputProps()} />

      {preview && imageDataUrl ? (
        <>
          <img src={imageDataUrl} alt="Uploaded" className="h-auto w-full" />
          <button className="mt-2 text-red-500 hover:text-red-700" onClick={handleDeleteImage}>
            X
          </button>
        </>
      ) : (
        <div className="flex w-full items-center justify-center text-blue-500">
          <PlusIcon className="stroke-blue-500 group-hover:stroke-blue-600 group-active:stroke-blue-700" />
          <span>Add file</span>
        </div>
      )}

      {error && <p className="mt-2 text-center text-red-500">{error}</p>}
    </div>
  );
};

export default InputDropZone;
