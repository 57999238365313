import moment from 'moment';
import { tv } from 'tailwind-variants';

import { HandSpeaker } from '@/assets/icons';
import Button from '@/components/Button/Button';

import UpgradePlanWithPaymentModal from '../UpgradePlanWithPaymentModal/UpgradePlanWithPaymentModal';
import UpgradeSubscriptionPlanModal from '../UpgradeSubscriptionPlanModal/UpgradeSubscriptionPlanModal';
import useSubscriptionGlobalBanner from './useSubscriptionGlobalBanner';
import { SUBSCRIPTION_BANNER_TYPES } from './utils/constants';

const subscriptionGlobalBannerClasses = tv({
  slots: {
    base: 'flex w-full items-center gap-3 p-4',
    speaker: '',
    button: 'text-sm py-1 px-6',
    message: 'text-sm flex-1',
  },
  variants: {
    variant: {
      '30days': '',
      '15days': '',
      '7days': '',
      '3days': '',
      planExpired: '',
      subscriptionCancelled: '',
      accountDeactivated: '',
    },
  },
  compoundVariants: [
    {
      variant: ['30days', '15days'],
      class: {
        base: 'bg-emerald-500',
        message: 'text-white',
        button: 'bg-white hover:bg-gray-100 !text-black',
        speaker: 'white',
      },
    },
    {
      variant: ['7days'],
      class: {
        base: 'bg-blue-500',
        message: 'text-white',
        button: 'bg-white hover:bg-gray-100 !text-black',
        speaker: 'white',
      },
    },
    {
      variant: ['3days', 'planExpired'],
      class: {
        base: 'bg-red-100',
        message: 'text-black',
        button: 'bg-red-500 hover:bg-red-700 !text-white',
        speaker: 'black',
      },
    },
    {
      variant: ['accountDeactivated', 'subscriptionCancelled'],
      class: {
        base: 'bg-black',
        message: 'text-white',
        button: 'bg-red-500 hover:bg-red-700 !text-white',
        speaker: 'red',
      },
    },
  ],
});

export type SubscriptionGlobalBannerProps = {
  bannerVariant: keyof typeof SUBSCRIPTION_BANNER_TYPES;
  remainingDays?: number;
  gracePeriodEndDate?: string;
  cancellationDate?: string;
  isFreeTrailOver?: boolean;
};

const SubscriptionGlobalBanner = ({
  bannerVariant,
  remainingDays,
  gracePeriodEndDate,
  cancellationDate,
  isFreeTrailOver,
}: SubscriptionGlobalBannerProps) => {
  const {
    closeSubscriptionModalHandler,
    isUpgradePlanModalOpen,
    subscribePlanClickHandler,
    isUpgradeWithPaymentModalOpen,
  } = useSubscriptionGlobalBanner();

  const TRIAL_DAYS_SET = new Set([
    SUBSCRIPTION_BANNER_TYPES['30days'],
    SUBSCRIPTION_BANNER_TYPES['15days'],
    SUBSCRIPTION_BANNER_TYPES['7days'],
    SUBSCRIPTION_BANNER_TYPES['3days'],
  ]);

  const { base, button, speaker, message } = subscriptionGlobalBannerClasses();
  return (
    <div className={base({ variant: bannerVariant })}>
      <div>
        <HandSpeaker stroke={speaker({ variant: bannerVariant })} />
      </div>
      <div className={message({ variant: bannerVariant })}>
        {TRIAL_DAYS_SET.has(bannerVariant) ? (
          <p>
            Your free trial is ending soon.{' '}
            <span className="font-F37Bolton-Medium">{remainingDays ?? 0} days remaining.</span>
          </p>
        ) : bannerVariant === SUBSCRIPTION_BANNER_TYPES.planExpired ? (
          <p>
            Your plan has expired,{' '}
            {gracePeriodEndDate && (
              <span>
                but don't worry! You have a grace period until{' '}
                <span className="font-F37Bolton-Medium">
                  {' '}
                  {moment(gracePeriodEndDate).format('MMM DD, YYYY')}
                </span>{' '}
                to renew.
              </span>
            )}
          </p>
        ) : bannerVariant === SUBSCRIPTION_BANNER_TYPES.subscriptionCancelled ? (
          <p>
            <span className="font-F37Bolton-Medium">Your subscription has been canceled.</span>{' '}
            {cancellationDate && (
              <span>
                However, your current plan will remain active until{' '}
                <span className="font-F37Bolton-Medium">
                  {' '}
                  {moment(cancellationDate).format('MMM DD, YYYY')}
                </span>
                .
              </span>
            )}
          </p>
        ) : bannerVariant === SUBSCRIPTION_BANNER_TYPES.accountDeactivated ? (
          <p>
            <span className="font-F37Bolton-Medium">Your account has been deactivated.</span> To
            continue using our payroll services, please subscribe now. We'd love to have you back!
          </p>
        ) : null}
      </div>
      <div>
        <Button
          variant="primary"
          parentClasses={button({ variant: bannerVariant })}
          width="w-auto"
          height="h-auto"
          onPress={() => subscribePlanClickHandler()}
        >
          Subscribe
        </Button>
        <UpgradePlanWithPaymentModal
          isOpen={isUpgradeWithPaymentModalOpen}
          onCancel={closeSubscriptionModalHandler}
          remainingDays={remainingDays}
          isFreeTrailOver={isFreeTrailOver}
        />
        <UpgradeSubscriptionPlanModal
          isOpen={isUpgradePlanModalOpen}
          onCancel={closeSubscriptionModalHandler}
        />
      </div>
    </div>
  );
};

export default SubscriptionGlobalBanner;
