import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import CobrowseIO from 'cobrowse-sdk-js';
import TagManager from 'react-gtm-module';

import { whiteListErrorsSentry } from './constants';

const GTM_ID = import.meta.env.APP_GTM_ID;
const COBROWSE_LICENSE = import.meta.env.APP_COBROWSE_LICENSE;

const sentryBeforeSend = (event: Sentry.Event, hint: Sentry.EventHint) => {
  const expectedCoBrowseResponse = { success: false, status: 401 };
  if (
    JSON.stringify(hint.originalException) === JSON.stringify(expectedCoBrowseResponse) &&
    event.exception?.values?.[0]?.value ===
      'Object captured as promise rejection with keys: data, status, success'
  ) {
    return null;
  }

  const stack = hint?.originalException?.stack;
  if (stack?.includes('Failed to fetch') && stack.includes('loadSeloUrls')) {
    return null;
  }
  return event;
};

export const initializeAnalytics = () => {
  FullStory.init({ orgId: import.meta.env.APP_FULLSTORY_ORG, namespace: 'FullStory' });

  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: import.meta.env.APP_DNS_SENTRY,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(),
        new SentryFullStory(import.meta.env.APP_SENTRY_ORG_SLUG, { client: FullStory }),
      ],
      tracesSampleRate: 0.1, // Performance Monitoring rate, set to 10% in production
      release: import.meta.env.APP_VERSION,
      environment: import.meta.env.APP_MODE,
      replaysSessionSampleRate: 0.1, // Sample 10% of sessions for Replay
      replaysOnErrorSampleRate: 1.0, // Sample 100% of sessions with errors
      ignoreErrors: whiteListErrorsSentry,
      beforeSend: sentryBeforeSend,
    });
  }

  if (GTM_ID) {
    TagManager.initialize({ gtmId: GTM_ID });
  }
};

export const initializeCobrowse = () => {
  if (COBROWSE_LICENSE) {
    CobrowseIO.license = COBROWSE_LICENSE;
    CobrowseIO.start();
  }
};
