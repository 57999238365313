import { Dispatch } from '@/redux/store';
import { downloadPayStub } from '@/utils/helpers';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

const useBillingHistoryView = ({ invoice }: any) => {
  const dispatch = useDispatch<Dispatch>();

  const [isLoadingInvoice, setIsLoadingInvoice] = useState<boolean>(false);
  const [isLoadingReceipt, setIsLoadingReceipt] = useState<boolean>(false);

  const payDate = DateTime.fromISO(invoice.dueDate, {
    zone: 'utc',
  }).toFormat('MMM d, y');

  const handleDownloadInvoice = async () => {
    setIsLoadingInvoice(true);
    const data = await dispatch.user.downloadInvoice({ id: invoice.id });
    if (data) {
      downloadPayStub(data, invoice.id, true, 'Invoice_');
      setIsLoadingInvoice(false);
    }
    setIsLoadingInvoice(false);
  };
  const handleDownloadReceipt = async () => {
    setIsLoadingReceipt(true);
    if (!invoice.fsInvoiceUrl) {
      const data = await dispatch.user.downloadReceipt({ id: invoice.id });
      if (data) {
        downloadPayStub(data, invoice.id, true, 'Receipt_');
        setIsLoadingReceipt(false);
      }
    } else {
      window.open(invoice.fsInvoiceUrl);
    }
    setIsLoadingReceipt(false);
  };

  const handleAction = () => {
    if (invoice?.fsInvoiceUrl) {
      handleDownloadInvoice();
    } else {
      handleDownloadReceipt();
    }
  };
  return {
    handleDownloadInvoice,
    handleDownloadReceipt,
    payDate,
    isLoadingInvoice,
    isLoadingReceipt,
    handleAction,
  };
};
export default useBillingHistoryView;
