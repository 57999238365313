import CheckListCard from './components/ChecklistCard/CheckListCard';
import useWhatToDo from './useWhatToDo';

const WhatTodo = () => {
  const { whatYouDoList } = useWhatToDo() || {};
  return (
    <div className="flex w-full justify-center bg-emerald-50 px-5 py-12 sm:px-20 lg:min-h-screen lg:pt-72">
      <div className="flex flex-col gap-6">
        <h1 className="w-full text-[28px]">Here’s what you can do with PayStubs.com</h1>
        {whatYouDoList.map((item, index) => (
          <CheckListCard text={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default WhatTodo;
