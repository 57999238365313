import React from 'react';

import { ReactComponent as DocumentIcon } from '@/assets/document.svg';
import { Button } from '@/components';

interface DocumentCardProps {
  fileObject: File;
  onClose: (fileObject: File) => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ fileObject, onClose }) => {
  return (
    <div className="mt-[20px] flex justify-between rounded-2xl border border-gray-300 bg-white p-[16px]">
      <div className="flex items-center">
        <DocumentIcon />
        <span className="ml-[16px] font-F37Bolton-Medium text-gray-900">{fileObject.path}</span>
      </div>
      <Button type="closeIcon" onClick={() => onClose(fileObject)} />
    </div>
  );
};

export default DocumentCard;
