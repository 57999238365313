import { createModel } from '@rematch/core';

import { callToast } from '@/utils/helpers';
import { getDashboardList, skipContractors, updateEinSsnOfCompany } from '@/api/services/dashboard';
import { DashboardList } from '@/redux/dto/dashboard';

import type { RootModel } from '.';

type companyState = {
  erorr: unknown;
  list: DashboardList;
};

export const dashboard = createModel<RootModel>()({
  state: {
    error: null,
    list: null,
  } as unknown as companyState,
  reducers: {
    GET_DASHBOARD_LIST_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        list: payload,
      };
    },
    GET_DASHBOARD_LIST_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_SSN_EIN_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    UPDATE_SSN_EIN_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SKIP_CONTRACTORS_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    SKIP_CONTRACTORS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },
  effects: dispatch => {
    const { dashboard } = dispatch;
    return {
      async getDashboardList(payload): Promise<unknown> {
        try {
          const { companyId } = payload;
          const { data } = await getDashboardList(companyId);
          dashboard.GET_DASHBOARD_LIST_SUCCESS(data);
          return data;
        } catch (error: any) {
          dashboard.GET_DASHBOARD_LIST_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateEinSsnOfComapny(payload): Promise<unknown> {
        try {
          const { companyId, state } = payload;
          const { data } = await updateEinSsnOfCompany(companyId, state);
          dashboard.UPDATE_SSN_EIN_SUCCESS(data);
          await dispatch.auth.getOnboardinUserStatus();
          return data;
        } catch (error: any) {
          dashboard.UPDATE_SSN_EIN_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async skipAddingContractors(companyId): Promise<unknown> {
        try {
          const { data } = await skipContractors(companyId);
          dashboard.SKIP_CONTRACTORS_SUCCESS(data);
          await dispatch.auth.getOnboardinUserStatus();
          return data;
        } catch (error: any) {
          dashboard.SKIP_CONTRACTORS_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
