import React from 'react';

import {
  ReactComponent as UserAvatarTheBiggestIcon,
  default as userAvatarTheBiggestUrl,
} from '@/assets/userAvatarTheBiggest.svg';

interface UserAvatarProps {
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  src,
  alt = 'User avatar',
  width = 80,
  height = 80,
}) => {
  const iconStyles = {
    height: `${height}px`,
    width: `${width}px`,
  };

  return (
    <div className="inline-block">
      {src ? (
        <img
          className="rounded-full"
          src={src}
          alt={alt}
          width={width}
          height={height}
          onError={e => {
            e.currentTarget.src = userAvatarTheBiggestUrl;
            e.currentTarget.onerror = null; // Prevent infinite loop
          }}
        />
      ) : (
        <UserAvatarTheBiggestIcon style={iconStyles} />
      )}
    </div>
  );
};

export default UserAvatar;
