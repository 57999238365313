import { Controller, FormProvider } from 'react-hook-form';

import { FormInputMaskTextField } from '@/components';
import Button from '@/components/Button/Button';
import Dropdown from '@/components/Dropdown/Dropdown';
import RadioButtonVerticalGroup from '@/components/RadioButtonGroup/RadioButtonVerticalGroup';
import { typeOfBusiness } from '@/utils/constants';

import { forwardRef } from 'react';
import { BusinessProfileFormProps } from './types';
import useBusinessProfileForm from './useBusinessProfileForm';

const BusinessProfileForm = forwardRef(
  (
    {
      isLoading,
      onSaveBusinessProfile,
      initialValues,
      industryList,
      hideSubmitButton = false,
    }: BusinessProfileFormProps,
    ref,
  ) => {
    const { control, form, onSubmit, isEinSelected, canChooseEinOrSsn } = useBusinessProfileForm({
      onSaveBusinessProfile,
      initialValues,
      ref,
    });

    return (
      <div>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-6">
              <div className="flex">
                <span className="font-F37Bolton-Trial font-F37Bolton-Medium text-2xl">
                  Tell us about your business
                </span>
              </div>

              <Controller
                control={control}
                name={`businessType`}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => (
                  <Dropdown
                    title={`Legal structure of ${initialValues?.name}?`}
                    isRequired
                    options={typeOfBusiness}
                    width="w-[335px] lg:w-full"
                    subtitle="You can edit this from your business profile later."
                    placeholder="For example Limited Liability - LLC"
                    onChange={onChange}
                    value={value ?? ''}
                    name={name}
                    errorMessage={error?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name={`industry`}
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { error },
                  formState,
                }) => (
                  <Dropdown
                    title="What does your business do?"
                    options={industryList}
                    width="w-[335px] lg:w-full"
                    placeholder="Choose your industry"
                    isRequired
                    onChange={onChange}
                    value={value ?? ''}
                    name={name}
                    errorMessage={error?.message}
                    optionParams={option => ({
                      disabled:
                        typeof option === 'string'
                          ? option.includes('----')
                          : option.label?.includes('----'),
                    })}
                  />
                )}
              />

              <div className="flex flex-col gap-3">
                {canChooseEinOrSsn && (
                  <Controller
                    control={control}
                    name={`chooseEinOrSsn`}
                    render={({ field: { onChange, onBlur, value, name, ref }, formState }) => (
                      <RadioButtonVerticalGroup
                        options={[
                          { label: 'EIN', value: 1 },
                          { label: 'SSN', value: 2 },
                        ]}
                        label="Taxpayer identification"
                        onChange={value => {
                          form.setValue('ein', null);
                          form.setValue('ssn', null);
                          onChange(value);
                        }}
                        defaultValue={value}
                        isRequired
                      />
                    )}
                  />
                )}

                {isEinSelected ? (
                  <FormInputMaskTextField
                    mask="99-9999999"
                    placeholder="Your EIN identification number"
                    maskChar=""
                    name={`ein`}
                    title="EIN"
                    isRequired
                    width="w-[335px] lg:w-full"
                  />
                ) : (
                  <FormInputMaskTextField
                    mask="999-99-9999"
                    placeholder="Your social security number"
                    maskChar=""
                    name={`ssn`}
                    title="SSN"
                    isRequired
                    width="w-[335px] lg:w-full"
                  />
                )}

                <div className="w-[335px] lg:w-full">
                  {isEinSelected ? (
                    canChooseEinOrSsn ? (
                      <span className="text-sm text-gray-400">
                        A nine-digit IRS-assigned number, identifies businesses and is necessary for
                        hiring employees. Apply online via the
                        <a
                          target="_blank"
                          className="mx-1 text-blue-500"
                          href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online"
                          rel="noreferrer"
                        >
                          IRS website
                        </a>
                        if you lack one.
                      </span>
                    ) : (
                      <span className="text-sm text-gray-400">
                        The EIN is a number assigned by the IRS to identify businesses. It's
                        required if you're hiring employees. If you don't have one, you can simply
                        <a
                          target="_blank"
                          className="mx-1 text-blue-500"
                          href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online"
                          rel="noreferrer"
                        >
                          visit the IRS website and easily apply for one online
                        </a>
                      </span>
                    )
                  ) : (
                    <span className="text-sm text-gray-400">
                      If you are hiring W2 employees, then you must enter your EIN (employer
                      identification number). If you’re only hiring 1099 contractors, you can use
                      your SSN.
                    </span>
                  )}
                </div>
              </div>

              {!hideSubmitButton && (
                <div className={'mt-5'}>
                  <Button
                    type="submit"
                    variant="primary"
                    width="w-[335px] lg:w-full"
                    isLoading={isLoading}
                  >
                    Next: Business address
                  </Button>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    );
  },
);

export default BusinessProfileForm;
