import { useState } from 'react';

import { ManageSubscriptionPlan } from './components';
import { CancelModal } from './components/modals/CancelModal';
import { DowngradeModal } from './components/modals/DowngradeModal';
import { UpgradeModal } from './components/modals/UpgradeModal';
import { UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID } from './utils/constants';

type useUpgradeSubscriptionPlanModalType = {
  handleOpenCancelModal: () => void;
  onCancel: () => void;
};

const useUpgradeSubscriptionPlanModal = ({
  handleOpenCancelModal,
  onCancel,
}: useUpgradeSubscriptionPlanModalType) => {
  const [currentStep, setCurrentStep] = useState(
    UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan,
  );

  const upgradeSuccessHandler = () => {
    setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradeSuccess);
  };

  const downgradeSuccessHandler = () => {
    setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderDowngradeSuccess);
  };

  const openCancelModalHandler = () => {
    setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderCancelModal);
  };

  const defaultStepHandler = () => {
    setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan);
  };

  const onSuccessCancelHandler = () => {
    onCancel();
    setCurrentStep(UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan);
  };

  const modalSteps = {
    [UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradeSuccess]: (
      <UpgradeModal onGotIt={onSuccessCancelHandler} />
    ),
    [UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderDowngradeSuccess]: (
      <DowngradeModal onGotIt={onSuccessCancelHandler} />
    ),
    [UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderCancelModal]: (
      <CancelModal
        handleOpenCancelModal={() => {
          defaultStepHandler();
          onSuccessCancelHandler();
          handleOpenCancelModal();
        }}
        defaultStepHandler={defaultStepHandler}
      />
    ),
    [UPGRADE_SUBSCRIPTION_PLAN_STEPPER_ID.renderUpgradePlan]: (
      <div className="mx-auto w-full">
        <ManageSubscriptionPlan
          choosePlanTitle=""
          upgradeSuccessHandler={upgradeSuccessHandler}
          downgradeSuccessHandler={downgradeSuccessHandler}
          openCancelModalHandler={openCancelModalHandler}
          onCancel={onCancel}
        />
      </div>
    ),
  };

  return { currentStep, modalSteps, defaultStepHandler, onSuccessCancelHandler };
};

export default useUpgradeSubscriptionPlanModal;
