import { forwardRef, useCallback, useMemo } from 'react';
import { Label, TextField, Text } from 'react-aria-components';
import { InputMaskTextFieldProps } from './types';
import InputMask from 'react-input-mask';
import { trackEvent } from '@/utils/track';
import { extractEventName } from '@/utils/helpers';
import debounce from 'lodash.debounce';

const InputMaskTextField = forwardRef(
  (
    {
      title,
      subtitle,
      placeholder,
      isRequired = false,
      isOptional = false,
      width,
      errorMessage,
      onChange,
      parentClasses,
      maskChar = '',
      mask = '',
      isDisabled = false,
      ...rest
    }: InputMaskTextFieldProps,
    ref,
  ) => {
    const handleInputChange = useCallback(
      debounce(() => {
        trackEvent('inputChange', {
          name: rest.name,
          currentPage: extractEventName(location.pathname),
        });
      }, 700),
      [],
    );

    let labelClassName = `font-F37Bolton-Medium text-[16px] text-gray-900`;

    let inputClassName = useMemo(() => {
      let baseClass = `mt-[8px] h-[44px] rounded-[8px] placeholder-gray-300 focus:outline-none focus:ring-[1px] focus:ring-blue-500 w-full ${
        errorMessage
          ? 'border-red-500'
          : isDisabled
          ? 'border-gray-100 text-gray-100'
          : 'border-gray-300'
      }`;

      return baseClass;
    }, [errorMessage, width, isDisabled]);

    return (
      <div className={`flex flex-col ${width ? width : 'w-full'} ${parentClasses}`}>
        <Label className={labelClassName}>
          {title} {isRequired && <span className="text-red-500">*</span>}
          {isOptional && <span className="text-gray-300">(optional)</span>}
        </Label>
        <InputMask
          mask={mask}
          maskChar={maskChar}
          className={inputClassName}
          inputRef={ref}
          value={rest.value}
          onChange={e => {
            handleInputChange();
            onChange && onChange(e.target.value);
          }}
          placeholder={placeholder}
          disabled={isDisabled}
          {...rest}
        />

        {(errorMessage || subtitle) && (
          <Text
            slot="description"
            className={`mt-[8px] text-[14px] ${errorMessage ? 'text-red-500' : 'text-gray-400'}`}
          >
            {errorMessage ? errorMessage : subtitle}
          </Text>
        )}
      </div>
    );
  },
);

export default InputMaskTextField;
