import { Controller } from 'react-hook-form';

import { InputLocationSends } from '@/components';
import Button from '@/components/Button/Button';
import Input from '@/components/Input/Input';
import { forwardRef } from 'react';
import { BusinessAddressFormProps } from './types';
import useBusinessAddressForm from './useBusinessAddressForm';

const BusinessAddressForm = forwardRef(
  (
    {
      isLoading,
      onSaveBusinessAddress,
      initialValues,
      hideSubmitButton = false,
    }: BusinessAddressFormProps,
    ref,
  ) => {
    const { handleSubmit, control, errors, updateFields, onSubmit, defaultValues } =
      useBusinessAddressForm({ onSaveBusinessAddress, initialValues, ref });

    return (
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-6">
            <div className="mt-8 flex justify-center">
              <span className="font-F37Bolton-Trial text-2xl ">Where is it located?</span>
            </div>

            <div>
              <span className="font-F37Bolton-Medium">
                Street address <span className="text-red-500">*</span>
              </span>
              <InputLocationSends
                onPlaceSelect={stateLocation => updateFields(stateLocation)}
                errorMessage={errors?.streetAddress}
                defaultValue={defaultValues.streetAddress}
              />
            </div>
            <Controller
              control={control}
              name="aptSteNumber"
              render={({ field: { onChange, onBlur, value, name, ref }, formState }) => (
                <Input
                  title="Apt/Unit #"
                  placeholder="Enter apt/unit number"
                  width="w-[335px] lg:w-[400px]"
                  subtitle="Apartment, suite, unit, building, floor, etc."
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  errorMessage={errors?.aptSteNumber?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="city"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Input
                  title="City"
                  placeholder="Business location city"
                  width="w-[335px] lg:w-[400px]"
                  isRequired
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  errorMessage={errors?.city?.message}
                />
              )}
            />
            <div className="flex gap-x-[8px]">
              <Controller
                control={control}
                name="state"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    title="State"
                    placeholder="Business state"
                    width="w-[188px] lg:w-[196px]"
                    isRequired
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    errorMessage={errors?.state?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="zipCode"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Input
                    title="Zip code"
                    placeholder="Enter zipcode"
                    width="w-[139px] lg:w-[196px]"
                    isRequired
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    errorMessage={errors?.zipCode?.message}
                  />
                )}
              />
            </div>
          </div>
          <span className="mt-3 block text-sm text-gray-400">
            You can add multiple business addresses later.
          </span>
          {!hideSubmitButton && (
            <div className="mt-[24px]">
              <Button
                variant="primary"
                type="submit"
                children="Save business profile"
                width="w-[335px] lg:w-[400px]"
                isLoading={isLoading}
              />
            </div>
          )}
        </form>
      </div>
    );
  },
);

export default BusinessAddressForm;
