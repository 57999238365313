import { twMerge } from 'tailwind-merge';

import { SUPPORT_CONTACT_INFO } from '@/utils/constants';

import { InfoStepCardType } from './type';

const InfoStepCard = ({
  cardContainerClasses = '',
  subCardClasses = '',
  title,
  description,
  subCardTitle = '',
  subCardDescription,
  actionButton,
  actionEventButton,
}: InfoStepCardType) => {
  return (
    <div
      className={twMerge(
        'mt-10 flex flex-col justify-between rounded-2xl bg-darkblue-500 p-6 text-white sm:flex-row',
        cardContainerClasses,
      )}
    >
      <div className="flex max-w-[300px] flex-col justify-between">
        <div>
          <h3 className="text-[28px]">{title}</h3>
          {description && description}
          <h6 className="hidden pb-4 sm:block">Call {SUPPORT_CONTACT_INFO.mobileNumber} or</h6>
        </div>
        {actionEventButton}
      </div>

      {/* sub card */}
      <div
        className={twMerge(
          'flex w-full gap-4 rounded-xl bg-darkblue-400 p-4 sm:block sm:max-w-[160px] ',
          subCardClasses,
        )}
      >
        <img
          src="https://uploads-ssl.webflow.com/63d39d61533099307dbc0bf8/6639dbfeb3883789ae817a53_JaysonHeadshot.png"
          className="size-12"
          alt="jayson"
        />
        <div>
          <h6 className="text-lg">{subCardTitle}</h6>
          {subCardDescription && subCardDescription}
        </div>
      </div>
      {actionButton && actionButton}
    </div>
  );
};

export default InfoStepCard;
