import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import * as routes from '@/routes/routes';

export default ({ data }) => {
  const {
    status,
    year,
    contractor: { id },
  } = data || {};
  const navigate = useNavigate();

  const handleClickCard = useCallback(() => {
    if (status === 'approved') {
      navigate(routes.TAX_FORM_CONTRACTOR_1099S, { state: { id, year, currentStep: 4 } });
    } else {
      navigate(routes.TAX_FORM_CONTRACTOR_1099S, { state: { id, year } });
    }
  }, []);

  return (
    <div
      role="button"
      onClick={handleClickCard}
      tabIndex={0}
      className="flex flex-col rounded-2xl border border-gray-300 shadow-secondary"
    >
      <ContractorCardDetails data={data} />

      <div
        className="rounded-b-2xl border-t bg-white py-[10px] text-center text-blue-500"
        children={status === 'approved' ? 'View & Share' : 'Review & Approve'}
      />
    </div>
  );
};

export const ContractorCardDetails = ({ data, px }) => {
  const {
    contractor: { fullName },
    status,
    year,
  } = data || {};

  return (
    <div
      className={`flex justify-between rounded-none rounded-t-2xl bg-white ${
        px ? px : 'px-[20px]'
      }   py-[16px]`}
    >
      <div className="flex w-full">
        <div className="flex grow">
          <DocumentTextIcon className="me-[12px] inline-block h-10 w-10 flex-none rounded-full border-[6px] border-lightblue-400 bg-lightblue-400 text-gray-400" />
          <div className="flex w-full flex-col gap-y-[2px]">
            <p className="font-F37Bolton-Medium" children={`${fullName ? fullName : ''}`} />
            <span className="font-normal text-gray-400" children={`1099 Form | ${year ?? ''}`} />
          </div>
        </div>
        <div>
          {status === 'approved' ? (
            <span className="h-fit flex-none rounded-2xl bg-teal-100 px-[12px] py-[4px] text-teal-700">
              Approved
            </span>
          ) : (
            <span className="h-fit flex-none rounded-2xl bg-gray-100 px-[12px] py-[4px] text-gray-300">
              Draft
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
