import { twMerge } from 'tailwind-merge';

import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';

import PaymentSuccess from '../SubscriptionReminder/PaymentSuccess/PaymentSuccess';
import UpgradeSubscriptionPlan from '../UpgradeSubscriptionPlan/UpgradeSubscriptionPlan';
import useSubscriptionReminderModal from './useSubscriptionReminderModal';
import { SUBSCRIPTION_REMINDER_STEPPER_IDS } from './utils/constants';

type SubscriptionReminderModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  remainingDays?: number | string;
  isFreeTrailOver?: boolean;
};

const SubscriptionReminderModal = ({
  isOpen,
  onCancel,
  remainingDays = 15,
  isFreeTrailOver,
}: SubscriptionReminderModalProps) => {
  const {
    currentStep,
    upgradePlanTitle,
    paymentSuccessHandler,
    paymentDetails,
    upgradePlanSubTitle,
  } = useSubscriptionReminderModal({ remainingDays, isFreeTrailOver });

  return (
    <DialogComponent
      open={isOpen}
      onCancel={onCancel}
      height="h-auto max-h-[95vh]"
      width={twMerge(
        'w-[95vw] sm:max-w-[520px]',
        currentStep === SUBSCRIPTION_REMINDER_STEPPER_IDS.renderUpgradePlan &&
          'sm:w-[85vw] sm:max-w-[1100px]',
      )}
      modalClasses={`px-5 py-7 rounded-lg`}
    >
      <div>
        <div className="absolute right-2 top-1.5 flex justify-end pr-[16px] ">
          <Button variant="icon" icon={CloseIcon} onPress={onCancel} />
        </div>
        <div className="w-full pt-5">
          {currentStep === SUBSCRIPTION_REMINDER_STEPPER_IDS.renderPaymentSuccess &&
          paymentDetails ? (
            <div className="w-full max-w-[480px]">
              <PaymentSuccess
                billingDate={paymentDetails.billingDate}
                amount={paymentDetails.amount}
                planName={paymentDetails.planName}
                onConfirm={onCancel}
              />
            </div>
          ) : (
            <div className="mx-auto w-full max-w-[1000px]">
              <UpgradeSubscriptionPlan
                paymentSuccessHandler={paymentSuccessHandler}
                choosePlanTitle={upgradePlanTitle}
                subTitleStyle={!isFreeTrailOver && !remainingDays ? 'text-gray-400' : ''}
                subTitle={upgradePlanSubTitle}
              />
            </div>
          )}
        </div>
      </div>
    </DialogComponent>
  );
};

export default SubscriptionReminderModal;
