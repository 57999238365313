import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';

import useUpgradeSubscriptionPlanModal from './useUpgradeSubscriptionPlanModal';

type UpgradeSubscriptionPlanModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  handleOpenCancelModal: () => void;
};

const UpgradeSubscriptionPlanModal = ({
  isOpen,
  onCancel,
  handleOpenCancelModal,
}: UpgradeSubscriptionPlanModalProps) => {
  const { currentStep, modalSteps, defaultStepHandler, onSuccessCancelHandler } =
    useUpgradeSubscriptionPlanModal({
      handleOpenCancelModal,
      onCancel,
    });

  return (
    <DialogComponent
      open={isOpen}
      onCancel={() => {
        onSuccessCancelHandler();
        defaultStepHandler();
      }}
      height="h-auto sm:min-h-[200px] max-h-[95vh]"
      width="w-auto sm:min-w-[450px] max-w-[592px]"
      modalClasses={`p-6 mx-4 rounded-lg`}
    >
      <div>
        <div className="absolute right-2 top-1.5 flex justify-end pr-[16px] ">
          <Button
            variant="icon"
            icon={CloseIcon}
            onPress={() => {
              onSuccessCancelHandler();
              defaultStepHandler();
            }}
          />
        </div>
        <div className="w-full pt-5">{modalSteps[currentStep]}</div>
      </div>
    </DialogComponent>
  );
};

export default UpgradeSubscriptionPlanModal;
