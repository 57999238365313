import { SVGProps } from 'react';

const HandSpeaker = (props: SVGProps<SVGPathElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.34 15.84C9.652 15.78 8.954 15.75 8.25 15.75H7.5C6.30653 15.75 5.16193 15.2759 4.31802 14.432C3.47411 13.5881 3 12.4435 3 11.25C3 10.0565 3.47411 8.91193 4.31802 8.06802C5.16193 7.22411 6.30653 6.75 7.5 6.75H8.25C8.954 6.75 9.652 6.72 10.34 6.66M10.34 15.84C10.593 16.802 10.924 17.732 11.325 18.623C11.572 19.173 11.385 19.833 10.862 20.134L10.205 20.514C9.654 20.832 8.945 20.631 8.678 20.053C8.04429 18.682 7.56146 17.2463 7.238 15.771M10.34 15.84C9.94698 14.3417 9.74868 12.799 9.75 11.25C9.75 9.664 9.955 8.126 10.34 6.66M10.34 15.84C13.4215 16.1041 16.4222 16.9651 19.175 18.375M10.34 6.66C13.4215 6.39592 16.4223 5.53493 19.175 4.125M19.175 18.375C19.057 18.755 18.93 19.129 18.795 19.5M19.175 18.375C19.719 16.6216 20.0592 14.8113 20.189 12.98M19.175 4.125C19.0577 3.74689 18.931 3.37177 18.795 3M19.175 4.125C19.719 5.87844 20.0592 7.6887 20.189 9.52M20.189 9.52C20.684 9.933 21 10.555 21 11.25C21 11.945 20.684 12.567 20.189 12.98M20.189 9.52C20.2711 10.6719 20.2711 11.8281 20.189 12.98"
        stroke="#F23F3F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        {...props}
      />
    </svg>
  );
};

export default HandSpeaker;
