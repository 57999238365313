import { DEDUCTION_TYPES_NAME } from '@/utils/constants';
import * as Yup from 'yup';

const createRequiredSchema = ({ type, message }: { type: string; message: string }) =>
  Yup.string().when('type', {
    is: (val: string) => [type].includes(val),
    then: schema => schema.required(message),
    otherwise: schema => schema.notRequired(),
  });

export const DeductionFormSchema = Yup.object().shape({
  benefitType: createRequiredSchema({
    type: DEDUCTION_TYPES_NAME.benefit,
    message: 'Benefit Type is Required',
  }),
  garnishmentType: createRequiredSchema({
    type: DEDUCTION_TYPES_NAME.garnishment,
    message: 'Garnishment Type is Required',
  }),
  benefitTitle: createRequiredSchema({
    type: DEDUCTION_TYPES_NAME.benefit,
    message: 'Benefit Title is Required',
  }),
  garnishmentTitle: createRequiredSchema({
    type: DEDUCTION_TYPES_NAME.garnishment,
    message: 'Garnishment Title is Required',
  }),
  customPostTaxDeductionTitle: createRequiredSchema({
    type: DEDUCTION_TYPES_NAME.custom,
    message: 'Custom post-tax deductions Title is Required',
  }),
});
