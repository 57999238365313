import { SetStateAction, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { AppDropdownProps } from './types';

const Dropdown = forwardRef(
  (
    {
      title,
      placeholder,
      isRequired = false,
      width,
      options,
      parentClasses,
      subtitle,
      onChange,
      errorMessage,
      value,
      name,
      isDisabled,
      selectClasses,
      optionParams,
      ...rest
    }: AppDropdownProps,
    ref,
  ) => {
    const handleChange = (event: { target: { value: SetStateAction<string> } }) => {
      trackEvent('inputChange', {
        name: name ?? event.target.value,
        currentPage: extractEventName(location.pathname),
      });
      const newValue = event.target.value;

      if (onChange) {
        onChange(newValue);
      }
    };

    return (
      <div className={`${width ? width : 'w-[335px]'} ${parentClasses}`}>
        {title && (
          <label htmlFor="select" className="mb-2 block font-F37Bolton-Medium text-gray-900">
            {title} {isRequired && <span className="text-red-500">*</span>}
          </label>
        )}
        <select
          ref={ref}
          id="select"
          value={value}
          onChange={handleChange}
          className={twMerge(
            `${selectClasses} block h-[44px] w-full cursor-pointer rounded-[8px] border-gray-300 focus:border-gray-400 focus:outline-none focus:ring-[1px] focus:ring-blue-500 ${
              errorMessage ? 'border-red-500 focus:border-red-500' : ''
            } ${value ? 'text-gray-900' : 'text-gray-300'} `,
          )}
          name={name}
          {...rest}
        >
          {
            <option value="" disabled style={{ color: 'italic' }}>
              {placeholder ?? 'Select an item'}
            </option>
          }
          {Array.isArray(options) &&
            options.map((optionItem, index) => (
              <option
                key={optionItem?.id ?? index}
                value={optionItem?.value ?? optionItem}
                {...(optionParams ? optionParams(optionItem) : {})}
              >
                {optionItem?.name || optionItem.value || optionItem.toString()}
              </option>
            ))}
        </select>
        {errorMessage ? (
          <span className="mt-[4px] block text-sm text-red-500">{errorMessage}</span>
        ) : (
          subtitle && <span className="mt-[4px] block text-sm text-gray-400">{subtitle}</span>
        )}
      </div>
    );
  },
);

Dropdown.displayName = 'Dropdown';

export default Dropdown;
