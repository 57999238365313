import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { getCall } from '@/api/axios';

import { SubscriptionPlansType } from '@/redux/dto/auth';
import { queryEndpoints } from './endpoints';
import { UseGetSubscriptionPlansTypes } from './types';

// 60 minutes time
const queryTime = 60 * 60 * 1000;

export const useGetSubscriptionPlans = ({
  queryParams,
}: UseGetSubscriptionPlansTypes): UseQueryResult<SubscriptionPlansType, Error> => {
  const { queryKey, url } = queryEndpoints.all();

  return useQuery<SubscriptionPlansType, Error>({
    queryKey,
    queryFn: () => getCall(url),
    select: data => data.data,
    gcTime: queryTime,
    staleTime: queryTime,
    ...queryParams,
  });
};
