import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { getCall } from '@/api/axios';
import { TeamMember } from '@/redux/dto/members';

import { queryEndpoints } from './endpoints';

const useGetTeamMembers = (companyId: number): UseQueryResult<TeamMember[], Error> => {
  const { queryKey, url } = queryEndpoints.teamMembers(companyId);

  return useQuery<TeamMember[], Error>({
    queryKey,
    queryFn: () => getCall(url),
    select: data => {
      return data.data.teamMembers;
    },
    refetchOnWindowFocus: true,
  });
};

export default useGetTeamMembers;
