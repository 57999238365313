import { Bars3BottomRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronDownBlack } from '@/assets/chevron-down-black.svg';
import { ReactComponent as Logo } from '@/assets/logoDark.svg';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { LoaderComponent } from '@/components/ui';
import Menu from '@/components/ui/Menu/Menu';
import SupportHelp from '@/components/ui/SupportHelp/SupportHelp';
import { CalendlyModal } from '@/modules/myAccount/components/CancelSubscriptionModal/modals/CalendlyModal';

import LogoutModal from '../LogoutModal';
import SubscriptionGlobalBanner from '../SubscriptionGlobalBanner/SubscriptionGlobalBanner';
import MyAccountModal from './component/MyAccountModal';
import { publicHeaderType } from './type';
import { usePublicHeader } from './usePublicHeader';

const PublicHeader = ({ showProfile, showHelp, showHelpClasses = '' }: publicHeaderType) => {
  const {
    handleLogout,
    setIsOpenedLogoutModal,
    isOpenedLogoutModal,
    handleHeaderMenuActions,
    isOpenMyAccountModal,
    setIsOpenMyAccountModal,
    user,
    menuItemList,
    handleGotoDashboardClick,
    handleCalendlyModal,
    isCalendlyModalOpen,
    isCalendlyLoading,
    bannerVariantToShow,
    gracePeriodEndDate,
    nextProcessingDate,
    remainingDays,
    isFreeTrailOver,
  } = usePublicHeader();
  return (
    <div className="fixed z-[45] w-full">
      {bannerVariantToShow && (
        <SubscriptionGlobalBanner
          bannerVariant={bannerVariantToShow}
          remainingDays={remainingDays}
          cancellationDate={nextProcessingDate}
          gracePeriodEndDate={gracePeriodEndDate}
          isFreeTrailOver={isFreeTrailOver}
        />
      )}
      <header className="  flex h-14 w-full items-center border-b border-gray-50 bg-white px-5 sm:px-10 ">
        <nav className="flex size-full items-center justify-between">
          <div>
            <Link to={'https://www.paystubs.com/'} target="_blank">
              <Logo />
            </Link>
          </div>
          <div className="flex items-center justify-center">
            {showHelp && (
              <SupportHelp onBookCall={handleCalendlyModal} className={showHelpClasses} />
            )}
            {showProfile && (
              <>
                <div className=" flex items-center justify-center py-4 pl-[18px] text-base font-medium leading-6 ">
                  <Menu
                    onAction={handleHeaderMenuActions}
                    menuWrapperClasses="min-w-[250px]"
                    menuButtonLabel={
                      <>
                        <div className="hidden lg:flex">
                          {user?.firstName + ' ' ?? ''}
                          {user?.lastName ?? ''}
                        </div>
                        <div className="flex lg:hidden">
                          <Bars3BottomRightIcon className="size-6" />
                        </div>
                      </>
                    }
                    menuButtonVariant="text"
                    menuButtonIcon={
                      <ChevronDownBlack className="mt-[3px] hidden max-h-[24px] max-w-[20px] lg:flex" />
                    }
                    menuItemsList={menuItemList}
                  />
                </div>
              </>
            )}
            {isOpenedLogoutModal && (
              <LogoutModal
                isOpen={isOpenedLogoutModal}
                toggleModal={isOpen => setIsOpenedLogoutModal(isOpen)}
                onAction={handleLogout}
              />
            )}
            {isOpenMyAccountModal && (
              <MyAccountModal
                isOpen={isOpenMyAccountModal}
                onCancel={() => setIsOpenMyAccountModal(false)}
                onGotoDashboard={handleGotoDashboardClick}
              />
            )}
            {isCalendlyModalOpen && (
              <DialogComponent
                open={isCalendlyModalOpen}
                onCancel={handleCalendlyModal}
                children={
                  <div className="relative h-screen min-h-screen">
                    {isCalendlyLoading && (
                      <div className=" absolute flex min-h-screen w-full flex-col items-center justify-center">
                        <LoaderComponent />
                      </div>
                    )}

                    <CalendlyModal onClose={handleCalendlyModal} />
                  </div>
                }
                width="w-[100vw] lg:w-[610px]"
                height="h-[100vh] lg:max-h-[800px]"
              />
            )}
          </div>
        </nav>
      </header>
    </div>
  );
};

export default PublicHeader;
