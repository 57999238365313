import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SheduleRoot } from '@/redux/dto/schedule';
import { Dispatch, RootState } from '@/redux/store';

import { isArrayHasData } from '@/utils/helpers';
import { PayrollScheduleProps, PayrollScheduleTypes } from './types';
import { separateSchedules } from './utils';

export const options = ['Weekly', 'Bi-weekly', 'Semi-monthly', 'Monthly'];

export const OverWriteOption = {
  other: 'Custom Pay Period',
};

const usePayrollSchedule = ({
  isEdit,
  step,
  setStep,
  setIsEdit,
  refetchDashboard,
}: PayrollScheduleProps) => {
  const [previewSchedule, setPreviewSchedule] = useState({ backDated: [], upcoming: [] });
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);
  const [isScheduleEditable, setIsScheduleEditable] = useState(true);
  const [schedule, setSchedule] = useState<SheduleRoot | null>(null);
  const [previewScheduleData, setPreviewScheduleData] = useState<SheduleRoot | null>(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [payFrequency, setPayFrequency] = useState(1);

  const previewScheduleButtonRef = useRef();

  const companyId = useSelector((state: RootState) => state.auth.user.companyId);
  const savedSchedule = useSelector((state: RootState) => state.schedule.schedule);

  const getScheduleList = async () => {
    await dispatch.schedule.getSchedule({ companyId });
  };

  const handleSaveButtonValidation = (isDisabled: boolean) => {
    setIsSaveButtonDisabled(isDisabled);
  };

  useEffect(() => {
    setSchedule(savedSchedule);
  }, [savedSchedule]);

  const dispatch = useDispatch<Dispatch>();

  const warningPayroll = useSelector((state: RootState) => state.employee.warningPayroll);

  const handleClose = () => {
    refetchDashboard && refetchDashboard();
    setIsConfirmationOpen(true);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setIsConfirmationOpen(false);
    dispatch.schedule.closePayrollSchedule();
    if (step === 2) {
      setStep(prev => --prev);
    }
  };

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = async () => {
    setLoading(true);
    const res = await dispatch.employee.getPayrollHistoryEmployeesReworked({
      companyId,
    });
    if (res) {
      const filteredHistory = Array.isArray(res) && res.filter(history => history.type !== 'test');
      setIsScheduleEditable(!isArrayHasData(filteredHistory));
    }
    setLoading(false);
  };

  const getPreviewSchedule = async data => {
    setLoading(true);
    const res = await dispatch.schedule.getPreviewSchedule({ companyId, data });
    if (res) {
      setLoading(false);
      const scheduleData = separateSchedules(res?.scheduleEntries, data?.payPeriodEndDate);
      setPreviewSchedule(scheduleData);
      setStep(prev => ++prev);
      getScheduleList();
      setPayFrequency(res?.payFrequency);
      setPreviewScheduleData({ ...res, ...data });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      const data = separateSchedules(
        savedSchedule.scheduleEntries,
        moment(savedSchedule.payPeriodEndDate).format('MM/DD/YYYY'),
      );
      setPayFrequency(savedSchedule?.payFrequency);
      setPreviewSchedule(data);
    }
  }, [isEdit, savedSchedule]);

  const handleSubmitSchedule = async () => {
    setLoading(true);
    const submitRes = await dispatch.schedule.submitSchedule({ companyId, data });
    if (submitRes) {
      Boolean(warningPayroll?.[0]?.isDone) && location.reload();
      const dashboardListRes = await dispatch.dashboard.getDashboardList({ companyId });
      if (dashboardListRes) {
        setLoading(false);
        refetchDashboard && refetchDashboard();
        dispatch.schedule.closePayrollSchedule();
      }
    } else {
      setLoading(false);
    }
  };

  const handleEditSchedule = () => {
    setStep(1);

    setPreviewScheduleData(schedule);
  };

  const onSubmit = (data: PayrollScheduleTypes) => {
    setIsEdit(false);
    setPayFrequency(data?.payFrequency);
    getPreviewSchedule(data);
    setData(data);
  };

  const handleBackButton = () => {
    setStep(prev => ++prev);
  };

  return {
    handleClose,
    onSubmit,
    step,
    loading,
    previewSchedule,
    handleSubmitSchedule,
    handleBackButton,
    isConfirmationOpen,
    handleCancel,
    setIsConfirmationOpen,
    isScheduleEditable,
    schedule,
    handleEditSchedule,
    previewScheduleButtonRef,
    previewScheduleData,
    handleSaveButtonValidation,
    isSaveButtonDisabled,
    payFrequency,
  };
};

export default usePayrollSchedule;
