import * as Sentry from '@sentry/react';
import { loadStripe, Stripe } from '@stripe/stripe-js';

const STRIPE_TOKEN = import.meta.env.APP_STRIPE_TOKEN;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

export const loadStripeWithRetry = async (
  key: string = STRIPE_TOKEN,
  retries: number = MAX_RETRIES,
): Promise<Stripe | null> => {
  try {
    const stripe = await loadStripe(key);
    if (!stripe) throw new Error('Stripe.js failed to load.');
    return stripe;
  } catch (error) {
    if (retries > 0) {
      console.error(
        `Failed to load Stripe.js, retrying in ${RETRY_DELAY / 1000} seconds...`,
        error,
      );
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return loadStripeWithRetry(key, retries - 1);
    } else {
      Sentry.captureException(
        new Error(`Failed to load Stripe.js after multiple attempts: ${error.message}`),
      );
      console.error('Failed to load Stripe.js after multiple attempts:', error);
      return null;
    }
  }
};

export const stripePromise = loadStripeWithRetry();

export const stripeOptions = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  appearance: {
    /* Add any appearance configurations here */
  },
};
