import { yupResolver } from '@hookform/resolvers/yup';
import { useImperativeHandle, useMemo } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { useBusinessAddressFormType } from './types';
import { BusinessAddressValidation } from './validations';

const useBusinessAddressForm = ({
  onSaveBusinessAddress,
  initialValues,
  ref,
}: useBusinessAddressFormType) => {
  const defaultValues = useMemo(() => {
    return {
      streetAddress: initialValues?.address?.streetAddress || '',
      aptSteNumber: initialValues?.address?.aptSteNumber || '',
      city: initialValues?.address?.city || '',
      state: initialValues?.address?.state || '',
      zipCode: initialValues?.address?.zipCode || '',
    };
  }, [initialValues]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(BusinessAddressValidation),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    onSaveBusinessAddress(data);
  };

  const updateFields = (stateLocation: FieldValues | string) => {
    if (typeof stateLocation === 'object') {
      for (const field in stateLocation) {
        setValue(field, stateLocation[field]);
      }
    } else {
      setValue('streetAddress', stateLocation, { shouldValidate: true });
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
  }));

  return {
    handleSubmit,
    control,
    errors,
    setValue,
    updateFields,
    onSubmit,
    defaultValues,
  };
};

export default useBusinessAddressForm;
