import { useSelector } from 'react-redux';

import { ReactComponent as Illustration } from '@/assets/illustration/upgradeModalIllustration.svg';
import Button from '@/components/Button/Button';
import { RootState } from '@/redux/store';

interface UpgradeModalProps {
  onGotIt: () => void;
}
export const UpgradeModal = ({ onGotIt }: UpgradeModalProps) => {
  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const activeSubscriptionAgreement =
    subscriptionInformation?.subscriptionAgreements[
      subscriptionInformation?.subscriptionAgreements?.length - 1
    ];
  const activeSubscriptionPlan = activeSubscriptionAgreement?.plan;

  const planLimits = {
    starter: 'up to 2 team members',
    pro: 'up to 5 team members',
    advanced: 'up to 15 team members',
    'advanced-plus': 'up to 25 team members',
  };

  const teamMemberLimitText = planLimits[activeSubscriptionPlan];

  return (
    <div className="flex max-w-[498px] flex-col items-center justify-center ">
      <Illustration />
      <span className="mt-6 text-center font-F37Bolton-Medium text-[38px]">
        Success! Your Plan Has Been Upgraded!
      </span>
      <span className="mt-1 text-gray-400">
        You can now manage payroll for {teamMemberLimitText}.
      </span>
      <Button
        variant="primary"
        children="Got it"
        width="w-[416px]"
        parentClasses="mt-8"
        onPress={onGotIt}
      />
      <div className="flex flex-wrap justify-center text-sm text-gray-300">
        Have questions? Our{' '}
        <Button
          variant="text"
          width="w-auto"
          parentClasses="text-emerald-500 mx-1"
          height="h-auto"
          onPress={() => {
            onGotIt();
            window.fcWidget.open();
          }}
        >
          support team
        </Button>
        is here to help!
      </div>
    </div>
  );
};
