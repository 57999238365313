import { XMarkIcon } from '@heroicons/react/24/solid';

import { toast } from 'react-toastify';
import { ToastType } from './types';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';

export const Toast = ({ variant, title, subtitle }: ToastType): JSX.Element => {
  const alertIcon = {
    info: <InformationCircleIcon className="h-6 w-6 text-blue-500" />,
    success: <CheckCircleIcon className="h-6 w-6 text-emerald-500" />,
    warning: <ExclamationCircleIcon className="h-6 w-6 text-yellow-500" />,
    error: <XCircleIcon className="h-6 w-6 text-red-500" />,
  };

  const fallbackText = {
    info: 'Information',
    success: 'Success',
    warning: 'Warning',
    error: 'Error',
  };

  return (
    <div className="relative flex h-full rounded-lg bg-gray-900 p-4">
      {alertIcon[variant]}
      <div className="ml-3 flex flex-col pr-11">
        {title && <span className="text-left font-F37Bolton-Medium text-white">{title}</span>}
        <span className="max-w-[220px] text-sm text-gray-100">
          {subtitle ?? fallbackText[variant]}
        </span>
      </div>
      <XMarkIcon
        className="absolute right-4 top-4 h-4 w-4 cursor-pointer text-gray-400"
        onClick={() => toast.dismiss()}
      />
    </div>
  );
};
