import { useMutation } from '@tanstack/react-query';

import { patchCall } from '@/api/axios';
import useQueryHandlers from '@/hooks/useQueryHandlers/useQueryHandlers';

import { mutationEndpoints } from './endpoints';
import { UpdateAccountInfoType } from './types';

export const useUpdateAccountInfo = ({ accountId, mutationParams }: UpdateAccountInfoType) => {
  const { mutationKey, url, invalidateKeys } = mutationEndpoints.updateAccountInfo(accountId);

  const { onSuccess } = useQueryHandlers({
    mutationParams,
    invalidateKeys,
  });

  return useMutation({
    mutationKey,
    mutationFn: data => patchCall(url, data),
    ...mutationParams,
    onSuccess,
  });
};
