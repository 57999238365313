import { createModel } from '@rematch/core';

import { callToast } from '@/utils/helpers';
import {
  createUserSignupSimple,
  getProfileResource,
  updateAccountResource,
  updatePassword,
  userProfile,
} from '@/api/services/user';
import { downloadInvoice, downloadReceipt } from '@/api/services/billing';
import { UserData } from '@/redux/dto/user';

import type { RootModel } from '.';

type companyState = {
  erorr: unknown;
  user: userProfile;
};

export const user = createModel<RootModel>()({
  state: {
    error: null,
    resourse: null,
  } as unknown as companyState,
  reducers: {
    GET_PROFILE_RESOURSE_SUCCES: (state, payload) => {
      return {
        ...state,
        error: null,
        resourse: payload,
      };
    },
    GET_PROFILE_RESOURSE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_ACCOUNT_RESOURSE_SUCCES: (state, payload) => {
      return {
        ...state,
        error: null,
        resourse: payload,
      };
    },
    UPDATE_ACCOUNT_RESOURSE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_ACCOUNT_PASSWORD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    CREATE_USER_SIGNUP_SIMPLE_SUCCES: (state, payload) => {
      return {
        ...state,
        error: null,
        resourse: payload,
      };
    },
    CREATE_USER_SIGNUP_SIMPLE_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },
  effects: dispatch => {
    const { user, auth } = dispatch;
    return {
      async getProfileResourse(payload): Promise<unknown> {
        try {
          const { id } = payload;
          const { data } = await getProfileResource(id);
          user.GET_PROFILE_RESOURSE_SUCCES(data);
          return data;
        } catch (error: any) {
          user.GET_PROFILE_RESOURSE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateAccountResource(payload): Promise<unknown> {
        try {
          const { id, state } = payload;
          const { data } = await updateAccountResource(id, state);
          user.UPDATE_ACCOUNT_RESOURSE_SUCCES(data);
          auth.UPDATE_ACCOUNT_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          user.UPDATE_ACCOUNT_RESOURSE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateAccountPhoneNumber(payload): Promise<unknown> {
        try {
          const { id, state } = payload;
          const { data } = await updateAccountResource(id, state);
          user.UPDATE_ACCOUNT_RESOURSE_SUCCES(data);
          auth.UPDATE_ACCOUNT_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          user.UPDATE_ACCOUNT_RESOURSE_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateAccountPassword(payload): Promise<unknown> {
        try {
          const { data } = await updatePassword(payload);
          return data;
        } catch (error: any) {
          user.UPDATE_ACCOUNT_PASSWORD_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async createUserSignupSimple(payload): Promise<UserData | unknown> {
        try {
          const data = await createUserSignupSimple(payload);
          user.CREATE_USER_SIGNUP_SIMPLE_SUCCES(data);
          if (data) {
            return data;
          }
        } catch (error: any) {
          user.CREATE_USER_SIGNUP_SIMPLE_FAILURE(error);
          callToast('error', error?.response?.data?.detail);
          return error;
        }
      },
      async downloadInvoice(payload): Promise<Blob | undefined> {
        try {
          const { id } = payload;
          const { data } = await downloadInvoice(id);
          return data;
        } catch (error: any) {
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async downloadReceipt(payload): Promise<Blob | undefined> {
        try {
          const { id } = payload;
          const { data } = await downloadReceipt(id);
          return data;
        } catch (error: any) {
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
