import * as Yup from 'yup';

export const BusinessAddressValidation = Yup.object().shape({
  city: Yup.string()
    .required('City is required')
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/, 'Invalid city name'),
  aptSteNumber: Yup.string(),
  state: Yup.string().required('State is required'),
  streetAddress: Yup.string()
    .required('Street address is required')
    .matches(/^[^,]*$/, 'Street address must not contain a comma'),
  zipCode: Yup.string()
    .required('Zip code is required')
    .matches(/^\d{5}$/, 'Zip code must be exactly 5 digits'),
});
