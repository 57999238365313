import { MutationEndpoint } from '@/api/types';

export const mutationEndpoints = {
  upgradePlan: (): MutationEndpoint => ({
    url: '/subscription-agreement/upgrade',
    mutationKey: ['subscriptionAgreement', 'upgradePlan'],
    invalidateKeys: [],
  }),
  downgradePlan: (): MutationEndpoint => ({
    url: '/subscription-agreement/downgrade',
    mutationKey: ['subscriptionAgreement', 'downgradePlan'],
    invalidateKeys: [],
  }),
};
