import { AxiosResponse } from 'axios';

import axios from '../axios';

interface PaymentHistory {
  id: number;
  status: number;
  amount: number;
  reimbursementAmount: number;
  invoiceNumber: string;
  numberOfHours: number;
  bonus: number;
  totalAmount: number;
  contractorId: number;
  contractorName: string;
  payPeriod: string;
}

//Get Collection Contractor's Payment history

export const getListOfPayrollHistoryContractor = (
  companyId: string,
  page?: number,
  contractorIds?: number[],
  dateOfPaymentBefore?: Date,
  dateOfPaymentAfter?: Date,
): Promise<AxiosResponse<PaymentHistory>> => {
  const params: Record<string, unknown> = {};
  if (page) {
    params.page = page;
    params['order'] = 'desc';
  }
  if (contractorIds && contractorIds.length > 0) {
    params['contractor.id'] = contractorIds;
  }
  if (dateOfPaymentBefore) {
    params['dateOfPayment[before]'] = dateOfPaymentBefore;
  }
  if (dateOfPaymentAfter) {
    params['dateOfPayment[after]'] = dateOfPaymentAfter;
  }
  return axios.get<PaymentHistory>(`/company/${companyId}/contractors/payroll-history`, {
    params: params,
  });
};

// Get Contractor's Payment history details
export const getContractorPaymentHistoryDetails = (companyId: string, paymentHistoryId: string) => {
  return axios.get(`/company/${companyId}/contractors/payroll-history/${paymentHistoryId}/details`);
};

//Get Payment History for a contractor
export const getPayStubsHistoryContractor = (
  companyId: string,
  page?: number,
  contractorId?: number,
  dateOfPaymentBefore?: Date,
  dateOfPaymentAfter?: Date,
): Promise<AxiosResponse<PaymentHistory>> => {
  const params: Record<string, unknown> = {};
  if (page) {
    params.page = page;
    params['order[id]'] = 'desc';
  }
  if (dateOfPaymentBefore) {
    params['contractorPayment.dateOfPayment[before]'] = dateOfPaymentBefore;
  }
  if (dateOfPaymentAfter) {
    params['contractorPayment.dateOfPayment[after]'] = dateOfPaymentAfter;
  }
  return axios.get<PaymentHistory>(
    `/company/${companyId}/contractor/${contractorId}/paystubs-history`,
    {
      params: params,
    },
  );
};

// Get Payment History for a employee

export const getPayStubsHistoryEmployee = (
  companyId: string,
  page?: number,
  employeeId?: number,
  dateOfPaymentBefore?: Date,
  dateOfPaymentAfter?: Date,
): Promise<AxiosResponse<PaymentHistory>> => {
  const params: Record<string, unknown> = {};
  if (page) {
    params.page = page;
    params['order[id]'] = 'desc';
  }
  if (dateOfPaymentBefore) {
    params['employeePayroll.payDate[before]'] = dateOfPaymentBefore;
  }
  if (dateOfPaymentAfter) {
    params['employeePayroll.payDate[after]'] = dateOfPaymentAfter;
  }
  return axios.get<PaymentHistory>(
    `/company/${companyId}/employees/${employeeId}/paystubs-history`,
    {
      params: params,
    },
  );
};

// Get Contractor's Pay stubs

export const getContractorsPayStubs = (companyId: string, id: string) => {
  return axios.get(`/company/${companyId}/contractors/pay-stubs/${id}`);
};

// Get Contractor Pay stubs

export const getContractorPayStubs = (companyId: string, contractorId: string, id: string) => {
  return axios.get(`/company/${companyId}/contractors/${contractorId}/pay-stubs-item/${id}`);
};

export const previewContractorPayStub = (companyId: string, contractorId: string, id: string) =>
  axios.get(`/company/${companyId}/contractor/${contractorId}/preview-pay-stubs/${id}`);

// Download contractor's paystubs (multiple)
export const getContractorPayStubsDownload = (companyId: string, contractorId: string) => {
  return axios.get(`/company/${companyId}/contractors/pay-stubs/${contractorId}/download`, {
    responseType: 'blob',
  });
};

//Download contractor's paysub (single)
export const getContractorPayStubsDownloadItem = (
  companyId: string,
  contractorId: string,
  id: string,
) => {
  return axios.get(
    `/company/${companyId}/contractors/${contractorId}/pay-stubs-item/${id}/download`,
    {
      responseType: 'blob',
    },
  );
};

// Employee part

// Employee Payment history

export const getListOfPayrollHistoryEmployee = (
  companyId: number,
  page?: number,
  dateOfPaymentBefore?: Date,
  dateOfPaymentAfter?: Date,
): Promise<AxiosResponse<PaymentHistory>> => {
  const params: Record<string, unknown> = {};
  if (page) {
    params.page = page;
    params['order'] = 'desc';
  }
  if (dateOfPaymentBefore) {
    params['payDate[before]'] = dateOfPaymentBefore;
  }
  if (dateOfPaymentAfter) {
    params['payDate[after]'] = dateOfPaymentAfter;
  }
  return axios.get<PaymentHistory>(`/company/${companyId}/employees/payroll-history`, {
    params: params,
  });
};

//Reworked employee Payment history
export const getListOfPayrollHistoryEmployeeReworked = (
  companyId: string,
  dateOfPaymentBefore?: Date,
  dateOfPaymentAfter?: Date,
) => {
  const params: Record<string, unknown> = {};
  if (dateOfPaymentBefore) {
    params['payDate[before]'] = dateOfPaymentBefore;
  }
  if (dateOfPaymentAfter) {
    params['payDate[after]'] = dateOfPaymentAfter;
  }
  return axios.get(`/company/${companyId}/employees/payroll-history`, {
    params: params,
  });
};

// Download (PDF), All Pay stubs for specific Payroll

export const getEmployeePayStubsDownloadAll = (companyId: string, id: string) => {
  return axios.get(`/company/${companyId}/employees/payroll-history/${id}/download/pay-stubs`, {
    responseType: 'blob',
  });
};

// Download (PDF), Pay stubs for an employee
export const getEmployeePayStubsDownloadItem = (
  companyId: string,
  employeeId: string,
  id: string,
) => {
  return axios.get(
    `/company/${companyId}/employees/${employeeId}/paystubs-history/download/${id}/pay-stubs`,
    {
      responseType: 'blob',
    },
  );
};
// Get Employee Payment history details

export const getContractorPaymentHistoryDetailsEmployee = (
  companyId: string,
  paymentHistoryId: string,
) => {
  return axios.get(`/company/${companyId}/employees/payroll-history/${paymentHistoryId}/items`);
};

// Download (PDF), All Pay stubs for specific Payroll

export const getEmployeePayStubsDownload = (companyId: string, id: string) => {
  return axios.get(`/company/${companyId}/employees/payroll-history/${id}/download/pay-stubs`, {
    responseType: 'blob',
  });
};

// Download (PDF), Pay stubs for specific Payroll Item

export const getEmployeePayStubsPayrollItem = (
  companyId: string,
  payrollId: string,
  id: string,
) => {
  return axios.get(
    `/company/${companyId}/employees/payroll-history/${payrollId}/download/items/${id}/pay-stubs`,
    {
      responseType: 'blob',
    },
  );
};

//Delete contractor on adding team member

export const deleteContractorOnAdd = (companyId: string, currentContractorId: string) => {
  return axios.delete(`/company/${companyId}/contractor/${currentContractorId}`);
};

//Delete payroll contractor by id

export const deletePayrollContractorById = (companyId: string, id: string) => {
  return axios.delete(`/company/${companyId}/contractor/payroll/${id}`);
};

//Delete payroll employee by id

export const deletePayrollEmployeeById = (companyId: string, id: string) => {
  return axios.delete(`/company/${companyId}/employee/payroll/${id}`);
};

//Delete payroll history
export const deletePayrollEmployeeHistory = (companyId: string, id: string) => {
  return axios.delete(`/company/${companyId}/employees/payroll-history/${id}`);
};
