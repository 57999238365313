import { twMerge } from 'tailwind-merge';

import { LoaderComponent } from '@/components';

import 'react-tabs/style/react-tabs.css';

import AccountSetupTeamMember from '@/components/ui/TabsComponent/TabTitleVariants/AccountSetupTeamMember/AccountSetupTeamMember';
import Tabs from '@/components/ui/TabsComponent/Tabs';

import { myAccountType } from './types';
import useMyAccount from './useMyAccount';

const MyAccount = ({
  cardWrapperClasses,
  cardContainerClasses,
  cardTabPanelClasses,
  onGotoDashboard,
}: myAccountType) => {
  const { subscriptionInformation, isLoading, tabController } = useMyAccount({ onGotoDashboard });

  const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

  return (
    <div
      className={twMerge(
        `relative flex size-full flex-col  bg-white pb-[80px]`,
        `${cardContainerClasses ?? ''}`,
      )}
    >
      <div className="w-full">
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <div className={twMerge(`bg-white`, `${cardWrapperClasses ?? ''}`)}>
            <div className="px-8 pb-4 pt-8">
              <span className="font-F37Bolton-Medium text-[28px] text-gray-900">
                Account & Billing
              </span>
            </div>
            <Tabs
              {...tabController}
              variant="progressBar"
              width="w-full"
              forwardNavigateOnTitleClick={true}
              backwardNavigateOnTitleClick={true}
              isLoading={false}
              className="gap-6"
              tabTitlesComponent={AccountSetupTeamMember}
              tabTitlesComponentProps={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyAccount;
