import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { WithGTMInput } from '../../HOCs/Analytics';

type Props = {
  type: 'price' | 'percentage' | 'hours';
  title: string;
  subtitle?: string;
  defaultValue?: string;
  placeholder?: string;
  register?: any;
  value?: string;
  errorMessage?: string | undefined;
  isPassword?: boolean;
  isNecessary?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  onChange: any;
  onBlur?: any;
  field?: any;
  width?: string;
  font?: string;
  fontSize?: string;
  isOptional?: boolean;
  isLongSubtitle?: boolean;
  isLongTaxDescription?: boolean;
  isVeryLongTaxDescription?: boolean;
  isLongMiddleName?: boolean;
  name?: string;
  refForm?: any;
  parentClasses?: string;
};

const InputDecimalsWithoutGTM = ({
  type,
  subtitle,
  isDisabled,
  fontSize,
  title,
  isNecessary,
  register,
  defaultValue,
  onChange,
  width,
  errorMessage,
  isError,
  value,
  isLongSubtitle,
  placeholder,
  onBlur,
  name,
  refForm,
  isOptional,
  parentClasses,
  ...rest
}: Props) => {
  const isLongTaxDescription = subtitle?.length > 50;
  const [checkedValue, setCheckedValue] = useState<string>(value);
  const handleValueCheck = e => {
    if (type === 'percentage') {
      if (Number(e) > 100 || Number(e) < 0) {
        setCheckedValue('');
        return;
      }
    }
    if (type === 'price') {
      if (Number(e) > 1000000000 || Number(e) < 0) {
        setCheckedValue('');
        return;
      }
    }
    setCheckedValue(e);
  };
  switch (type) {
    case 'price':
      return (
        <div className="flex flex-col">
          {(title || subtitle) && (
            <div className={`flex w-[335px] flex-col  items-start text-sm md:w-full lg:w-full`}>
              <span
                className={`font-medium ${
                  isDisabled && 'text-gray-300'
                } ${fontSize} font-F37Bolton-Medium`}
              >
                {title}
                {isNecessary && (
                  <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
                )}
                {isOptional && <span className="text-gray-300"> (optional)</span>}
              </span>
              <span
                className={`w-full font-normal ${
                  isDisabled ? 'text-gray-200' : 'text-gray-400'
                } mt-[2px] border-red-500`}
              >
                {subtitle}
              </span>
            </div>
          )}
          <div className="relative">
            <CurrencyInput
              decimalsLimit={2}
              defaultValue={defaultValue}
              placeholder="0.00"
              onValueChange={e => {
                onChange(e);
                handleValueCheck(e);
              }}
              step={0.01}
              onWheel={event => event.currentTarget.blur()}
              min={0}
              className={`rounded-md pl-6 ${
                isDisabled
                  ? 'border-gray-100 text-gray-100'
                  : isError
                  ? 'border-red-500'
                  : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
              }  ${width ? width : 'w-full'}
              shadow-sm placeholder:text-gray-200 focus:ring-transparent`}
              disabled={isDisabled}
              decimalSeparator="."
              groupSeparator=","
              value={checkedValue}
              {...rest}
            />
            <span className={`absolute left-[10px]  ${subtitle ? 'top-[9px]' : 'top-[9px]'}`}>
              $
            </span>
          </div>
          {errorMessage && (
            <span
              className={`${
                errorMessage === 'Total payments YTD is required'
                  ? 'relative top-[22px]'
                  : isLongSubtitle
                  ? 'top-[42px]'
                  : 'mt-1'
              } flex h-auto w-full flex-col items-start text-sm text-red-500`}
            >
              {errorMessage}
            </span>
          )}
        </div>
      );

    case 'percentage':
      return (
        <div className="flex flex-col">
          <div className={`w-80 ${subtitle ? 'h-11' : 'h-6'} flex flex-col items-start text-sm`}>
            <span
              className={`font-medium ${
                isDisabled && 'text-gray-300'
              } ${fontSize} font-F37Bolton-Medium`}
            >
              {title}
              {isNecessary && (
                <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
              )}
            </span>
            <span
              className={`w-[335px] font-normal ${
                isDisabled ? 'text-gray-200' : 'text-gray-400'
              } mt-[2px]`}
            >
              {subtitle}
            </span>
          </div>
          <div
            className={`relative ${
              subtitle === 'Total amount of payments made to the contractor this year' &&
              'top-[20px]'
            }`}
          >
            <span
              className={`absolute ${
                isLongTaxDescription ? 'top-[34px]' : 'top-[17px]'
              } left-[10px] text-base text-gray-500`}
            >
              %
            </span>
            <CurrencyInput
              {...register}
              allowDecimals={true}
              decimalScale={2}
              decimalsLimit={2}
              defaultValue={defaultValue}
              placeholder="0.00"
              onValueChange={e => {
                onChange(e);
                handleValueCheck(e);
              }}
              min={0}
              max={100}
              step={1}
              onWheel={event => event.currentTarget.blur()}
              className={`rounded-md pl-[30px] ${
                isDisabled
                  ? 'border-gray-100 text-gray-100'
                  : isError
                  ? 'border-red-500'
                  : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
              }  ${isLongTaxDescription ? 'mt-[25px]' : 'mt-[8px]'} ${
                width ? width : 'w-full'
              } shadow-sm placeholder:text-gray-200 focus:ring-transparent`}
              disabled={isDisabled}
              decimalSeparator="."
              groupSeparator=","
              value={checkedValue}
            />
          </div>
          {errorMessage && (
            <span
              className={`${
                errorMessage === 'Total payments YTD is required'
                  ? 'relative top-[22px]'
                  : isLongSubtitle
                  ? 'top-[22px]'
                  : 'top-[2px]'
              } flex h-auto w-[335px] flex-col items-start text-sm text-red-500`}
            >
              {errorMessage}
            </span>
          )}
        </div>
      );
    case 'hours':
      return (
        <label className="relative flex h-full w-full items-center">
          <CurrencyInput
            allowNegativeValue={false}
            name={name}
            min={0}
            placeholder={placeholder}
            step={0.01}
            decimalsLimit={2}
            defaultValue={defaultValue}
            className={`${parentClasses} h-full w-full border-none pl-[45px] placeholder-gray-300 focus:ring-gray-900`}
            onWheel={event => event.currentTarget.blur()}
            onValueChange={e => {
              onChange(e);
              handleValueCheck(e);
            }}
            onBlur={onBlur}
            ref={refForm}
            disabled={isDisabled}
            decimalSeparator="."
            groupSeparator=","
            {...rest}
          />
          <span
            className={`${
              value ? 'text-gray-900' : 'text-gray-300'
            } absolute left-[10px] top-[11px]`}
          >
            Hrs.
          </span>
        </label>
      );
  }
};

const InputDecimals = WithGTMInput(InputDecimalsWithoutGTM);
export default InputDecimals;
