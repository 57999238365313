import moment from 'moment';
import { useEffect, useImperativeHandle, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { RootState } from '@/redux/store';
import { ACCOUNT_METADATA } from '@/utils/constants';
import {
  calculateBackdatedPayPeriod,
  calculateBiWeekPayPeriods,
  calculateBiWeeklyBackdatedPayPeriod,
  calculateMonthlyBackDatedPayPeriods,
  calculateMonthlyPayPeriods,
  calculatePayPeriods,
  calculateSemiMonthlyPayPeriods,
  calculateWeeklyBackdatedPayPeriod,
  getDatesForOneYear,
  getDaysInMonths,
  getNextMonthDate,
  getNextWeekDays,
  getPayPeriodDate,
  getSemiMonthlyDate,
  getWeekDays,
} from '@/utils/dateConversion';
import {
  findKeyByValue,
  findValueByKey,
  getKeyByValue,
  getObjectValues,
} from '@/utils/getObjectKey';

import {
  PayFrequency,
  PayFrequencyList,
  PayrollScheduleTypes,
  WeekDays,
  WeekDaysList,
  quarters,
} from '../../types';
import {
  dayDifferenceCondition,
  getAllDatesForMonth,
  getAllMonths,
  getMonthPrevDate,
  getPrevSemiMonthDate,
  getPreviousBiWeekDays,
  getPreviousWeekDays,
  getQuarter,
  getSemiMonthOtherPrevDate,
} from '../../utils';
import { UsePayrollScheduleFormType } from './types';

const options = ['Weekly', 'Bi-weekly', 'Semi-monthly', 'Monthly'];

const OverWriteOption = {
  other: 'Custom Pay Period',
};

const usePayrollScheduleForm = ({
  schedule,
  submitHandler,
  ref,
  handleSaveButtonValidation,
}: UsePayrollScheduleFormType) => {
  const [backDatedPayPeriod, setBackDatedPayPeriod] = useState<string[]>([]);
  const [semiMonthPayDate, setSemiMonthPayDate] = useState<string[]>([]);
  const [weekPayDate, setWeekPayDate] = useState<string[]>([]);
  const [monthPayDate, setMonthPayDate] = useState<string[]>([]);
  const [semiMonthPrevPayDate, setSemiMonthPrevPayDate] = useState<string[]>([]);
  const accountInfo = useSelector((state: RootState) => state.auth.onboardingInfo?.account);

  const { payrollScheduleShowBackdated } = ACCOUNT_METADATA || {};
  const shouldShowBackdatedData = accountInfo?.metadata[`${payrollScheduleShowBackdated}`];

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{
    payFrequency: null | string;
    payDay: null;
    payDate: null | string;
    payPeriod: null | string;
    semiMonthly: null;
    backDated: null;
    backDatedPayPeriod: null;
    firstPayPeriod: null;
    firstPayDate: null | string;
    secondPayDate: null | string;
  }>({
    defaultValues: {
      payFrequency: null,
      payDay: null,
      payDate: null,
      payPeriod: null,
      semiMonthly: null,
      backDated: null,
      backDatedPayPeriod: null,
      firstPayPeriod: null,
      firstPayDate: null,
      secondPayDate: null,
    },
    mode: 'onChange',
  });

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      handleSubmit(onSubmit)();
    },
  }));

  const {
    payFrequency,
    payDate,
    payDay,
    semiMonthly,
    firstPayDate,
    secondPayDate,
    backDated,
    payPeriod,
    firstPayPeriod,
    backDatedPayPeriod: formBackDatedPayPeriod,
  } = watch();

  useEffect(() => {
    if (handleSaveButtonValidation) {
      handleSaveButtonValidation(
        shouldShowBackdatedData ? backDated !== 'no' && !formBackDatedPayPeriod : !payPeriod,
      );
    }
  }, [backDated, formBackDatedPayPeriod, payPeriod]);

  const weekDays = getWeekDays();
  const daysInMonth = getDaysInMonths();
  const weekPayPeriod = calculatePayPeriods(payDate);
  const biWeekPayPeriod = calculateBiWeekPayPeriods(payDate);

  const monthlyPayPeriod = calculateMonthlyPayPeriods(payDate, monthPayDate, payDay);

  const firstPayPeriodDateList = getPayPeriodDate(payDate);
  const semiMonthlyPayPeriod = calculateSemiMonthlyPayPeriods(
    payDate,
    semiMonthPayDate,
    firstPayDate,
    secondPayDate,
  );

  useEffect(() => {
    if (schedule) {
      handleScheduleData();
    }
  }, [schedule]);

  useEffect(() => {
    if (semiMonthly) {
      if (semiMonthly == 1 && firstPayDate && secondPayDate) {
        setSemiMonthPayDate(getDatesForOneYear(firstPayDate, secondPayDate));
      } else {
        setSemiMonthPayDate(getSemiMonthlyDate());
      }
    }
  }, [firstPayDate, secondPayDate, JSON.stringify(semiMonthly)]);

  useEffect(() => {
    if (Boolean(payPeriod) && Boolean(semiMonthly)) {
      semiMonthly == 1
        ? setSemiMonthPrevPayDate(getSemiMonthOtherPrevDate(payDate, +firstPayDate, +secondPayDate))
        : setSemiMonthPrevPayDate(getPrevSemiMonthDate(firstPayPeriod || payDate));

      const daysDiff = moment(payDate).diff(firstPayPeriod, 'days');

      const backwardPayPeriod = calculateBackdatedPayPeriod(
        semiMonthPrevPayDate,
        payPeriod,
        daysDiff,
        semiMonthly,
        semiMonthlyPayPeriod,
      );

      setBackDatedPayPeriod(backwardPayPeriod);
    }
  }, [payDay, payPeriod, firstPayPeriod, backDated, JSON.stringify(semiMonthPrevPayDate)]);

  useEffect(() => {
    if (payDay) {
      setMonthPayDate(getNextMonthDate(payDay));
    }
    if (payFrequency === 'monthly' && payDate) {
      const date = getMonthPrevDate(firstPayPeriod || payDate, payDay, firstPayPeriod);

      setBackDatedPayPeriod(
        calculateMonthlyBackDatedPayPeriods(
          date,
          payPeriod,
          monthlyPayPeriod,
          payDay,
          firstPayPeriod,
        ),
      );
    }
  }, [
    payDay,
    payPeriod,
    firstPayPeriod,
    backDated,
    JSON.stringify(monthlyPayPeriod),
    JSON.stringify(monthPayDate),
  ]);

  useEffect(() => {
    if ((payFrequency === 'weekly' || payFrequency === 'biweekly') && payDay) {
      setWeekPayDate(getNextWeekDays(payDay));
    }
  }, [payDay]);

  useEffect(() => {
    if (payFrequency === 'weekly') {
      const date = getPreviousWeekDays(firstPayPeriod || payDate);
      const backwardPayPeriod = calculateWeeklyBackdatedPayPeriod(date, payPeriod, weekPayPeriod);
      setBackDatedPayPeriod(backwardPayPeriod);
    } else if (payFrequency === 'biweekly') {
      const date = getPreviousBiWeekDays(firstPayPeriod || payDate);
      const backwardPayPeriod = calculateBiWeeklyBackdatedPayPeriod(
        date,
        payPeriod,
        biWeekPayPeriod,
      );
      setBackDatedPayPeriod(backwardPayPeriod);
    }
  }, [backDated, weekPayDate]);

  const handlePayFieldChange = (field: string[]) => {
    field.forEach((el: string) => {
      setValue(el, null);
    });
  };

  const handleSemiMonthlyFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FieldValues,
  ) => {
    field.onChange(e);
    handlePayFieldChange([
      'payDay',
      'payDate',
      'payPeriod',
      'firstPayDate',
      'secondPayDate',
      'backDatedPayPeriod',
      'backDated',
      'firstPayPeriod',
    ]);
  };

  const handlePayDayFieldChange = (e: React.ChangeEvent<HTMLInputElement>, field: FieldValues) => {
    field.onChange(e);
    handlePayFieldChange(['payPeriod', 'payDate', 'backDated', 'backDatedPayPeriod']);
  };

  const payDayOptions = {
    weekly: {
      label: 'Which day of the week?',
      options: weekDays,
      payDateOption: { label: 'Select your upcoming pay date', options: weekPayDate },
      payPeriodOption: { label: 'Select the pay period', options: weekPayPeriod },
      firstPayPeriod: { label: 'First pay period ends on', options: firstPayPeriodDateList },
      backDatedPayPeriod: {
        label: 'Pick the pay period you want to start with',
        options: backDatedPayPeriod,
      },
    },
    biweekly: {
      label: 'Which day of the week?',
      options: weekDays,
      payDateOption: { label: 'Select your upcoming pay date', options: weekPayDate },
      payPeriodOption: { label: 'Select the pay period', options: biWeekPayPeriod },
      firstPayPeriod: { label: 'First pay period ends on', options: firstPayPeriodDateList },
      backDatedPayPeriod: {
        label: 'Pick the pay period you want to start with',
        options: backDatedPayPeriod,
      },
    },
    monthly: {
      label: 'Which day of the month do you pay your employees?',
      options: daysInMonth,
      payDateOption: { label: 'Select your upcoming pay date', options: monthPayDate },
      payPeriodOption: { label: 'Select the pay period', options: monthlyPayPeriod },
      firstPayPeriod: { label: 'First pay period ends on', options: firstPayPeriodDateList },
      backDatedPayPeriod: {
        label: 'Pick the pay period you want to start with',
        options: backDatedPayPeriod,
      },
    },
    semimonthly: {
      label: '',
      option: [],
      firstPayDate: { label: 'When is the first pay day of the month?', options: daysInMonth },
      secondPayDate: { label: 'When is the second pay day of the month?', options: daysInMonth },
      payDateOption: { label: 'Select your upcoming pay date', options: semiMonthPayDate },
      payPeriodOption: { label: 'Select the pay period', options: semiMonthlyPayPeriod },
      firstPayPeriod: { label: 'First pay period ends on', options: firstPayPeriodDateList },
      backDatedPayPeriod: {
        label: 'Pick the pay period you want to start with',
        options: backDatedPayPeriod,
      },
    },
    quarterly: {
      label: 'First month?',
      options: getAllMonths(),
      payDateOption: {
        label: 'Select your upcoming pay date',
        options: getAllDatesForMonth(+moment(payDay).format('M')),
      },
      payPeriodOption: {
        label: 'Select the pay period',
        options: getQuarter(+moment(payDay).format('M')),
      },
      backDatedPayPeriod: {
        label: 'Pick the pay period you want to start with',
        options: Object.keys(quarters),
      },
    },
  };

  const difference = dayDifferenceCondition(+firstPayDate, +secondPayDate);

  const handleScheduleData = () => {
    const firstPayPeriodEndDate = schedule.firstPayPeriodEndDate;
    setValue('payFrequency', getKeyByValue(PayFrequencyList, schedule.payFrequency));
    setValue('payDate', moment(schedule.firstPayDate).format('MM/DD/YYYY'));
    setValue('backDated', firstPayPeriodEndDate ? 'yes' : 'no');

    if (getKeyByValue(PayFrequencyList, schedule.payFrequency) === 'monthly') {
      const payDayValue =
        schedule.dayOfTheMonth === 31 ? 'Last day of the month' : schedule.dayOfTheMonth;
      setValue('payDay', payDayValue);

      const editMonthPayDat = getNextMonthDate(schedule.dayOfTheMonth);

      const monthlyPayPeriod = calculateMonthlyPayPeriods(
        moment(schedule.firstPayDate).format('MM/DD/YYYY'),
        editMonthPayDat,
      );

      const payPeriodKey = findValueByKey(
        monthlyPayPeriod,
        moment(schedule.payPeriodEndDate).format('YYYY-MM-DD'),
      );

      const otherCondition =
        moment(schedule.payPeriodEndDate).isSame(moment(schedule.firstPayDate)) ||
        Boolean(payPeriodKey);

      setValue('payPeriod', otherCondition ? payPeriodKey : OverWriteOption.other);

      if (!payPeriodKey) {
        setValue('firstPayPeriod', moment(schedule.payPeriodEndDate).format('MM/DD/YYYY'));
      }

      setValue(
        'backDatedPayPeriod',
        firstPayPeriodEndDate ? moment(firstPayPeriodEndDate).format('YYYY-MM-DD') : null,
      );
    } else if (getKeyByValue(PayFrequencyList, schedule.payFrequency) === 'weekly') {
      setValue('payDay', WeekDaysList[schedule.dayOfTheWeek]);

      const weekPayPeriod = calculatePayPeriods(moment(schedule.firstPayDate).format('MM/DD/YYYY'));
      const payPeriodKey = findValueByKey(
        weekPayPeriod,
        moment(schedule.payPeriodEndDate).format('YYYY-MM-DD'),
      );

      const otherCondition =
        moment(schedule.payPeriodEndDate).isSame(moment(schedule.firstPayDate)) ||
        Boolean(payPeriodKey);

      setValue('payPeriod', otherCondition ? payPeriodKey : OverWriteOption.other);

      const date = getPreviousWeekDays(
        moment(schedule.payPeriodEndDate).format('MM/DD/YYYY') ||
          moment(schedule.firstPayDate).format('MM/DD/YYYY'),
      );

      const backwardPayPeriod = calculateWeeklyBackdatedPayPeriod(
        date,
        findValueByKey(weekPayPeriod, moment(schedule.payPeriodEndDate).format('YYYY-MM-DD'))
          ? findValueByKey(weekPayPeriod, moment(schedule.payPeriodEndDate).format('YYYY-MM-DD'))
          : OverWriteOption.other,
        weekPayPeriod,
      );

      if (!payPeriodKey) {
        setValue('firstPayPeriod', moment(schedule.payPeriodEndDate).format('MM/DD/YYYY'));
      }

      setValue(
        'backDatedPayPeriod',
        findValueByKey(
          backwardPayPeriod,
          firstPayPeriodEndDate ? moment(firstPayPeriodEndDate).format('YYYY-MM-DD') : null,
        ),
      );
    } else if (getKeyByValue(PayFrequencyList, schedule.payFrequency) === 'biweekly') {
      setValue('payDay', WeekDaysList[schedule.dayOfTheWeek]);

      const biWeekPayDate = getNextWeekDays(WeekDaysList[schedule.dayOfTheWeek]);

      const biWeekPayPeriod = calculateBiWeekPayPeriods(
        moment(schedule.firstPayDate).format('MM/DD/YYYY'),
      );

      const payPeriodKey = findValueByKey(
        biWeekPayPeriod,
        moment(schedule.payPeriodEndDate).format('YYYY-MM-DD'),
      );

      const otherCondition =
        moment(schedule.payPeriodEndDate).isSame(moment(schedule.firstPayDate)) ||
        Boolean(payPeriodKey);

      setValue('payPeriod', otherCondition ? payPeriodKey : OverWriteOption.other);

      const date = getPreviousBiWeekDays(
        moment(schedule.payPeriodEndDate).format('MM/DD/YYYY') ||
          moment(schedule.firstPayDate).format('MM/DD/YYYY'),
      );
      const backwardPayPeriod = calculateBiWeeklyBackdatedPayPeriod(date);
      if (!payPeriodKey) {
        setValue('firstPayPeriod', moment(schedule.payPeriodEndDate).format('MM/DD/YYYY'));
      }

      setValue(
        'backDatedPayPeriod',
        findValueByKey(
          backwardPayPeriod,
          firstPayPeriodEndDate ? moment(firstPayPeriodEndDate).format('YYYY-MM-DD') : null,
        ),
      );
    } else if (getKeyByValue(PayFrequencyList, schedule.payFrequency) === 'semimonthly') {
      setValue(
        'semiMonthly',
        (schedule.dayOfTheMonth === 15 && schedule.dayOfTheMonth2 === 29) ||
          schedule.dayOfTheMonth2 === 31
          ? 15
          : 1,
      );
      setValue('firstPayDate', schedule.dayOfTheMonth);
      setValue('secondPayDate', schedule.dayOfTheMonth2);
      let ediSemiMonthDate;
      if (
        (schedule.dayOfTheMonth === 15 && schedule.dayOfTheMonth2 === 29) ||
        schedule.dayOfTheMonth2 === 31
      ) {
        ediSemiMonthDate = getSemiMonthlyDate();
      } else {
        ediSemiMonthDate = getDatesForOneYear(schedule.dayOfTheMonth, schedule.dayOfTheMonth2);
      }

      const semiMonthPayPeriod = calculateSemiMonthlyPayPeriods(
        moment(schedule.firstPayDate).format('MM/DD/YYYY'),
        ediSemiMonthDate,
        schedule.dayOfTheMonth,
        schedule.dayOfTheMonth2,
      );

      const payPeriodKey = findValueByKey(
        semiMonthPayPeriod,
        moment(schedule.payPeriodEndDate).format('YYYY-MM-DD'),
      );

      const otherCondition =
        moment(schedule.payPeriodEndDate).isSame(moment(schedule.firstPayDate)) ||
        Boolean(payPeriodKey);

      setValue('payPeriod', otherCondition ? payPeriodKey : OverWriteOption.other);

      if (!payPeriodKey) {
        setValue('firstPayPeriod', moment(schedule.payPeriodEndDate).format('MM/DD/YYYY'));
      }

      const semiMonthPrevDate = schedule.dayOfTheMonth2
        ? getSemiMonthOtherPrevDate(
            schedule.firstPayDate,
            +schedule.dayOfTheMonth,
            +schedule.dayOfTheMonth2,
          )
        : getPrevSemiMonthDate(schedule.firstPayDate);

      const daysDiff = moment(schedule.firstPayDate).diff(schedule.payPeriodEndDate, 'days');
      const backwardSemiMonthPayPeriod = calculateBackdatedPayPeriod(
        semiMonthPrevDate,
        payPeriodKey,
        daysDiff,
        schedule.firstPayDate,
        semiMonthPayPeriod,
      )?.filter(Boolean);

      const backDatedIndex = backwardSemiMonthPayPeriod?.findIndex(
        el => el && Object.keys(el) == moment(firstPayPeriodEndDate).format('YYYY-MM-DD'),
      );
      const backDateArr = getObjectValues(backwardSemiMonthPayPeriod);
      setValue('backDatedPayPeriod', backDatedIndex >= 0 ? backDateArr[backDatedIndex] : null);
    }
  };

  const onSubmit: SubmitHandler<PayrollScheduleTypes> = async formData => {
    const semiMonthlyData = {
      payFrequency: PayFrequency[formData.payFrequency],
      firstPayDate: moment(formData.payDate).format('YYYY-MM-DD'),
      firstPayPeriodEndDate: findKeyByValue(backDatedPayPeriod, formData.backDatedPayPeriod),
      payPeriodEndDate:
        payPeriod === OverWriteOption.other
          ? moment(formData.firstPayPeriod).format('YYYY-MM-DD')
          : findKeyByValue(semiMonthlyPayPeriod, formData.payPeriod),
      dayOfTheMonth: semiMonthly == 15 ? 15 : +formData.firstPayDate,
      dayOfTheMonth2: semiMonthly == 15 ? 31 : +formData.secondPayDate,
    };

    const monthlyData = {
      payFrequency: PayFrequency[formData.payFrequency],
      firstPayDate: moment(formData.payDate).format('YYYY-MM-DD'),
      dayOfTheMonth: formData.payDay === 'Last day of the month' ? 31 : +formData.payDay,
      payPeriodEndDate:
        payPeriod === OverWriteOption.other
          ? moment(formData.firstPayPeriod).format('YYYY-MM-DD')
          : findKeyByValue(monthlyPayPeriod, formData.payPeriod),
      firstPayPeriodEndDate: formData.backDatedPayPeriod,
    };

    const weeklyData = {
      payFrequency: PayFrequency[formData.payFrequency],
      firstPayDate: moment(formData.payDate).format('YYYY-MM-DD'),
      payPeriodEndDate:
        payPeriod === OverWriteOption.other
          ? moment(formData.firstPayPeriod).format('YYYY-MM-DD')
          : findKeyByValue(weekPayPeriod, formData.payPeriod),
      firstPayPeriodEndDate: findKeyByValue(backDatedPayPeriod, formData.backDatedPayPeriod),
      dayOfTheWeek: WeekDays[formData.payDay],
    };

    const biWeekData = {
      payFrequency: PayFrequency[formData.payFrequency],
      firstPayDate: moment(formData.payDate).format('YYYY-MM-DD'),
      payPeriodEndDate:
        payPeriod === OverWriteOption.other
          ? moment(formData.firstPayPeriod).format('YYYY-MM-DD')
          : findKeyByValue(biWeekPayPeriod, formData.payPeriod),
      firstPayPeriodEndDate: findKeyByValue(backDatedPayPeriod, formData.backDatedPayPeriod),
      dayOfTheWeek: WeekDays[formData.payDay],
    };

    const quarterly = {
      payFrequency: PayFrequency[formData.payFrequency],
      firstPayDate: moment(formData.payDate).format('YYYY-MM-DD'),
    };

    let formattedData = null;

    if (payFrequency === 'weekly') {
      formattedData = weeklyData;
    } else if (payFrequency === 'biweekly') {
      formattedData = biWeekData;
    } else if (payFrequency === 'monthly') {
      formattedData = monthlyData;
    } else if (payFrequency === 'semimonthly') {
      formattedData = semiMonthlyData;
    }

    submitHandler(formattedData);
  };

  return {
    watch,
    handlePayFieldChange,
    register,
    handleSubmit,
    control,
    options,
    OverWriteOption,
    handleSemiMonthlyFieldChange,
    data: payDayOptions[payFrequency || 'semimonthly'],
    handlePayDayFieldChange,
    difference,
    onSubmit,
    shouldShowBackdatedData,
    errors,
  };
};

export default usePayrollScheduleForm;
