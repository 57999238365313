import { isObjHasData } from '@/utils/helpers';
import { useEffect, useState } from 'react';
import usePostCancelSubscription from '../apis/usePostCancelSubscription';
import { DetailsForm } from '../forms/DetailsForm/DetailsForm';
import { DifficultiesForm } from '../forms/DifficultiesForm/DifficultiesForm';
import { LastTabContent } from '../forms/LastTabContent/LastTabContent';
import { ReasonForm } from '../forms/ReasonForm/ReasonForm';
import { CancelObjectType } from '../types';
import { useCancelSubscriptionReworkedType } from './types';

export const useCancelSubscriptionReworked = ({ onClose }: useCancelSubscriptionReworkedType) => {
  const [currentTab, setCurrentTab] = useState<number>(1);

  const [cancelObject, setCancelObject] = useState<CancelObjectType>({});
  const [isContentHidden, setIsContentHidden] = useState<boolean>(false);
  const [isBackButtonVisible, setIsBackButtonVisible] = useState<boolean>(false);
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState<boolean>(false);

  const nextTab = (values: Object) => {
    setCancelObject(prevObject => ({ ...prevObject, ...values }));
    setCurrentTab(prevTab => ++prevTab);
  };

  const previousTab = () => {
    setCurrentTab(prevTab => --prevTab);
    setIsContentHidden(false);
  };

  const { mutate: mutateCancelSubscription, status } = usePostCancelSubscription({
    onClose,
  });

  const handleCallNow = () => {
    mutateCancelSubscription({ data: cancelObject, cancellationActionType: 'call_now' });
  };

  const handleScheduleCall = () => {
    mutateCancelSubscription({ data: cancelObject, cancellationActionType: 'schedule_a_call' });
    setIsCalendlyModalOpen(true);
  };

  const handleCancelSubscription = () => {
    mutateCancelSubscription({ data: cancelObject, cancellationActionType: 'cancel_subscription' });
  };

  const renderStepThree = () => {
    if (
      cancelObject.reason === '1' ||
      (cancelObject.reason === '2' && cancelObject.difficulties?.includes('other')) ||
      cancelObject.reason === '3' ||
      cancelObject.reason === '4'
    )
      return (
        <LastTabContent
          reason={cancelObject.reason}
          cancelObject={cancelObject}
          handleCancelSubscription={handleCancelSubscription}
          handleScheduleCall={handleScheduleCall}
          handleCallNow={handleCallNow}
          isLoading={status === 'pending'}
        />
      );
    if (cancelObject.reason === '2' && !cancelObject.difficulties?.includes('other'))
      return <DifficultiesForm nextTab={nextTab} />;
  };

  const renderSpanContent = () => {
    if (currentTab === 1) return `You’re leaving? 😔`;
    if (currentTab === 2) return `We’d love to do better next time`;
    if (currentTab === 3 && cancelObject.reason === '2') return `We’d love to do better next time`;
  };

  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: '',
      disabled: false,
      content: <ReasonForm nextTab={nextTab} />,
    },
    {
      id: 2,
      title: '',
      disabled: false,
      content: (
        <DetailsForm
          reason={cancelObject.reason}
          nextTab={nextTab}
          handleCancelSubscription={handleCancelSubscription}
          handleScheduleCall={handleScheduleCall}
          handleCallNow={handleCallNow}
          isLoading={status === 'pending'}
        />
      ),
    },
    {
      id: 3,
      title: '',
      disabled: false,
      content: renderStepThree(),
    },
    {
      id: 4,
      title: '',
      disabled: false,
      content: (
        <LastTabContent
          reason={cancelObject.reason}
          handleCancelSubscription={handleCancelSubscription}
          handleScheduleCall={handleScheduleCall}
          handleCallNow={handleCallNow}
          isLoading={status === 'pending'}
        />
      ),
    },
  ]);

  useEffect(() => {
    setIsContentHidden(
      (isObjHasData(cancelObject) && cancelObject.reason === 'other') ||
        (currentTab === 3 &&
          (cancelObject.reason !== '2' || cancelObject.difficulties?.includes('other'))) ||
        currentTab === 4,
    );
    setIsBackButtonVisible(currentTab !== 1 && !isContentHidden);
    const updatedTabs = [
      {
        id: 1,
        title: '',
        disabled: false,
        content: <ReasonForm nextTab={nextTab} />,
      },
      {
        id: 2,
        title: '',
        disabled: false,
        content: (
          <DetailsForm
            reason={cancelObject.reason}
            nextTab={nextTab}
            handleCancelSubscription={handleCancelSubscription}
            handleScheduleCall={handleScheduleCall}
            handleCallNow={handleCallNow}
            isLoading={status === 'pending'}
          />
        ),
      },
      {
        id: 3,
        title: '',
        disabled: false,
        content: renderStepThree(),
      },
      {
        id: 4,
        title: '',
        disabled: false,
        content: (
          <LastTabContent
            reason={cancelObject.reason}
            handleCancelSubscription={handleCancelSubscription}
            handleScheduleCall={handleScheduleCall}
            handleCallNow={handleCallNow}
            isLoading={status === 'pending'}
          />
        ),
      },
    ];
    setTabs(updatedTabs);
  }, [cancelObject, status, currentTab, isContentHidden]);

  return {
    tabs,
    currentTab,
    setCurrentTab,
    renderSpanContent,
    previousTab,
    isContentHidden,
    reason: cancelObject.reason,
    isCalendlyModalOpen,
    setIsCalendlyModalOpen,
    isBackButtonVisible,
  };
};
