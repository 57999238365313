import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormInputType, UseCustomizationModalType } from './types';
import { OnboardingCustomizationSchema } from './validations';

const useCustomizationModal = ({ onSaveClickHandler }: UseCustomizationModalType) => {
  const form = useForm({
    defaultValues: {
      managePayroll: null,
    },
    resolver: yupResolver(OnboardingCustomizationSchema),
  });

  const saveClickHandler = (value: string) => {
    onSaveClickHandler(value);
  };

  const onSubmit: SubmitHandler<FormInputType> = values => {
    saveClickHandler(values.managePayroll);
  };

  return { onSubmit, form };
};

export default useCustomizationModal;
