/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useRadio, useRadioGroup } from '@react-aria/radio';
import { RadioGroupState, useRadioGroupState } from '@react-stately/radio';
import { forwardRef, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { isArrayHasData } from '@/utils/helpers';

interface Option {
  value: string | number | boolean | null;
  label: string;
  disabled?: boolean;
  subtitle?: string;
  tag?: string;
}

interface RadioOptionProps {
  option: Option;
  state: RadioGroupState;
  globalDisabled?: boolean;
  trackEvent?: (name: string, data: Object) => void;
  onChange: (value: string | number | boolean | null) => void;
  selectedValue: string | number | boolean | null;
  error?: boolean;
  optionClasses?: string;
  isColumn?: boolean;
  name: string;
}

const RadioOption = ({
  option,
  state,
  globalDisabled,
  trackEvent,
  onChange,
  selectedValue,
  error,
  optionClasses,
  isColumn,
  name,
}: RadioOptionProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps } = useRadio(
    {
      'aria-label': option.label,
      //@ts-ignore
      value: option.value,
      isDisabled: globalDisabled || option.disabled,
    },
    state,
    ref,
  );

  // eslint-disable-next-line eqeqeq
  const isSelected = selectedValue == option.value;

  const handleChange = () => {
    onChange(option.value);
    if (trackEvent) trackEvent('inputChange', option);
  };

  return (
    <label
      htmlFor={inputProps.id}
      className={twMerge(
        `flex cursor-pointer flex-col  gap-x-2 rounded-lg border   ${
          isSelected ? 'border-emerald-500 bg-emerald-50' : 'border-gray-300 bg-white'
        } px-5 py-[10px] text-base ${
          globalDisabled || option.disabled ? 'cursor-not-allowed opacity-50' : ''
        } ${error ? 'border-red-500' : ''} ${
          option?.subtitle ? 'w-full max-w-[330px]' : 'min-w-[140px] items-start'
        } ${isColumn && 'mt-3 max-w-full'} ${optionClasses ? optionClasses : ''}`,
      )}
    >
      <div className={`${option?.subtitle ? 'flex flex-row-reverse justify-between' : 'flex'}`}>
        <input
          {...inputProps}
          ref={ref}
          name={name}
          onChange={handleChange}
          checked={isSelected}
          disabled={globalDisabled || option.disabled}
          className="visuallyHidden"
        />
        <div
          className={`flex size-6 items-center justify-center rounded-full border border-gray-300 ${
            isSelected ? 'bg-white' : ''
          }`}
        >
          <div className={`size-4 rounded-full ${isSelected ? 'bg-emerald-500' : ''}`}></div>
        </div>
        <div className="flex">
          <span className={`text-gray-700 ${!option?.subtitle && 'ms-2'}`}>{option.label}</span>
          {option?.tag && (
            <span className="ml-1.5 flex h-fit items-center justify-center rounded border border-gray-200 bg-white px-2 py-1 text-xs">
              {option?.tag}
            </span>
          )}
        </div>
      </div>
      {option?.subtitle && (
        <span className={`mt-1 text-sm text-gray-400 ${!isColumn && 'max-w-[270px]'}`}>
          {option?.subtitle}
        </span>
      )}
    </label>
  );
};

export interface RadioGroupProps {
  label: string;
  options: Option[];
  description?: string;
  showAsterisk?: boolean;
  disabled?: boolean;
  trackEvent?: (name: string, data: Object) => void;
  onChange: (value: string | number | boolean | null) => void;
  selectedValue: string | number | boolean | null;
  errorMessage?: string;
  optionWrapperClasses?: string;
  optionClasses?: string;
  labelClasses?: string;
  width?: string;
  hasSubtitle?: boolean;
  isColumn?: boolean;
  name: string;
}

const RadioGroup = forwardRef(
  (
    {
      label,
      options,
      description,
      showAsterisk,
      disabled: globalDisabled,
      trackEvent,
      onChange,
      selectedValue,
      errorMessage,
      optionWrapperClasses,
      optionClasses,
      labelClasses,
      width,
      isColumn = false,
      name,
    }: RadioGroupProps,
    ref,
  ) => {
    const state = useRadioGroupState({
      //@ts-ignore
      defaultValue: selectedValue,
    });
    const { radioGroupProps, labelProps } = useRadioGroup(
      { label, 'aria-label': label ? label : 'radio-group' },
      state,
    );

    if (!isArrayHasData(options)) return null;

    return (
      <div {...radioGroupProps} className={`flex flex-col gap-y-2 ${width && width}`}>
        <p {...labelProps} className={`font-F37Bolton-Medium ${labelClasses}`}>
          {label} {showAsterisk && <span className="font-bold text-red-500">*</span>}
        </p>
        <div
          className={`flex gap-x-2 ${optionWrapperClasses ? optionWrapperClasses : ''} ${
            isColumn && 'flex-col'
          }`}
          ref={ref}
        >
          {options.map((option, index) => (
            <RadioOption
              key={index}
              option={option}
              state={state}
              globalDisabled={globalDisabled}
              trackEvent={trackEvent}
              onChange={onChange}
              selectedValue={selectedValue}
              error={!!errorMessage}
              optionClasses={optionClasses}
              isColumn={isColumn}
              name={name}
            />
          ))}
        </div>
        {description && <p className="text-sm text-gray-400">{description}</p>}
        {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
      </div>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
