import { FC } from 'react';
import { MenuItem as ReactAriaMenuItem } from 'react-aria-components';

import { AppMenuItemProps } from './types';

const MenuItem: FC<AppMenuItemProps> = ({ children, startIcon, ...rest }) => {
  return (
    <ReactAriaMenuItem {...rest}>
      <>
        {startIcon}
        {children}
      </>
    </ReactAriaMenuItem>
  );
};

export default MenuItem;
