import {
  FormInputMaskTextField,
  FormInputTextField,
  UnsavedDataConfirmationModal,
} from '@/components';
import Button from '@/components/Button/Button';
import Drawer from '@/components/ui/Drawer/Drawer';
import { FormProvider } from 'react-hook-form';
import { AccountInfoFormProps } from './types';
import useAccountBasicInfoForm from './useAccountBasicInfoForm';

const AccountBasicInfoForm = ({
  isOpen,
  onClose,
  userInfo,
  isLoading = false,
  handleSaveInfo,
}: AccountInfoFormProps) => {
  const {
    form,
    handleEditBasicInfo,
    isDirty,
    drawerCloseHandler,
    cancelUnsavedChangesHandler,
    confirmUnsavedChangesHandler,
    isConfirmationModalOpen,
  } = useAccountBasicInfoForm({
    account: userInfo,
    handleSaveInfo,
    onClose,
  });

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={drawerCloseHandler}
        title="Edit Profile"
        modelClasses="w-full md:!w-[640px]"
      >
        <FormProvider {...form}>
          <form
            className=" flex flex-col gap-5 py-6"
            onSubmit={form.handleSubmit(handleEditBasicInfo)}
          >
            <FormInputTextField
              name="firstName"
              key="firstName"
              isRequired
              title="First Name"
              width="w-full"
            />
            <FormInputTextField
              name="lastName"
              key="lastName"
              isRequired
              title="Last Name"
              width="w-full"
            />

            <FormInputMaskTextField
              name={`phoneNumber`}
              title="Phone number"
              placeholder="Your work phone number"
              isRequired
              width="w-full"
              mask="(999) 999-9999"
              maskChar="_"
            />
            <FormInputTextField
              name="email"
              key="email"
              isDisabled
              type="email"
              title="Email address"
              width="w-full"
              inputClasses="text-gray-400 border-gray-300"
            />

            <div className="absolute bottom-0 ml-[-24px] flex w-full justify-between gap-3 border-t-[1px] p-5">
              <Button
                variant="light"
                children="Close"
                width="w-full max-w-fit py-3 px-6"
                onPress={drawerCloseHandler}
              />

              <Button
                isDisabled={!isDirty}
                isLoading={isLoading}
                variant="primary"
                type="submit"
                children="Save changes"
                width="w-full sm:min-w-[149px] py-3 max-w-fit px-6"
              />
            </div>
          </form>
        </FormProvider>
      </Drawer>
      <UnsavedDataConfirmationModal
        isOpen={isConfirmationModalOpen}
        onCancel={cancelUnsavedChangesHandler}
        onConfirm={confirmUnsavedChangesHandler}
      />
    </>
  );
};

export default AccountBasicInfoForm;
