import { useState, useContext, useCallback } from 'react';
import InputMask from 'react-input-mask';
import debounce from 'lodash.debounce';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';

import { ReactComponent as ClosedEyeIcon } from '@/assets/closedEye.svg';
import { ReactComponent as EyeIcon } from '@/assets/eye.svg';
import { extractEventName } from '@/utils/helpers';
import { RootState } from '@/redux/store';

import { WithGTMInput } from '../../HOCs/Analytics';
import { CurrentStepContext } from '@/App';

type Props = {
  type:
    | 'default'
    | 'password'
    | 'price'
    | 'number'
    | 'phoneNumber'
    | 'counter'
    | 'calendar'
    | 'textarea'
    | 'suite'
    | 'expiration'
    | 'ein'
    | 'email'
    | 'percentage';
  title: string;
  subtitle?: string;
  defaultValue?: string;
  placeholder?: string;
  register?: any;
  value?: string;
  errorMessage?: string | undefined;
  isPassword?: boolean;
  isNecessary?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  onChange?: (value: string | number | null) => void;
  field?: any;
  width?: string;
  font?: string;
  fontSize?: string;
  isOptional?: boolean;
  isLongSubtitle?: boolean;
  isLongTaxDescription?: boolean;
  pl?: string;
  isLongMiddleName?: boolean;
  noLabel?: boolean;
};

const InputWithoutGTM = ({
  type,
  title,
  subtitle,
  placeholder,
  isError,
  register,
  value,
  isNecessary,
  isDisabled,
  onChange,
  errorMessage,
  defaultValue,
  field,
  width,
  font,
  fontSize,
  isOptional,
  isLongSubtitle,
  isLongTaxDescription,
  isLongMiddleName,
  noLabel,
  pl,
}: Props) => {
  const [active, setActive] = useState(value ? value : '');
  const [showPassword, setShowPassword] = useState(false);
  const { stepperInformation } = useContext(CurrentStepContext);
  const uuid = useSelector((state: RootState) => state?.auth?.user?.account?.uuid);

  const handleInputBlur = useCallback(
    debounce((name: string) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'inputChange',
          name: name,
          currentPage: extractEventName(location.pathname),
          currentStep: stepperInformation.currentStep,
          previousStep: stepperInformation.previousStep,
          currentStepName: 'EmployeeDetailsForm',
          previousStepName: 'hardcoded',
          userId: uuid,
        },
      });
    }, 700),
    [],
  );

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  switch (type) {
    case 'default':
      return (
        <div>
          {title && (
            <div className="mb-[2px] flex w-full flex-col items-start text-sm">
              <span
                className={`flex font-F37Bolton-Medium font-medium lg:w-[300px] ${
                  fontSize && fontSize
                } ${isDisabled && 'text-gray-300'} ${font}`}
              >
                <span className="font-F37Bolton-Medium">{title}</span>
                {isNecessary && (
                  <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'} ml-1`}>*</span>
                )}
                {isOptional && <span className="ml-2 text-gray-300"> (optional)</span>}
              </span>
              <span
                className={`mt-[2px] text-sm font-normal ${
                  isDisabled ? 'text-gray-200' : 'text-gray-400'
                }`}
              >
                {subtitle}
              </span>
            </div>
          )}
          <input
            {...register}
            {...field}
            defaultValue={defaultValue}
            type="text"
            placeholder={placeholder}
            onChange={e => {
              if (register && register.onChange) {
                register.onChange(e);
              }
              if (onChange) {
                onChange(e);
              }
              setActive(e.target.value);
            }}
            className={`block h-[44px] ${width ? width : 'w-[335px]'} rounded-md ${
              isDisabled
                ? 'border-gray-100 text-gray-100'
                : errorMessage
                ? 'border-red-500 focus:border-red-500'
                : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
            }  shadow-sm focus:ring-transparent ${
              isLongMiddleName ? 'relative top-[22px] lg:top-0' : 'relative top-[2px] lg:top-0'
            }`}
            disabled={isDisabled}
            onBlur={e => {
              register && register.onBlur(e);
              handleInputBlur(title);
            }}
          />
          {errorMessage && (
            <span className="flex h-auto w-full flex-col items-start text-sm text-red-500">
              {errorMessage}
            </span>
          )}
        </div>
      );
    case 'password':
      return (
        <div className="flex flex-col items-center justify-center">
          <div className={`w-80 ${subtitle ? 'h-11' : 'h-6'} flex flex-col items-start text-sm`}>
            <span className={`font-F37Bolton-Medium ${isDisabled && 'text-gray-300'}`}>
              {title}
            </span>
            <span className={`font-normal ${isDisabled ? 'text-gray-200' : 'text-gray-400'}`}>
              {subtitle}
            </span>
          </div>
          <div className="relative">
            <input
              {...register}
              type={showPassword ? 'text' : 'password'}
              defaultValue={defaultValue}
              onChange={e => {
                if (onChange) {
                  onChange(e.target.value);
                }
                setActive(e.target.value);
              }}
              placeholder={placeholder}
              className={`block w-80 rounded-md ${
                isDisabled
                  ? 'border-gray-100 text-gray-100'
                  : isError
                  ? 'border-red-500'
                  : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500 '
              }  shadow-sm focus:ring-transparent`}
              disabled={isDisabled}
              onBlur={e => {
                register && register.onBlur(e);
                handleInputBlur(title);
              }}
            />
            {!showPassword && (
              <EyeIcon
                className="absolute bottom-[8px] right-[20px] hover:cursor-pointer"
                onClick={isDisabled ? () => null : () => togglePassword()}
              />
            )}
            {showPassword && (
              <ClosedEyeIcon
                className="absolute bottom-[8px] right-[20px] hover:cursor-pointer"
                onClick={isDisabled ? () => null : () => togglePassword()}
              />
            )}
          </div>
          {errorMessage && (
            <span className="flex h-auto w-[320px] flex-col items-start text-sm text-red-500">
              {errorMessage}
            </span>
          )}
        </div>
      );
    case 'number':
      return (
        <div className="flex flex-col ">
          <div className={`flex w-80 flex-col items-start text-sm`}>
            <span
              className={`font-medium ${
                isDisabled && 'text-gray-300'
              } ${fontSize} font-F37Bolton-Medium`}
            >
              {title}
              {isNecessary && (
                <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
              )}
            </span>
            <span
              className={`w-[335px] font-normal ${
                isDisabled ? 'text-gray-200' : 'text-gray-400'
              } mt-[2px]`}
            >
              {subtitle}
            </span>
          </div>
          <div
            className={`relative ${
              (subtitle === 'Total amount of wages paid to the employee this year' ||
                subtitle === 'Total amount of payments made to the contractor this year') &&
              'top-[20px]'
            }`}
          >
            <input
              {...register}
              type="number"
              defaultValue={defaultValue}
              placeholder={placeholder || '0'}
              onWheel={event => event.currentTarget.blur()}
              onChange={({ target }) => {
                if (target.value.length > 15) {
                  target.value = target.value.slice(0, 15);
                }

                if (onChange) {
                  if (!target.value) onChange(null);
                  else onChange(Number(target.value));
                }
                setActive(target.value);
              }}
              className={`rounded-md ${pl ? pl : 'pl-6'} ${
                isDisabled
                  ? 'border-gray-100 text-gray-100'
                  : isError
                  ? 'border-red-500'
                  : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
              }  mt-[8px] ${
                width ? width : 'w-80'
              } shadow-sm placeholder:text-gray-200 focus:ring-transparent`}
              disabled={isDisabled}
              onBlur={e => {
                register && register.onBlur(e);
                handleInputBlur(title);
              }}
            />
          </div>
          {errorMessage && (
            <span
              className={`${
                errorMessage === 'Total payments YTD is required'
                  ? 'relative top-[22px]'
                  : 'top-[12px]'
              } flex h-auto w-[335px] flex-col items-start text-sm text-red-500`}
            >
              {errorMessage}
            </span>
          )}
        </div>
      );
    case 'price':
      return (
        <div className="flex flex-col ">
          <div className={`w-80 ${subtitle ? 'h-11' : 'h-6'} flex flex-col items-start text-sm`}>
            <span
              className={`font-medium ${
                isDisabled && 'text-gray-300'
              } ${fontSize} font-F37Bolton-Medium`}
            >
              {title}
              {isNecessary && (
                <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
              )}
            </span>
            <span
              className={`w-[335px] font-normal ${
                isDisabled ? 'text-gray-200' : 'text-gray-400'
              } mt-[2px]`}
            >
              {subtitle}
            </span>
          </div>
          <div
            className={`relative ${
              (subtitle === 'Total amount of wages paid to the employee this year' ||
                subtitle === 'Total amount of payments made to the contractor this year') &&
              'top-[20px]'
            }`}
          >
            <span
              className={`absolute ${
                isLongTaxDescription ? 'top-[34px]' : 'top-[17px]'
              } left-[10px] text-base text-gray-500`}
            >
              $
            </span>
            <input
              {...register}
              type="number"
              placeholder="0.00"
              step={0.01}
              onWheel={event => event.currentTarget.blur()}
              min={0}
              defaultValue={defaultValue === '0.0' ? '' : defaultValue}
              onChange={e => {
                if (onChange) {
                  onChange(e.target.value);
                }
                setActive(e.target.value);
              }}
              className={`rounded-md pl-6 ${
                isDisabled
                  ? 'border-gray-100 text-gray-100'
                  : isError
                  ? 'border-red-500'
                  : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
              }  ${isLongTaxDescription ? 'mt-[25px]' : 'mt-[8px]'} ${
                width ? width : 'w-80'
              } shadow-sm placeholder:text-gray-200 focus:ring-transparent`}
              disabled={isDisabled}
              onBlur={e => {
                register && register.onBlur(e);
                handleInputBlur(title);
              }}
            />
          </div>
          {errorMessage && (
            <span
              className={`${
                errorMessage === 'Total payments YTD is required'
                  ? 'relative top-[22px]'
                  : isLongSubtitle
                  ? 'top-[22px]'
                  : 'top-[2px]'
              } flex h-auto w-[335px] flex-col items-start text-sm text-red-500`}
            >
              {errorMessage}
            </span>
          )}
        </div>
      );
    case 'percentage':
      return (
        <div className="flex flex-col ">
          <div className={`w-80 ${subtitle ? 'h-11' : 'h-6'} flex flex-col items-start text-sm`}>
            <span
              className={`font-medium ${isDisabled && 'text-gray-300'} ${
                fontSize && fontSize
              } font-F37Bolton-Medium`}
            >
              {title}
              {isNecessary && (
                <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
              )}
            </span>
            <span
              className={`w-[335px] font-normal ${
                isDisabled ? 'text-gray-200' : 'text-gray-400'
              } mt-[2px]`}
            >
              {subtitle}
            </span>
          </div>
          <div
            className={`relative ${
              (subtitle === 'Total amount of wages paid to the employee this year' ||
                subtitle === 'Total amount of payments made to the contractor this year') &&
              'top-[20px]'
            }`}
          >
            <span className="absolute left-[10px] top-[17px] text-base text-gray-500">%</span>
            <input
              {...register}
              type="number"
              defaultValue={defaultValue}
              placeholder="0"
              step={0.0000000001}
              min={0}
              onWheel={event => event.currentTarget.blur()}
              max={100}
              onChange={e => {
                if (onChange) {
                  onChange(e.target.value);
                }
                setActive(e.target.value);
              }}
              className={`rounded-md pl-8 ${
                isDisabled
                  ? 'border-gray-100 text-gray-100'
                  : isError
                  ? 'border-red-500'
                  : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
              }  mt-[8px] ${
                width ? width : 'w-80'
              } shadow-sm placeholder:text-gray-200 focus:ring-transparent`}
              disabled={isDisabled}
              onBlur={e => {
                register && register.onBlur(e);
                handleInputBlur(title);
              }}
            />
          </div>
          {errorMessage && (
            <span
              className={`${
                errorMessage === 'Total payments YTD is required'
                  ? 'relative top-[22px]'
                  : 'top-[12px]'
              } flex h-auto w-[335px] flex-col items-start text-sm text-red-500`}
            >
              {errorMessage}
            </span>
          )}
        </div>
      );
    case 'phoneNumber':
      return (
        <div className="flex w-full flex-col">
          {!noLabel && (
            <div
              className={`w-full ${subtitle ? 'h-11' : 'h-6'} flex flex-col items-start text-sm`}
            >
              <span
                className={`font-medium ${isDisabled && 'text-gray-300'} ${fontSize && fontSize}`}
              >
                <span className={font}>
                  {title} {!isNecessary && <span className="text-gray-300">(optional)</span>}
                </span>
                {isNecessary && (
                  <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
                )}
              </span>
              <span
                className={`font-normal ${isDisabled ? 'text-gray-200' : 'text-gray-400'} mt-[2px]`}
              >
                {subtitle}
              </span>
            </div>
          )}
          <InputMask
            mask="(999) 999-9999"
            maskChar="_"
            {...register}
            type="text"
            placeholder="( _ _ _ ) _ _ _ - _ _ _ _"
            value={active ? active : null}
            onBlur={e => {
              register && register.onBlur(e);
              handleInputBlur(title);
            }}
            onChange={e => {
              if (onChange) {
                // const unmask = e.target.value.replace(/[^\d]/g, '');
                if (e.target.value !== '') {
                  onChange(e.target.value);
                  setActive(e.target.value);
                } else {
                  onChange(null);
                }
              }
              setActive(e.target.value);
            }}
            className={`block ${width ? width : 'w-[335px]'} rounded-md ${
              isDisabled
                ? 'border-gray-100 text-gray-100'
                : isError
                ? 'border-red-500'
                : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
            }  mt-[8px] shadow-sm focus:ring-transparent ${
              isLongSubtitle ? 'relative top-[22px] lg:top-0' : 'relative top-[2px] lg:top-0'
            }`}
            disabled={isDisabled}
          />
          {errorMessage && (
            <span className="flex h-auto flex-col items-start text-sm text-red-500">
              {errorMessage}
            </span>
          )}
        </div>
      );
    case 'ein':
      return (
        <div className="">
          <div className={`w-full ${subtitle ? 'h-11' : 'h-6'} flex flex-col items-start text-sm`}>
            <span className={`font-medium ${isDisabled && 'text-gray-300'}`}>
              {title}
              {isNecessary && (
                <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
              )}
            </span>
            <span className={`font-normal ${isDisabled ? 'text-gray-200' : 'text-gray-400'}`}>
              {subtitle}
            </span>
          </div>
          <InputMask
            mask="99 - 9999999"
            maskChar=""
            {...register}
            type="text"
            placeholder="_ _ - _ _ _ _ _ _ _"
            value={active ? active : ''}
            onBlur={e => {
              register && register.onBlur(e);
              handleInputBlur(title);
            }}
            onChange={e => {
              setActive(e.target.value);
            }}
            className={`block h-[44px] w-[335px] rounded-md ${
              isDisabled
                ? 'border-gray-100 text-gray-100'
                : errorMessage
                ? 'border-red-500'
                : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
            }  shadow-sm focus:ring-transparent`}
            disabled={isDisabled}
          />
          {errorMessage && (
            <span className="flex h-auto w-[335px] flex-col items-start text-sm text-red-500">
              {errorMessage}
            </span>
          )}
        </div>
      );
    case 'email':
      return (
        <div>
          {!noLabel && (
            <div
              className={`w-full ${subtitle ? 'h-11' : 'h-6'} flex flex-col items-start text-sm`}
            >
              <span
                className={`font-F37Bolton-Medium ${fontSize && fontSize} ${
                  isDisabled && 'text-gray-300'
                } ${font}`}
              >
                {title}
                {isNecessary && (
                  <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
                )}
                {isOptional && <span className="text-gray-300"> (optional)</span>}
              </span>
              <span
                className={`text-sm font-normal ${isDisabled ? 'text-gray-200' : 'text-gray-400'}`}
              >
                {subtitle}
              </span>
            </div>
          )}
          <input
            {...register}
            {...field}
            type="email"
            placeholder={placeholder}
            // value={active ? active : ''}
            onBlur={e => {
              register && register.onBlur(e);
              handleInputBlur(title);
            }}
            onChange={e => {
              onChange && onChange(e);
              setActive(e.target.value);
            }}
            className={`block h-[44px] ${width ? width : 'w-[335px]'} rounded-md ${
              isDisabled
                ? 'border-gray-100 text-gray-100'
                : errorMessage
                ? 'border-red-500 focus:border-red-500'
                : 'border-gray-300 text-gray-900 hover:border-gray-400 focus:border-gray-500'
            }  shadow-sm focus:ring-transparent ${
              isLongSubtitle ? 'relative top-[22px] lg:top-0' : 'relative top-[2px] lg:top-0'
            }`}
            disabled={isDisabled}
            defaultValue={defaultValue}
          />
          {errorMessage && (
            <span className="mt-1 flex h-auto w-full flex-col items-start text-sm text-red-500">
              {errorMessage}
            </span>
          )}
        </div>
      );
    default:
      return null;
  }
};

const Input = WithGTMInput(InputWithoutGTM);
export default Input;
