// import url from 'utils/url';
import axios from '../axios';

export interface businessProfile {
  businessType: number;
  name: string;
  numberOfEmployees: number;
  numberOfContractors: number;
  chooseEinOrSsn: number;
  ein: string;
  ssn: string;
}

export const getTeamMembers = (companyId: string) => {
  return axios.get(`/company/${companyId}/team-members`);
};
