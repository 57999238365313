import Button from '@/components/Button/Button';
import { DateTime } from 'luxon';
import { NumericFormat } from 'react-number-format';
import { BillingHistoryCardProps } from '../../type';

const BillingHistoryCard = ({ data, handleAction }: BillingHistoryCardProps) => {
  const payDate = DateTime.fromISO(data?.dueDate, {
    zone: 'utc',
  }).toFormat('MMM d, y');

  return (
    <div className="flex flex-col gap-4 sm:hidden">
      <div className="rounded-xl border">
        <div className="p-4">
          <div className="flex justify-between  ">
            <span className="font-F37Bolton-Medium capitalize">{data.plan}</span>
            <NumericFormat
              value={data?.amount}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
              renderText={value => <span className=" font-F37Bolton-Medium ">${value}</span>}
            />
          </div>
          <span className="py-1">{payDate}</span>
        </div>

        <div className="border-t p-4">
          <Button variant="light" parentClasses="w-full" onPress={handleAction}>
            {data?.fsInvoiceUrl ? 'View receipt' : 'Download receipt'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BillingHistoryCard;
