import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { getCall } from '@/api/axios';
import { Account } from '@/redux/dto/auth';

import { queryEndpoints } from './endpoints';

type AccountResponse = AxiosResponse<Account>;

type UseGetAccountTypes = {
  rqParams?: Omit<UseQueryOptions<AccountResponse, Error, AccountResponse>, 'queryKey'>;
};

const useGetAccountInfo = ({ rqParams }: UseGetAccountTypes) => {
  const { queryKey, url } = queryEndpoints.getAccountInfo();

  return useQuery<AccountResponse, Error>({
    queryKey,
    queryFn: () => getCall(url),
    ...rqParams,
  });
};
export default useGetAccountInfo;
