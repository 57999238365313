import { BriefcaseIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { lazy, useCallback, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as BanknotesIcon } from '@/assets/banknotesWhite.svg';
import { ActionModal, Button, LoaderComponent } from '@/components';
import { Dispatch, RootState } from '@/redux/store';
import { PAYROLL_HISTORY_CURRENT_CONTRACTOR } from '@/routes/routes';
import { downloadPayStub, isArrayHasData } from '@/utils/helpers';
import { MembersListInitials } from './components';

const SharePayrollModal = lazy(() => import('@/components/shared/SharePayroll/SharePayroll'));

const DetailsPayroll = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadingPayments, setIsDownloadingPayments] = useState(false);
  const [isDeletePayrollModalOpen, setIsDeletePayrollModalOpen] = useState(false);
  const [isSharePayrollModalOpen, setIsSharePayrollModalOpen] = useState(false);
  const [paymentMemberNames, setPaymentMemberNames] = useState<Array<Record<string, string>>>([]);
  const [contractors, setContractors] = useState([]);

  const companyId = useSelector((state: RootState) => state.auth.user.companyId);
  const paymentDetails =
    useSelector((state: RootState) => state.contractor?.currentPaymentHistoryContractor) || {};
  const { payments = [] } = paymentDetails || {};

  useEffect(() => {
    if (isArrayHasData(payments)) {
      const formattedPaymentMembers: Array<Record<string, string>> = [];
      const formattedContractors = [];
      payments.forEach(({ contractor, id }, index) => {
        formattedPaymentMembers.push({
          id: index,
          firstName: contractor.firstName,
          lastName: contractor.lastName,
          contractorType: contractor.contractorType,
          businessName: contractor.businessName,
        });

        formattedContractors.push({
          ...contractor,
          paymentItemId: id,
        });
      });
      setPaymentMemberNames(formattedPaymentMembers);
      setContractors(formattedContractors);
    }
  }, [payments]);

  const getInformationPaymentDetails = async () => {
    setIsLoading(true);
    await dispatch.contractor.getDetailsPaymentHistoryById({
      companyId,
      paymentHistoryId: id,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getInformationPaymentDetails();
  }, [companyId, id]);

  const handleDownloadAllPayments = async () => {
    setIsDownloadingPayments(true);
    const response = await dispatch.contractor.getContractorPayStubsDownload({
      companyId,
      id,
    });
    if (response) {
      downloadPayStub(response, id);
    }
    setIsDownloadingPayments(false);
  };

  const onDeletePayroll = async () => {
    await dispatch.contractor.deletePayroll({
      companyId,
      id,
    });
    navigate(-1);
  };

  const handleToggleSharePayrollModal = useCallback(() => {
    setIsSharePayrollModalOpen(prev => !prev);
  }, [setIsSharePayrollModalOpen]);

  const handleViewPayment = (contractorId: string, paymentId: string) => () =>
    navigate(
      PAYROLL_HISTORY_CURRENT_CONTRACTOR.replace(':paymentId', paymentId).replace(
        ':contractorId',
        contractorId,
      ),
    );

  if (isLoading) return <LoaderComponent />;

  return (
    <div className="flex flex-col">
      <div className="bg-white p-5 lg:p-0 lg:px-14 lg:py-5">
        <div className="flex flex-col gap-y-3 lg:w-[600px]">
          <Button type="back" onClick={() => navigate(-1)} />
          <div className="flex w-fit items-center gap-x-1 rounded bg-darkblue-500 px-2 py-1 text-white">
            <BanknotesIcon />
            Contractors Payment
          </div>

          <span>Pay date: {paymentDetails?.paymentDate}</span>

          <MembersListInitials list={paymentMemberNames} />

          <div className="flex justify-between border-t pt-4">
            <span className="font-F37Bolton-Medium">Total Amount</span>
            <NumericFormat
              value={paymentDetails?.totalAmount}
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
              renderText={value => <span className="font-F37Bolton-Medium text-xl">${value}</span>}
            />
          </div>
        </div>
      </div>

      <div className="p-5 lg:ms-14 lg:mt-6 lg:w-[600px] lg:p-0">
        <div className="mb-4 flex flex-col gap-y-4 lg:flex-row lg:gap-x-3">
          <Button
            type={isDownloadingPayments ? 'loading' : 'primary'}
            bgColor="bg-darkblue-500"
            width="w-full lg:w-50% lg:order-1"
            onClick={handleDownloadAllPayments}
            children="Download all payments"
          />

          <button
            className="lg:w-50% flex w-full items-center justify-center gap-x-2 rounded-full border border-gray-200 bg-white px-5 py-3 text-blue-500"
            onClick={handleToggleSharePayrollModal}
          >
            <EnvelopeIcon className="w-5" />
            <span>Share with contractors</span>
          </button>
        </div>

        <div className="flex flex-col gap-y-4">
          {isArrayHasData(payments) &&
            payments.map(payment => (
              <div
                key={payment?.id}
                className="rounded-2xl border border-gray-300 bg-white shadow-secondary"
              >
                {payment.status === 2 && (
                  <div className="rounded-t-2xl bg-red-100 py-1 text-center text-red-500">
                    Canceled
                  </div>
                )}

                <div className="flex flex-col gap-y-3 p-5">
                  <div className="flex justify-between">
                    <span className="rounded bg-gray-100 px-3 py-1 text-sm text-gray-400">
                      Payment
                    </span>
                    <span>Pay date: {paymentDetails?.paymentDate}</span>
                  </div>

                  <div className="flex items-center gap-x-2">
                    <BriefcaseIcon className="inline-block h-9 w-9 rounded-full border-[6px] border-yellow-400 bg-yellow-400 text-black" />
                    {payment?.contractor.firstName} {payment?.contractor.lastName}
                  </div>

                  <div className="flex justify-between border-t pt-3">
                    <NumericFormat
                      value={payment.totalAmount}
                      thousandsGroupStyle="thousand"
                      thousandSeparator=","
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      renderText={value => (
                        <span className="font-F37Bolton-Medium text-lg">${value}</span>
                      )}
                    />

                    <Button
                      type="link"
                      width="w-fit"
                      onClick={handleViewPayment(payment?.contractor.id, payment?.id)}
                    >
                      View Payment
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {paymentDetails?.canBeDeleted && (
          <div className="mb-10 mt-8 flex flex-col gap-y-1">
            <Button
              type="link"
              isCritical
              width="w-fit"
              onClick={() => setIsDeletePayrollModalOpen(isOpen => !isOpen)}
              children="Delete payroll"
            />
            <p>
              If you believe there's a mistake in your most recent payroll run, you can delete it
              and run it again.
            </p>
          </div>
        )}

        {isDeletePayrollModalOpen && (
          <ActionModal
            isOpen={isDeletePayrollModalOpen}
            actionButtonTitle="Delete"
            title="Delete payroll"
            linkButtonTitle="Cancel"
            description="If you believe there's a mistake in this payroll run, you can delete it and run it again from the Contractor Pay Stubs page"
            iconType="XMarkIcon"
            onAction={onDeletePayroll}
            isCritical={false}
            bgColor="bg-[#DA3939]"
            isNewModal
            toggleModal={isOpen => {
              setIsDeletePayrollModalOpen(isOpen);
            }}
          />
        )}

        {isSharePayrollModalOpen && (
          <SharePayrollModal
            isOpen={isSharePayrollModalOpen}
            toggleModal={handleToggleSharePayrollModal}
            members={contractors}
            payrollDate={new Date(paymentDetails?.paymentDate).toISOString()}
            toContractors
          />
        )}
      </div>
    </div>
  );
};

export default DetailsPayroll;
