import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-tailwindcss-datepicker';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CalendarIcon } from '@/assets/calendar.svg';
import { WithGTMInput } from '@/components/HOCs/Analytics';

export interface DateResponse {
  startDate: Date | null;
  endDate: Date | null;
}

interface InputDatePickerProps {
  label: string;
  value: DateResponse | undefined;
  errorMessage?: string;
  onChange: (date: DateResponse) => void;
  register?: any;
  field?: string;
  subtitle?: string;
  isNecessary?: boolean;
  isSingle?: boolean;
  isDisabled?: boolean;
  width?: string;
  maxDate?: Date;
  isOptional?: boolean;
  disabledDates?: any;
  popoverDirection?: 'up' | 'down';
  type: string;
  placeholder?: string;
  className?: string;
  datePickerInputClasses?: string;
  labelClassName?: string;
}

const formatDate = (dateString: string) =>
  dateString && moment(dateString).isValid() ? moment(dateString).toISOString() : null;

const InputDatePickerWithoutGTM: React.FC<InputDatePickerProps> = ({
  label,
  value,
  onChange,
  field,
  errorMessage,
  isSingle = true,
  disabledDates,
  register,
  subtitle,
  isNecessary,
  isDisabled,
  width,
  maxDate,
  isOptional,
  popoverDirection,
  placeholder,
  type = '',
  className,
  datePickerInputClasses = '',
  labelClassName,
}) => {
  if (type === 'year') {
    const currentYear = new Date().getFullYear();
    // TODO: have to make Years list dynamic based on Payroll-schedule
    const firstYear = 2023;
    const yearDiff = currentYear - firstYear + 1;

    const years: number[] = [...Array.from({ length: yearDiff }, (_, index) => firstYear + index)];

    return (
      <div className={twMerge('relative w-full', className)}>
        <label
          htmlFor="year-picker"
          className="mb-[2px] flex flex-col items-start font-F37Bolton-Medium text-gray-900"
        >
          <span className="font-F37Bolton-Medium">
            {label}
            {isNecessary && (
              <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
            )}
            {isOptional && <span className="text-gray-300"> (optional)</span>}
          </span>
        </label>
        {subtitle && <span className="mb-2 block text-sm text-gray-400">{subtitle}</span>}

        <div className={`${width ? width : 'w-full'} relative mt-[8px]`}>
          <select
            id="year-picker"
            onChange={e => onChange({ startDate: new Date(e.target.value), endDate: null })}
            value={value?.startDate?.getFullYear()}
            className="w-full rounded-[8px] border-gray-300 pl-[15px] text-base text-gray-900 transition-none focus:border-[#999999] focus:outline-none focus:ring-0 focus:ring-offset-0"
          >
            <option value="" disabled>
              Select a Year
            </option>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        {errorMessage && (
          <span className="flex h-auto w-full flex-col items-start text-sm text-red-500">
            {errorMessage}
          </span>
        )}
      </div>
    );
  }
  // Render the existing date picker
  return (
    <div className={twMerge('relative w-full', className)}>
      <label
        htmlFor="date-picker"
        className={twMerge(
          'mb-[2px] flex flex-col items-start font-F37Bolton-Medium text-gray-900',
          labelClassName,
        )}
      >
        <span className="font-F37Bolton-Medium">
          {label}
          {isNecessary && (
            <span className={`${isDisabled ? 'text-gray-300' : 'text-red-500'}`}> *</span>
          )}
          {isOptional && <span className="text-gray-300"> (optional)</span>}
        </span>
      </label>
      {subtitle && <span className="mb-2 block text-sm text-gray-400">{subtitle}</span>}

      <div className={`${width ? width : 'w-full'} relative `}>
        <DatePicker
          startWeekOn="mon"
          field={field}
          asSingle={isSingle}
          useRange={false}
          toggleIcon={e => {
            if (e) {
              return (
                <ChevronDownIcon className={`ml-2 mr-1 size-5 text-gray-400`} aria-hidden="true" />
              );
            } else {
              return null;
            }
          }}
          displayFormat="MM/DD/YYYY"
          primaryColor={'emerald'}
          showShortcuts={false}
          {...register}
          id="date-picker"
          value={value}
          onChange={({ startDate, endDate }) => {
            onChange({
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
            });
          }}
          disabledDates={disabledDates}
          readOnly={true}
          placeholder={placeholder ? placeholder : isSingle ? 'XX/XX/XX' : 'XX/XX/XX - XX/XX/XX'}
          inputClassName={twMerge(
            'focus:outline-none datePickerClass focus:ring-0 focus:ring-offset-0 focus:border-[#999999] transition-none border-gray-300 pl-[53px] w-full rounded-[8px] text-base text-gray-900',
            datePickerInputClasses,
          )}
          separator="-"
          maxDate={maxDate}
          name="input_date"
          popoverDirection={popoverDirection}
        />
        <CalendarIcon className="pointer-events-none absolute left-[15px] top-[8px]" />
        <div className="absolute left-[45px] top-[11px] h-[20px] w-px bg-gray-300"></div>
        {isSingle
          ? value &&
            value?.startDate && (
              <XMarkIcon
                type="button"
                onClick={() => onChange({ startDate: null, endDate: null })}
                className={`absolute bottom-[12px] right-3 ml-2 mr-1 size-5 text-gray-400 hover:cursor-pointer`}
                aria-hidden="true"
              />
            )
          : value &&
            value?.startDate &&
            value?.endDate && (
              <XMarkIcon
                type="button"
                onClick={() => onChange({ startDate: null, endDate: null })}
                className={`absolute bottom-[12px] right-3 ml-2 mr-1 size-5 text-gray-400 hover:cursor-pointer`}
                aria-hidden="true"
              />
            )}
      </div>

      {errorMessage && (
        <span className="flex h-auto w-full flex-col items-start text-sm text-red-500">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

const InputDatePicker = WithGTMInput(InputDatePickerWithoutGTM);
export default InputDatePicker;
