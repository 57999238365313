import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { patchCall } from '@/api/axios';
import useQueryHandlers from '@/hooks/useQueryHandlers/useQueryHandlers';
import { Company } from '@/redux/dto/company';

import { mutationEndpoints } from './endpoints';

type CompanyResponse = AxiosResponse<Company>;
type PartialCompanyType = Partial<Company>;

const useUpdateCompany = ({
  companyId,
  mutationParams,
}: {
  companyId: number;
  mutationParams?: UseMutationOptions<CompanyResponse, Error, PartialCompanyType>;
}) => {
  const { mutationKey, url, invalidateKeys } = mutationEndpoints.updateCompany(companyId);

  const { onSuccess } = useQueryHandlers({
    invalidateKeys,
    mutationParams,
  });

  return useMutation<CompanyResponse, Error, PartialCompanyType>({
    mutationKey,
    mutationFn: data => patchCall(url, data),
    ...mutationParams,
    onSuccess,
  });
};

export default useUpdateCompany;
