import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import { Button } from '@/components';
import { formatDate, groupOptionByYear, groupOptionByYearForMonthly } from '@/utils/dateConversion';
import { getObjectValues } from '@/utils/getObjectKey';

import { PayrollScheduleFormProps } from './types';
import usePayrollScheduleForm from './usePayrollScheduleForm';

const pattern = /[^a-zA-Z0-9\s]/g;

const PayrollScheduleForm = forwardRef(
  (
    {
      submitHandler,
      schedule,
      isLoadingPreviewData,
      hideSubmitButton,
      handleSaveButtonValidation,
    }: PayrollScheduleFormProps,
    ref,
  ) => {
    const {
      handlePayFieldChange,
      watch,
      control,
      handleSubmit,
      OverWriteOption,
      options,
      handleSemiMonthlyFieldChange,
      handlePayDayFieldChange,
      data,
      difference,
      onSubmit,
      shouldShowBackdatedData,
    } = usePayrollScheduleForm({ submitHandler, schedule, ref, handleSaveButtonValidation });

    const {
      payFrequency,
      payDay,
      payDate,
      payPeriod,
      semiMonthly,
      backDated,
      firstPayDate,
      secondPayDate,
      backDatedPayPeriod,
      firstPayPeriod,
    } = watch();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* PAY FREQUENCY */}
        <div className="flex w-full flex-col justify-center">
          <Controller
            name="payFrequency"
            control={control}
            render={({ field }) => (
              <>
                <span className="mb-2 font-F37Bolton-Medium">
                  How often do you pay your employees? <span className="text-red-500">*</span>
                </span>
                <select
                  {...field}
                  onChange={e => {
                    field.onChange(e);
                    handlePayFieldChange([
                      'payDay',
                      'payDate',
                      'payPeriod',
                      'semiMonthly',
                      'firstPayDate',
                      'secondPayDate',
                      'backDated',
                      'backDatedPayPeriod',
                      'firstPayPeriod',
                    ]);
                  }}
                  className="appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                  required
                >
                  <option value="" disabled selected>
                    -Select frequency-
                  </option>

                  {options.map(ele => (
                    <option
                      className="text-black"
                      key={ele}
                      value={ele.toLocaleLowerCase().replace(pattern, '')}
                    >
                      {ele}
                    </option>
                  ))}
                </select>
              </>
            )}
          />
        </div>
        {/* PAY DAY */}
        {payFrequency &&
          (payFrequency === 'semimonthly' ? (
            <div className="mt-5 flex flex-col justify-center lg:w-full">
              <label className="mb-[16px] flex flex-col" htmlFor="chooseEinOrSsn">
                <span className="font-F37Bolton-Medium text-gray-900">
                  Monthly pay days <span className="text-red-500">*</span>
                </span>
              </label>
              <div className="mb-[16px]">
                <label className="flex items-center">
                  <Controller
                    name="semiMonthly"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        onChange={e => handleSemiMonthlyFieldChange(e, field)}
                        className="mr-2 size-[24px] focus:ring-0 focus:ring-offset-0"
                        type="radio"
                        value={15}
                        checked={field.value == 15}
                      />
                    )}
                  />
                  15th and last day of the month
                </label>
              </div>
              <div>
                <label className="flex items-center">
                  <Controller
                    name="semiMonthly"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        onChange={e => handleSemiMonthlyFieldChange(e, field)}
                        className="mr-2 size-[24px] focus:ring-0 focus:ring-offset-0"
                        type="radio"
                        value={1}
                        checked={field.value == 1}
                      />
                    )}
                  />
                  Other
                </label>
              </div>
            </div>
          ) : (
            <div className="mt-5 flex w-full flex-col justify-center">
              <Controller
                name="payDay"
                control={control}
                render={({ field }) => (
                  <>
                    <span className="mb-2 font-F37Bolton-Medium">
                      {data.label} <span className="text-red-500">*</span>
                    </span>
                    <select
                      key={payFrequency}
                      {...field}
                      onChange={e => {
                        field.onChange(e);
                        handlePayFieldChange([
                          'payDate',
                          'payPeriod',
                          'semiMonthly',
                          'backDatedPayPeriod',
                          'backDated',
                          'firstPayPeriod',
                        ]);
                      }}
                      className="custom-select appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                      required
                    >
                      <option value="" disabled selected>
                        -Select a day-
                      </option>
                      {data.options?.map(ele => (
                        <option className="text-black" key={ele} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              />
            </div>
          ))}

        {semiMonthly == 1 && (
          <>
            <div className="mt-5 flex w-full flex-col justify-center">
              <Controller
                name="firstPayDate"
                control={control}
                render={({ field }) => (
                  <>
                    <span className="mb-2 font-F37Bolton-Medium">
                      {data.firstPayDate.label} <span className="text-red-500">*</span>
                    </span>
                    <select
                      {...field}
                      onChange={e => handlePayDayFieldChange(e, field)}
                      className="appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                      required
                    >
                      <option value="" disabled selected>
                        -Select a first pay day-
                      </option>
                      {data.firstPayDate.options.map(
                        ele =>
                          ele <= 17 && (
                            <option className="text-black" key={ele} value={ele}>
                              {ele}
                            </option>
                          ),
                      )}
                    </select>
                  </>
                )}
              />
            </div>
            <div className="mt-5 flex w-full flex-col justify-center">
              <Controller
                name="secondPayDate"
                control={control}
                render={({ field }) => (
                  <>
                    <span className="mb-2 font-F37Bolton-Medium">
                      {data.secondPayDate.label} <span className="text-red-500">*</span>
                    </span>
                    <select
                      {...field}
                      onChange={e => handlePayDayFieldChange(e, field)}
                      className="appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                      required
                    >
                      <option value="" disabled selected>
                        -Select a second pay day-
                      </option>
                      {data.secondPayDate.options.slice(13).map(ele => (
                        <option className="text-black" key={ele} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              />
            </div>
            {difference && (
              <div className="flex h-auto w-full flex-col items-start text-sm text-red-500">
                {difference}
              </div>
            )}
          </>
        )}

        {/* PAY DATE */}
        {(payDay ||
          semiMonthly == 15 ||
          (semiMonthly == 1 && firstPayDate && secondPayDate && !difference)) && (
          <div className="mt-5 flex w-full flex-col justify-center">
            <Controller
              name="payDate"
              control={control}
              render={({ field }) => (
                <>
                  <span className="mb-2 font-F37Bolton-Medium">
                    {data.payDateOption.label} <span className="text-red-500">*</span>
                  </span>
                  <select
                    key={payDay}
                    {...field}
                    onChange={e => {
                      field.onChange(e);
                      handlePayFieldChange(['payPeriod', 'backDated', 'backDatedPayPeriod']);
                    }}
                    className="appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                    required
                  >
                    <option value="" disabled selected>
                      -Select a pay date-
                    </option>
                    {data.payDateOption.options.map(ele => (
                      <option className="text-black" key={ele} value={ele}>
                        {formatDate(ele, 'ddd, MMM DD, YYYY')}
                      </option>
                    ))}
                  </select>
                </>
              )}
            />
          </div>
        )}
        {/* PAY PERIOD */}
        {payDate &&
          (payFrequency === 'quarterly' ? (
            <div className="mt-5 flex w-full flex-col justify-center">
              <Controller
                name="payPeriod"
                control={control}
                render={({ field }) => (
                  <>
                    <span className="mb-2 font-F37Bolton-Medium">
                      {data.payPeriodOption.label} <span className="text-red-500">*</span>
                    </span>
                    <select
                      key={payDate}
                      {...field}
                      onChange={e => {
                        field.onChange(e);
                        handlePayFieldChange(['firstPayPeriod', 'backDated', 'backDatedPayPeriod']);
                      }}
                      className="rounded-lg border-gray-300 disabled:bg-gray-50"
                      disabled
                    >
                      {data.payPeriodOption.options?.map(ele => (
                        <option key={ele} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              />
            </div>
          ) : (
            <div className="mt-5 flex w-full flex-col justify-center">
              <Controller
                name="payPeriod"
                control={control}
                render={({ field }) => (
                  <>
                    <span className="mb-2 font-F37Bolton-Medium">
                      {data.payPeriodOption.label} <span className="text-red-500">*</span>
                    </span>
                    <select
                      key={payDate}
                      {...field}
                      onChange={e => {
                        field.onChange(e);
                        handlePayFieldChange(['firstPayPeriod', 'backDated', 'backDatedPayPeriod']);
                      }}
                      className="appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                      required
                    >
                      <option value="" disabled selected>
                        -Select a pay period-
                      </option>
                      {getObjectValues(data.payPeriodOption.options)?.map(ele => (
                        <option className="text-black" key={ele} value={ele}>
                          {ele}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              />
            </div>
          ))}

        {payPeriod === OverWriteOption.other && (
          <div className="mt-5 flex w-full flex-col justify-center">
            <Controller
              name="firstPayPeriod"
              control={control}
              render={({ field }) => (
                <>
                  <span className="mb-2 font-F37Bolton-Medium">
                    {data.firstPayPeriod.label} <span className="text-red-500">*</span>
                  </span>
                  <select
                    {...field}
                    onChange={e => {
                      field.onChange(e);
                      handlePayFieldChange(['backDated', 'backDatedPayPeriod']);
                    }}
                    className="appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                    required
                  >
                    <option value="" disabled selected>
                      -Select a pay period
                    </option>
                    {data.firstPayPeriod.options.map(ele => (
                      <option className="text-black" key={ele} value={ele}>
                        {formatDate(ele, 'ddd, MMM DD, YYYY')}
                      </option>
                    ))}
                  </select>
                </>
              )}
            />
          </div>
        )}

        {shouldShowBackdatedData &&
          ((payPeriod && payPeriod !== OverWriteOption.other) ||
            firstPayPeriod ||
            (payDate && payFrequency === 'quarterly')) && (
            <div className="mt-5 flex flex-col justify-center lg:w-full">
              <label className="mb-[16px] flex flex-col" htmlFor="chooseEinOrSsn">
                <span className="font-F37Bolton-Medium text-gray-900">
                  Do you want to record back-dated payroll? <span className="text-red-500">*</span>
                </span>
                <span className={`text-sm text-gray-400`}>
                  To record payroll previous to the upcoming one
                </span>
              </label>
              <div className="flex gap-8">
                <div className="mb-[16px]">
                  <label className="flex items-center">
                    <Controller
                      name="backDated"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          onChange={e => {
                            field.onChange(e);
                            handlePayFieldChange(['backDatedPayPeriod']);
                          }}
                          className="mr-2 size-[24px] focus:ring-0 focus:ring-offset-0"
                          type="radio"
                          value="yes"
                          checked={field.value === 'yes'}
                        />
                      )}
                    />
                    Yes
                  </label>
                </div>
                <div>
                  <label className="flex items-center">
                    <Controller
                      name="backDated"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          onChange={e => {
                            field.onChange(e);
                            handlePayFieldChange(['backDatedPayPeriod']);
                          }}
                          className="mr-2 size-[24px] focus:ring-0 focus:ring-offset-0"
                          type="radio"
                          value="no"
                          checked={field.value === 'no'}
                        />
                      )}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
          )}

        {shouldShowBackdatedData &&
          backDated === 'yes' &&
          (payFrequency === 'monthly' ? (
            <div className="mt-5 flex w-full flex-col justify-center">
              <Controller
                name="backDatedPayPeriod"
                control={control}
                render={({ field }) => (
                  <>
                    <span className="mb-2 font-F37Bolton-Medium">
                      {data?.backDatedPayPeriod?.label} <span className="text-red-500">*</span>
                    </span>

                    <select
                      {...field}
                      className="appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                      required
                    >
                      <option value="" disabled selected>
                        -Select a back-dated pay period-
                      </option>
                      {groupOptionByYearForMonthly(data?.backDatedPayPeriod?.options).map(
                        ([year, options]) => (
                          <optgroup label={`------------ ${year} ------------`} key={year}>
                            {options.map(ele => (
                              <option
                                key={Object.keys(ele)[0]}
                                value={Object.keys(ele)[0]}
                                className="text-black"
                              >
                                {Object.values(ele)[0]}
                              </option>
                            ))}
                          </optgroup>
                        ),
                      )}
                    </select>
                  </>
                )}
              />
            </div>
          ) : (
            <div className="mt-5 flex w-full flex-col justify-center">
              <Controller
                name="backDatedPayPeriod"
                control={control}
                render={({ field }) => (
                  <>
                    <span className="mb-2 font-F37Bolton-Medium">
                      {data?.backDatedPayPeriod?.label} <span className="text-red-500">*</span>
                    </span>
                    <select
                      {...field}
                      className="appearance-none rounded-lg border-gray-300 invalid:text-gray-300"
                      required
                    >
                      <option value="" disabled selected>
                        -Select a back-dated pay period-
                      </option>

                      {groupOptionByYear(data?.backDatedPayPeriod?.options).map(
                        ([year, options]) => (
                          <optgroup label={`------------ ${year} ------------`} key={year}>
                            {options.map(ele => (
                              <option key={ele} value={ele} className="text-black">
                                {ele}
                              </option>
                            ))}
                          </optgroup>
                        ),
                      )}
                    </select>
                  </>
                )}
              />
            </div>
          ))}

        {!hideSubmitButton && (
          <div className="mt-5">
            <Button
              isDisabled={
                shouldShowBackdatedData ? backDated !== 'no' && !backDatedPayPeriod : !payPeriod
              }
              type="primary"
              className="w-full"
              isLoading={isLoadingPreviewData}
            >
              Preview schedule
            </Button>
          </div>
        )}
      </form>
    );
  },
);

PayrollScheduleForm.displayName = 'PayrollScheduleForm';

export default PayrollScheduleForm;
