import { useAuth0 } from '@auth0/auth0-react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';
import { Flip, ToastContainer } from 'react-toastify';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Button } from '@/components';

import { CurrentStepContext, CurrentStepProvider } from './components/HOCs/Analytics';
import { store } from './redux/store';
import AppRoute from './routes';
import { callToast } from './utils/helpers';
import { sec } from './utils/security';

const cacheBuster = __BUILD_DATE__;
export { CurrentStepContext };

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
    mutations: {
      onError: error => {
        const errorData = error?.response?.data;

        const errorTitle = errorData?.['hydra:title'];
        const errorDescription = errorData?.['hydra:description'];

        const errorMessage = `${errorTitle}: ${errorDescription}`;

        callToast('error', `${errorMessage}`);
      },
    },
  },
});

function App() {
  const { getAccessTokenSilently } = useAuth0();
  sec.setAccessTokenSilently(getAccessTokenSilently);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;

  const growthbook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: import.meta.env.APP_GROWTHBOOK_CLIENT_KEY,
    enableDevMode: true,
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
      // TODO: Use your real analytics tracking system
      // console.log(`Viewed Experiment`, {
      //   experimentId: experiment.key,
      //   variationId: result.key
      // });
    },
  });

  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  useEffect(() => {
    i18n
      .use(HttpBackend)
      .use(initReactI18next)
      .init({
        lng: 'en',
        fallbackLng: 'en',
        missingKeyHandler: (lng, ns, key, fallbackValue) => {
          // Custom logic when a key is missing
          return fallbackValue;
        },
        ns: ['common'],
        defaultNS: 'common',
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + cacheBuster, // Path to your translation files
        },
      });
  }, []);

  return (
    <Provider store={store}>
      <GrowthBookProvider growthbook={growthbook}>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <CurrentStepProvider>
            <QueryClientProvider client={queryClient}>
              <AppRoute />
              <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
            </QueryClientProvider>
          </CurrentStepProvider>
        </QueryParamProvider>
        <ToastContainer
          className="box-border max-w-[316px] sm:w-96"
          closeButton={<Button type="closeIcon" />}
          transition={Flip}
          position="top-center"
          limit={3}
          autoClose={2500}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          theme="colored"
        />
      </GrowthBookProvider>
    </Provider>
  );
}

export default App;
