import Button from '@/components/Button/Button';
import RadioButtonVerticalGroup from '@/components/RadioButtonGroup/RadioButtonVerticalGroup';
import { useReasonForm } from '../../hooks/useReasonForm';
import { ReasonFormType } from '../../types';

export const ReasonForm = ({ nextTab }: ReasonFormType) => {
  const { reason, setReason, otherReason, setOtherReason } = useReasonForm();

  return (
    <div className="mt-[32px]">
      <span className="block font-F37Bolton-Medium text-[20px]">Why are you leaving?</span>
      <RadioButtonVerticalGroup
        options={[
          { label: `I couldn't find some of the features that I need`, value: '1' },
          {
            label: `It's too difficult to use / I ran into an issue that I couldn’t resolve`,
            value: '2',
          },
          {
            label: `It’s too expensive for me`,
            value: '3',
          },
          {
            label: `I thought was a different service`,
            value: '4',
          },
          {
            label: `Another reason`,
            value: 'other',
          },
        ]}
        label=""
        onChange={value => setReason(value)}
        defaultValue={reason}
      />
      {reason === 'other' && (
        <textarea
          className="mt-4 h-[120px] w-full rounded-md border p-2 text-base leading-6 text-gray-700"
          placeholder="Add text"
          value={otherReason}
          onChange={e => setOtherReason(e.target.value)}
        />
      )}
      <Button
        variant="primary"
        children="Next"
        parentClasses="mt-[32px]"
        width="w-full"
        isDisabled={!reason || (reason === 'other' ? !otherReason : false)}
        onPress={() => nextTab({ reason, otherReason })}
      />
    </div>
  );
};
