import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';

import { useGetAccountInfo, useUpdateAccountInfo, useUpdateCompany } from '@/api';
import { queryEndpoints } from '@/api/account/endpoints';
import { BROWSER_STORAGE_KEYS } from '@/utils/constants';

const useAccountInfo = () => {
  const queryClient = useQueryClient();

  const { isAuthenticated } = useAuth0();

  /**
   * Check if the account is authenticated as Impersonate by accessToken stored in session storage
   */
  const isAuthenticatedAsImpersonate = !!sessionStorage.getItem(
    BROWSER_STORAGE_KEYS.impersonateUserAccessToken,
  );

  const {
    data,
    isLoading: isAccountLoading,
    refetch: refetchAccountInfo,
  } = useGetAccountInfo({
    rqParams: {
      enabled: isAuthenticated || isAuthenticatedAsImpersonate,
      // Invalidate Account info after every 15 minutes
      staleTime: 1000 * 60 * 15,
      gcTime: Infinity,
      retry: 2,
    },
  });

  const account = data?.data;

  const accountId = account?.id;
  const company = account?.businessProfile;
  const companyId = company?.id ?? 0; // To fix Typescript error

  const { mutateAsync: patchAccount, isPending: isPatchingAccountDetails } = useUpdateAccountInfo({
    accountId: Number(accountId),
    mutationParams: {
      onSuccess: data => {
        if (data?.data) {
          queryClient.setQueryData(queryEndpoints.getAccountInfo().queryKey, data);
        }
      },
    },
  });

  const { mutateAsync: patchCompany, isPending: isPatchingCompanyDetails } = useUpdateCompany({
    companyId: companyId,
  });

  return {
    isAccountLoading,
    account,
    company,
    companyId,
    patchAccount,
    isPatchingAccountDetails,
    patchCompany,
    isPatchingCompanyDetails,
    refetchAccountInfo,
    isAuthenticatedAsImpersonate,
  };
};

export default useAccountInfo;
