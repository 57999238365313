import { TrashIcon } from '@heroicons/react/24/outline';

import Button from '@/components/Button/Button';

type CancelModalType = {
  handleOpenCancelModal: () => void;
  defaultStepHandler: () => void;
};

export const CancelModal = ({ handleOpenCancelModal, defaultStepHandler }: CancelModalType) => {
  return (
    <div className="relative flex flex-col items-center justify-center text-center">
      <div className="flex size-12 items-center justify-center rounded-[28px] border-8 border-red-50 bg-red-100">
        {<TrashIcon className="size-6 text-red-500" />}
      </div>
      <span className="mt-4 font-F37Bolton-Medium text-lg">Are you sure you want to cancel?</span>
      <span className="mt-1 w-[350px] text-sm text-gray-400">
        We'd hate to see you go! Canceling now means losing access to your payroll calculations.
      </span>
      <div className="mt-8 flex w-full flex-col-reverse items-center justify-center gap-x-3 lg:flex-row">
        <Button
          variant="tertiary"
          children="Cancel"
          width="w-full lg:w-[170px]"
          parentClasses="mt-3 lg:mt-0"
          onPress={defaultStepHandler}
        />
        <Button
          variant="primary"
          children="Continue to Cancel"
          width="w-full lg:w-[170px]"
          parentClasses="bg-red-600 hover:bg-red-700"
          onPress={handleOpenCancelModal}
        />
      </div>
    </div>
  );
};
