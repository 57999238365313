import { Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { LoaderComponent, VerticalStepperSidebar } from '@/components/ui';

import AccountSetupInfoCards from '../AccountSetupInfoCards/AccountSetupInfoCards';
import Header from '../Header';
import useAccountSetupLayout from './useAccountSetupLayout';

const AccountSetupLayout = () => {
  const {
    verticalStepper,
    activeStepIdentifier,
    isTeamMemberRouts,
    shouldShowWithoutCard,
    isAdditionHeaderBanner,
    isLoading,
  } = useAccountSetupLayout();

  return (
    <section>
      <div className="fixed z-[45] w-full">
        <Header showHelp showProfile showMobileMenuIcon={false} />
      </div>
      <aside
        className={twMerge(
          `fixed top-[72px] z-[2] max-h-[200px] w-full flex-col overflow-visible bg-light-brown sm:bg-white md:min-h-[100vh] md:w-[300px] lg:flex lg:h-[100vh] lg:w-[320px]`,
          isAdditionHeaderBanner ? 'top-[132px]' : '',
        )}
      >
        <VerticalStepperSidebar {...verticalStepper} />
      </aside>
      <div
        className={twMerge(
          'min-h-[100vh] bg-white pt-[173px] md:bg-light-brown md:pl-[300px] md:pt-14 lg:pl-[320px]',
          isAdditionHeaderBanner ? 'pt-60 md:pt-28' : '',
        )}
      >
        <div
          className={twMerge(
            `flex w-full max-w-[1100px] flex-col gap-6  py-[26px] sm:justify-between sm:py-8 xl:flex-row`,
            !!isTeamMemberRouts && 'xl:flex-col',
            shouldShowWithoutCard ? 'px-0 md:px-6' : 'px-6 sm:pl-6',
          )}
        >
          <div className="flex flex-1 flex-col">
            <div id="outsideAccountSetupCard" />
            <div
              className={twMerge(
                `h-fit flex-1 overflow-auto rounded-xl bg-white md:border md:border-gray-100`,
                !shouldShowWithoutCard && `md:p-5 lg:p-10 2xl:px-14 2xl:py-10`,
              )}
            >
              {isLoading ? <LoaderComponent /> : <Outlet />}
            </div>
          </div>
          <AccountSetupInfoCards
            identifier={activeStepIdentifier}
            isTeamMemberRouts={isTeamMemberRouts}
          />
        </div>
      </div>
    </section>
  );
};

export default AccountSetupLayout;
