import React from 'react';
import { Controller } from 'react-hook-form';
import InputDecimalsField from '../InputDecimalsField';
import { FormInputDecimalsFieldProps } from './types';

const FormInputDecimalsField: React.FC<FormInputDecimalsFieldProps> = ({
  name,
  isRequired,
  rules,
  isDisabled,
  shouldUnregister = true,
  onBlur,
  ...rest
}) => (
  <Controller
    name={name}
    shouldUnregister={shouldUnregister}
    disabled={Boolean(isDisabled)}
    render={({
      field: { value, onBlur: onFormInputBlur, ...restField },
      fieldState: { error },
    }) => (
      <InputDecimalsField
        errorMessage={error && !!error && error.message}
        isRequired={Boolean(isRequired || rules?.required)}
        value={value}
        isDisabled={isDisabled}
        {...rest}
        {...restField}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          onFormInputBlur();
          onBlur?.(event);
        }}
      />
    )}
    rules={rules}
  />
);

export default FormInputDecimalsField;
