import { OnEarningTypeValueChangeType } from '@/modules/runPayroll/components/InsertInformationRunPayroll/types';
import { useFormContext } from 'react-hook-form';

type UsePayTypeCardType = {
  name: string;
  sectionName: string;
  payTypeIndex: number;
  onEnableChangeHandler?: (data: OnEarningTypeValueChangeType) => void;
};

const usePayTypeCard = ({
  name,
  sectionName,
  payTypeIndex,
  onEnableChangeHandler,
}: UsePayTypeCardType) => {
  const { watch, setValue } = useFormContext();

  const sectionFields = watch(sectionName);

  const formattedSectionFields = Array.isArray(sectionFields) ? sectionFields : [];

  const formattedPayTypeIndex =
    formattedSectionFields.findIndex(field => field.name === name) ?? payTypeIndex;

  const fieldName = `${sectionName}.${formattedPayTypeIndex}.enabled`;

  const isChecked = watch(fieldName);

  const onChange = (value: boolean) => {
    if (onEnableChangeHandler && !value) {
      onEnableChangeHandler({
        field: { name, fieldName },
        value,
      });
    } else {
      setValue(fieldName, value, { shouldDirty: true });
    }
  };

  return { isChecked, onChange };
};

export default usePayTypeCard;
