import React from 'react';
import Lottie from 'lottie-react';

import loaderPage from '@/assets/lottie/loader.json';

interface LoaderProps {
  height?: number;
  classNames?: string;
}

const LoaderComponent: React.FC<LoaderProps> = ({ height = 50, classNames = '' }) => {
  return (
    <div className={`items-center justify-center ${classNames}`}>
      <Lottie animationData={loaderPage} style={{ height }} loop={true} />
    </div>
  );
};

export default LoaderComponent;
