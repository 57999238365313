import { useState } from 'react';

const useInputPasswordField = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return { handleShowPassword, showPassword };
};
export default useInputPasswordField;
