import * as Yup from 'yup';

export const AddPolicyFormSchema = Yup.object().shape({
  type: Yup.string().required('Paid time-off type is required'),
  title: Yup.string().required('Title is required'),
  description: Yup.string().optional(),
  accrualMethod: Yup.string().required('Accrual method is required'),
  accrualRate: Yup.number().when('accrualMethod', {
    is: (val: string) => ['per_hour_worked'].includes(val),
    then: schema => schema.required('Required'),
    otherwise: schema => schema.notRequired(),
  }),
  perHoursWorked: Yup.number().when('accrualMethod', {
    is: (val: string) => val === 'per_hour_worked',
    then: schema => schema.required('Required'),
    otherwise: schema => schema.notRequired(),
  }),
  maximumHoursAllowed: Yup.number().optional().nullable().positive('Must be a positive number'),
  hoursPerYear: Yup.number().when('accrualMethod', {
    is: (val: string) => ['beginning_of_year', 'per_pay_period', 'on_hire_date'].includes(val),
    then: schema => schema.required('Required'),
    otherwise: schema => schema.notRequired(),
  }),
});
