import { createModel } from '@rematch/core';

import { getSchedule, previewSchedule, submitSchedule } from '@/api/services/payrollSchedule';
import { PreviewSchedule, SheduleRoot } from '@/redux/dto/schedule';
import { callToast } from '@/utils/helpers';

import type { RootModel } from '.';

type scheduleState = {
  erorr: unknown;
  previewSchedule: PreviewSchedule[];
  schedule: SheduleRoot;
  isOpen: boolean;
};

export const schedule = createModel<RootModel>()({
  state: {
    error: null,
    previewSchedule: null,
    schedule: null,
    isOpen: false,
  } as unknown as scheduleState,
  reducers: {
    OPEN_PAYROLL_SCHEDULE: state => {
      return {
        ...state,
        isOpen: true,
      };
    },
    CLOSE_PAYROLL_SCHEDULE: state => {
      return {
        ...state,
        isOpen: false,
      };
    },
    GET_SCHEDULE_PAYROLL_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        schedule: payload,
      };
    },
    GET_SCHEDULE_PAYROLL_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PREVIEW_SCHEDULE_PAYROLL_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        previewSchedule: payload.scheduleEntries,
      };
    },
    PREVIEW_SCHEDULE_PAYROLL_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    SUBMIT_SCHEDULE_PAYROLL_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        schedule: payload,
      };
    },
    SUBMIT_SCHEDULE_PAYROLL_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },
  effects: dispatch => {
    const { schedule } = dispatch;
    return {
      openPayrollSchedule: () => {
        schedule.OPEN_PAYROLL_SCHEDULE();
      },
      closePayrollSchedule: () => {
        schedule.CLOSE_PAYROLL_SCHEDULE();
      },
      async getSchedule(payload): Promise<unknown> {
        try {
          const { companyId } = payload;
          const { data } = await getSchedule(companyId);
          schedule.GET_SCHEDULE_PAYROLL_SUCCESS(data);
          return data;
        } catch (error: any) {
          schedule.GET_SCHEDULE_PAYROLL_FAILURE(error);
        }
      },
      async getPreviewSchedule(payload): Promise<unknown> {
        try {
          const { companyId, data: state } = payload;
          const { data } = await previewSchedule(companyId, state);
          schedule.PREVIEW_SCHEDULE_PAYROLL_SUCCESS(data);
          return data;
        } catch (error: any) {
          schedule.PREVIEW_SCHEDULE_PAYROLL_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async submitSchedule(payload): Promise<unknown> {
        try {
          const { companyId, data: state } = payload;
          const { data } = await submitSchedule(companyId, state);
          schedule.SUBMIT_SCHEDULE_PAYROLL_SUCCESS(data);
          return data;
        } catch (error: any) {
          schedule.SUBMIT_SCHEDULE_PAYROLL_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
