import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AccountSetupLayout } from '@/components';
import DashBoardLayout from '@/components/shared/DashboardLayout';
import Loader from '@/components/ui/Loader/Loader';
import AccountSetupPage from '@/pages/accountSetup/AccountSetup';

import PrivateRoute from './PrivateRoute';
import pagesRoutesData from './pagesRoutesData';
import { ACCOUNT_SETUP } from './routes';

const StackRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<DashBoardLayout />}>
          {pagesRoutesData.privateRoutes.map(
            ({
              path,
              loadPageComponent,
              navigateTo = '',
              caseSensitive = false,
              replace = false,
              state = {},
            }) => {
              const LazyLoadedPage = loadPageComponent;

              return (
                <Route
                  key={path}
                  path={path}
                  caseSensitive={caseSensitive}
                  element={
                    loadPageComponent ? (
                      <Suspense fallback={<Loader />}>
                        <LazyLoadedPage />
                      </Suspense>
                    ) : (
                      <Navigate to={navigateTo} state={state} replace={replace} />
                    )
                  }
                />
              );
            },
          )}
        </Route>
        <Route path={ACCOUNT_SETUP} element={<AccountSetupPage />} />

        <Route element={<AccountSetupLayout />}>
          {pagesRoutesData.accountSetupRoutes.map(
            ({
              path,
              loadPageComponent,
              navigateTo = '',
              caseSensitive = false,
              replace = false,
              state = {},
            }) => {
              const LazyLoadedPage = loadPageComponent;

              return (
                <Route
                  key={path}
                  path={path}
                  caseSensitive={caseSensitive}
                  element={
                    loadPageComponent ? (
                      <Suspense fallback={<Loader />}>
                        <LazyLoadedPage />
                      </Suspense>
                    ) : (
                      <Navigate to={navigateTo} state={state} replace={replace} />
                    )
                  }
                />
              );
            },
          )}
        </Route>
      </Route>

      {pagesRoutesData.publicRoutes.map(
        ({
          path,
          loadPageComponent,
          navigateTo = '',
          caseSensitive = false,
          replace = false,
          state = {},
        }) => {
          const LazyLoadedPage = loadPageComponent;

          return (
            <Route
              key={path}
              path={path}
              caseSensitive={caseSensitive}
              element={
                loadPageComponent ? (
                  <Suspense fallback={<Loader />}>
                    <LazyLoadedPage />
                  </Suspense>
                ) : (
                  <Navigate to={navigateTo} state={state} replace={replace} />
                )
              }
            />
          );
        },
      )}
    </Routes>
  );
};

export default StackRoutes;
