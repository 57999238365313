import { Dialog } from '@headlessui/react';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface DialogComponentProps {
  open: boolean;
  onCancel: () => void;
  children: ReactNode;
  width?: string;
  height?: string;
  dialogClasses?: string;
  modalClasses?: string;
  /**
   * Is modal closed when clicked outside of it or on Esc key press
   * @default false
   */
  isDismissable?: boolean;
}

const DialogComponent: FC<DialogComponentProps> = ({
  open,
  onCancel,
  children,
  width,
  height,
  dialogClasses,
  modalClasses,
  isDismissable,
}) => {
  return (
    <Dialog
      open={!!open}
      onClose={isDismissable ? onCancel : () => {}}
      className={`relative z-[100] ${dialogClasses ? dialogClasses : ''}`}
    >
      <div className="fixed inset-0 z-50">
        <div className="absolute left-0 top-0 flex h-full w-[100vw] items-center justify-center backdrop-blur-sm backdrop-brightness-75">
          <Dialog.Panel>
            <div
              className={twMerge(
                `relative overflow-y-auto lg:rounded-[12px] ${
                  height ? height : 'h-[100vh] sm:max-h-[95vh] sm:min-h-[95vh]'
                } ${width ? width : 'w-[610px]'} bg-white ${modalClasses}`,
              )}
            >
              {children}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogComponent;
