import Toggle from '@/components/Toggle/Toggle';
import { OnEarningTypeValueChangeType } from '@/modules/runPayroll/components/InsertInformationRunPayroll/types';
import { Bars2Icon } from '@heroicons/react/24/outline';
import { forwardRef } from 'react';
import usePayTypeCard from './usePayTypeCard';

type PayTypeCardProps = {
  title: string;
  description: string;
  isRequired: boolean;
  name: string;
  sectionName: string;
  payTypeIndex: number;
  dragHandleProps?: any;
  onEnableChangeHandler?: (data: OnEarningTypeValueChangeType) => void;
};

const PayTypeCard = forwardRef<HTMLDivElement, PayTypeCardProps>(
  (
    {
      title,
      description,
      isRequired = false,
      name,
      sectionName,
      payTypeIndex,
      dragHandleProps,
      onEnableChangeHandler,
      ...rest
    },
    ref,
  ) => {
    const { isChecked, onChange } = usePayTypeCard({
      name,
      sectionName,
      payTypeIndex,
      onEnableChangeHandler,
    });

    return (
      <div className="my-2 rounded-lg border bg-white px-4 py-3" ref={ref} {...rest}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            {!isRequired && (
              <div className="w-7" {...dragHandleProps}>
                <Bars2Icon className="w-5" />
              </div>
            )}
            <div className="flex flex-col gap-1">
              <p>
                {title}{' '}
                {isRequired ? <span className="text-sm text-gray-400">(Required)</span> : ''}
              </p>
              <p className="text-sm text-gray-400">{description}</p>
            </div>
          </div>
          <div className="w-full max-w-12">
            {!isRequired && <Toggle isChecked={isChecked} onChange={onChange} />}
          </div>
        </div>
      </div>
    );
  },
);

export default PayTypeCard;
