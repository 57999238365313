/* eslint-disable react/prop-types */
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

const authDomain = import.meta.env.APP_AUTH_DOMAIN;
const authClientId = import.meta.env.APP_AUTH_CLIENT_ID;
const audience = import.meta.env.APP_AUTH_AUDIENCE;
const currentDomain = window.location.hostname;
const currentDomainHost = window.location.host;
const callBack =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? `http://${currentDomainHost}/callback`
    : `https://${currentDomain}/callback`;

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: { returnTo: string }) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = {
    domain: authDomain,
    clientId: authClientId,
    onRedirectCallback: onRedirectCallback,

    authorizationParams: {
      redirect_uri: callBack,
      ...(audience
        ? {
            audience: audience,
          }
        : null),
    },
  };

  if (!(authDomain && authClientId && callBack)) {
    return null;
  }

  return <Auth0Provider {...providerConfig}> {children}</Auth0Provider>;
};
