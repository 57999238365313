import { Switch } from 'react-aria-components';

const Toggle = ({
  isChecked,
  onChange,
  label = '',
  isDisabled = false,
  labelPosition = 'after',
  ...rest
}: {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  label?: React.ReactNode;
  isDisabled?: boolean;
  labelPosition?: 'before' | 'after';
}) => {
  return (
    <Switch
      isSelected={isChecked}
      className={`group flex items-center gap-2`}
      onChange={onChange}
      isDisabled={isDisabled}
      {...rest}
    >
      <div
        className={`box-border flex h-[32px] w-[51px] shrink-0 cursor-pointer rounded-full border border-solid border-white/30 bg-clip-padding p-[2.5px] shadow-inner outline-none ring-black transition duration-200 ease-in-out group-focus-visible:ring-2 ${
          isDisabled
            ? 'bg-gray-200'
            : 'bg-gray-300 group-pressed:bg-emerald-500 group-selected:bg-emerald-500 group-selected:group-pressed:bg-emerald-500 group-selected:group-pressed:ring-emerald-500'
        }`}
      >
        <span
          className={`h-[24px] w-[24px] translate-x-0 transform rounded-full text-lg font-semibold text-black ${
            isDisabled ? 'bg-gray-300' : 'bg-white'
          } shadow transition duration-200 ease-in-out  group-selected:translate-x-[80%]`}
        />
      </div>
      {label}
    </Switch>
  );
};

export default Toggle;
