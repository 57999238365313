import axios from '../axios';

export interface UpdateEinSsnPayload {
  ein: string;
  ssn: string;
}
//Get Account's Todos and Action Items

export const getDashboardList = (companyId: number) => {
  return axios.get(`/company/${companyId}/dashboard`);
};

//Update EIN/SSN of a Company

export const updateEinSsnOfCompany = (companyId: number, payload: UpdateEinSsnPayload) => {
  return axios.patch(`/company/${companyId}/dashboard/update-ein-ssn`, payload);
};

export const skipContractors = (companyId: number) => {
  return axios.patch(`/company/${companyId}/dashboard/skipped-contractor`, {});
};
