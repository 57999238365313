import { createModel } from '@rematch/core';

import { callToast } from '@/utils/helpers';
import {
  getContractors1099NecFormsPerYearEndpoint,
  getContractor1099NecFormPerYearDetailsEndpoint,
  saveContractors1099NecFormsForPreviewEndpoint,
  putFormAsDraft,
  cancelForm,
  getPreviewForm,
  patchFormAsDraft,
  patchFormAsApproved,
  postShareViaEmail,
  getDownloadForm,
} from '@/api/services/1099-nec';

import type { RootModel } from '.';

type membersState = {
  erorr: unknown;
  contractors: [];
  contractorDetails: {};
};

export const _1099nec = createModel<RootModel>()({
  state: {
    error: null,
    contractors: [],
    contractorDetails: {},
  } as unknown as membersState,
  reducers: {
    GET_CONTRACTORS_SUCCESS: (state, payload) => ({
      ...state,
      contractors: payload,
    }),
    GET_CONTRACTORS_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
    GET_CONTRACTOR_DETAILS_SUCCESS: (state, payload) => {
      return {
        ...state,
        contractorDetails: {
          ...state.contractorDetails,
          ...payload,
        },
      };
    },
    GET_CONTRACTORS_DETAILS_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
    DRAFT_FORM_SUCCESS: (state, payload) => {
      return {
        ...state,
        contractorDetails: {
          ...state.contractorDetails,
          ...payload,
        },
      };
    },
    DRAFT_FORM_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
    CANCEL_FORM_SUCCESS: (state, payload) => {
      return {
        ...state,
        contractorDetails: {
          ...state.contractorDetails,
          ...payload,
        },
      };
    },
    CANCEL_FORM_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
    GET_PREVIEW_FORM_SUCCESS: (state, payload) => {
      return {
        ...state,
        contractorDetails: {
          ...state.contractorDetails,
          ...payload,
        },
      };
    },
    GET_PREVIEW_FORM_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
    GET_DOWNLOAD_FORM_SUCCESS: (state, payload) => {
      return {
        ...state,
        contractorDetails: {
          ...state.contractorDetails,
          ...payload,
        },
      };
    },
    GET_DOWNLOAD_FORM_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
    PATCH_FORM_DRAFT_SUCCESS: (state, payload) => {
      return {
        ...state,
        contractorDetails: {
          ...state.contractorDetails,
          ...payload,
        },
      };
    },
    PATCH_FORM_DRAFT_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
    PATCH_FORM_APPROVED_SUCCESS: (state, payload) => {
      return {
        ...state,
        contractorDetails: {
          ...state.contractorDetails,
          ...payload,
        },
      };
    },
    PATCH_FORM_APPROVED_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
    SHARE_VIA_EMAIL_SUCCESS: (state, payload) => {
      return {
        ...state,
        contractorDetails: {
          ...state.contractorDetails,
          ...payload,
        },
      };
    },
    SHARE_VIA_EMAIL_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
  },
  effects: dispatch => {
    const { _1099nec } = dispatch;
    return {
      async getContractors1099NecFormsPerYearList(payload): Promise<unknown> {
        try {
          const { data } = await getContractors1099NecFormsPerYearEndpoint(payload);
          _1099nec.GET_CONTRACTORS_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.GET_CONTRACTORS_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getContractor1099NecFormPerYearDetailsEndpoint(payload): Promise<unknown> {
        try {
          const { data } = await getContractor1099NecFormPerYearDetailsEndpoint(payload);
          _1099nec.GET_CONTRACTOR_DETAILS_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.GET_CONTRACTOR_DETAILS_SUCCESS(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async saveContractors1099NecFormsForPreview(payload): Promise<unknown> {
        try {
          const { id, body } = payload;
          const { data } = await saveContractors1099NecFormsForPreviewEndpoint({ id, body });
          _1099nec.GET_CONTRACTOR_DETAILS_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.GET_CONTRACTOR_DETAILS_SUCCESS(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async putContractors1099NecFormAsDraft(payload): Promise<unknown> {
        try {
          const { formId, body } = payload;
          const { data } = await putFormAsDraft(formId, body);
          _1099nec.DRAFT_FORM_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.DRAFT_FORM_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async cancelContractors1099NecForm(payload): Promise<unknown> {
        try {
          const { formId } = payload;
          const { data } = await cancelForm(formId);
          _1099nec.CANCEL_FORM_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.CANCEL_FORM_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async get1099NecFormPreview(payload): Promise<unknown> {
        try {
          const { formId } = payload;
          const { data } = await getPreviewForm(formId);
          _1099nec.GET_PREVIEW_FORM_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.GET_PREVIEW_FORM_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async get1099NecFormDownload(payload): Promise<unknown> {
        try {
          const { formId } = payload;
          const { data } = await getDownloadForm(formId);
          _1099nec.GET_DOWNLOAD_FORM_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.GET_DOWNLOAD_FORM_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async patch1099NecFormDraft(payload): Promise<unknown> {
        try {
          const { formId } = payload;
          const { data } = await patchFormAsDraft(formId);
          _1099nec.PATCH_FORM_DRAFT_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.PATCH_FORM_DRAFT_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async patch1099NecFormApproved(payload): Promise<unknown> {
        try {
          const { formId } = payload;
          const { data } = await patchFormAsApproved(formId);
          _1099nec.PATCH_FORM_APPROVED_SUCCESS(data);
          return data;
        } catch (error: any) {
          _1099nec.PATCH_FORM_APPROVED_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async shareViaEmail(payload): Promise<unknown> {
        try {
          const { formID, content } = payload;
          const { data, status } = await postShareViaEmail(formID, content);
          _1099nec.SHARE_VIA_EMAIL_SUCCESS(data);
          return { data, status };
        } catch (error: any) {
          _1099nec.SHARE_VIA_EMAIL_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
