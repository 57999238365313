import moment from 'moment';
import { useMemo } from 'react';

import { SubscriptionInfo } from '@/redux/dto/auth';
import { AVAILABLE_SUBSCRIPTIONS, FREE_3_MONTH_TRIAL_SUBSCRIPTION } from '@/utils/constants';

import { SubscriptionBillingCardProps } from './SubscriptionBillingCard';

type UseSubscriptionBillingCardType = {
  subscriptionData: SubscriptionInfo['activeSubscriptionAgreement'];
  usageStates: SubscriptionInfo['usageStats'];
};

const useSubscriptionBillingCard = ({
  subscriptionData,
  usageStates,
}: UseSubscriptionBillingCardType) => {
  const formattedSubscriptionData = useMemo(() => {
    if (!subscriptionData) {
      return null;
    }
    const { plan, nextProcessingDate, price, status, startDate, endDate, scheduledToBeCancelled } =
      subscriptionData;

    const planEndDate = moment(endDate);
    const isEndDatePassed = planEndDate.isBefore(moment());
    const { product, amount, pricingStrategy } = price || {};
    const isFreeTrial = plan === FREE_3_MONTH_TRIAL_SUBSCRIPTION;

    const { numOfActiveContractors, numOfActiveEmployees } = usageStates || {};

    const totalTeamMembers = (numOfActiveContractors ?? 0) + (numOfActiveEmployees ?? 0);
    const progressType = product?.type === 'time-based' ? 'days' : 'employee';

    const planStatus =
      status === 'canceled' || scheduledToBeCancelled
        ? 'cancelled'
        : status === 'ended' || isEndDatePassed
        ? 'expired'
        : 'active';

    const formattedMaxValue =
      (progressType === 'days'
        ? moment(endDate).diff(startDate, 'days')
        : product?.metadata?.maxSeats) ?? 0;

    const formattedCurrentValue =
      (progressType === 'days' ? moment().diff(startDate, 'days') : totalTeamMembers) ?? 0;

    const data: SubscriptionBillingCardProps = {
      planName: plan ?? '',
      description: product?.description ?? '',
      isFreeTrial,
      isShowProgress: true,
      isUpgradePlanAvailable: plan !== AVAILABLE_SUBSCRIPTIONS.advancedPlus,
      price: amount,
      pricingStrategy,
      progressType,
      freeTrialEndDate: isFreeTrial && endDate ? endDate : null,
      currentValue: formattedCurrentValue,
      status: planStatus,
      maxValue: formattedMaxValue,
      nextInvoiceDate: nextProcessingDate,
    };
    return data;
  }, [subscriptionData]);

  return { formattedSubscriptionData };
};

export default useSubscriptionBillingCard;
