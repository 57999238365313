import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Dialog, DialogTrigger, OverlayArrow, Popover } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';

import { SupportBox } from './components';
import { SupportHelpProps } from './types';

const SupportHelp: FC<SupportHelpProps> = ({ className = '', onBookCall, ...rest }) => {
  return (
    <>
      <DialogTrigger>
        <Button
          variant="text"
          parentClasses={twMerge(
            'flex items-center gap-1 !rounded-[1000px] group !h-fit !border !border-solid px-4 !py-1.5 ',
            `${className}`,
          )}
          {...rest}
        >
          <div className="size-2 rounded-[1000px] bg-teal-600"></div>
          Help
          <ChevronDownIcon className="mt-0.5 w-5 text-gray-300 transition-[margin] group-hover:mt-1" />
        </Button>
        <Popover
          offset={-10}
          placement="bottom right"
          className={({ isEntering, isExiting }) => `
        group w-[275px] rounded-xl bg-white p-6 ring-0 drop-shadow-lg placement-top:mb-2 placement-bottom:mt-2 focus-visible:outline-none sm:w-[300px]
        ${
          isEntering
            ? 'animate-in fade-in placement-bottom:slide-in-from-top-1 placement-top:slide-in-from-bottom-1 duration-200 ease-out'
            : ''
        }
        ${
          isExiting
            ? 'animate-out fade-out placement-bottom:slide-out-to-top-1 placement-top:slide-out-to-bottom-1 duration-150 ease-in'
            : ''
        }
      `}
        >
          <OverlayArrow>
            <svg
              viewBox="0 0 12 12"
              className="block size-4 fill-white group-placement-bottom:rotate-180"
            >
              <path d="M0 0 L6 6 L12 0" />
            </svg>
          </OverlayArrow>
          <Dialog className="focus-visible:outline-none">
            <SupportBox onBookCall={onBookCall} />
          </Dialog>
        </Popover>
      </DialogTrigger>
    </>
  );
};

export default SupportHelp;
