import Button from '@/components/Button/Button';
import { default as CheckBoxGroup } from '@/components/CheckboxGroup/CheckboxGroup';
import RadioButtonVerticalGroup from '@/components/RadioButtonGroup/RadioButtonVerticalGroup';
import FormInputDecimalsField from '@/components/ui/FormInputDecimalsField';
import { Controller, FormProvider } from 'react-hook-form';
import { useDetailsForm } from '../../hooks/useDetailsForm';
import { DetailsFormType } from '../../types';
import { LastTabContent } from '../LastTabContent/LastTabContent';

export const DetailsForm = ({
  reason,
  nextTab,
  handleCancelSubscription,
  handleScheduleCall,
  handleCallNow,
  isLoading,
}: DetailsFormType) => {
  const {
    formState,
    onSubmit,
    setLookingFor,
    setLookingForOther,
    isButtonDisabled,
    renderTitle,
    noProvider,
    lookingFor,
    lookingForOther,
    isNoProvider,
    setIsNoProvider,
    featuresOptions,
    difficultiesOptions,
  } = useDetailsForm({ reason, nextTab });

  return (
    <FormProvider {...formState}>
      <form onSubmit={formState.handleSubmit(onSubmit)}>
        <span
          className={`block font-F37Bolton-Medium text-[20px] ${reason !== 'other' && 'mt-[32px]'}`}
        >
          {renderTitle()}
        </span>
        {reason === '1' && (
          <Controller
            control={formState.control}
            name={`features`}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <CheckBoxGroup
                title=""
                options={featuresOptions}
                onChange={onChange}
                value={value}
                name={name}
                errorMessage={formState?.formState?.errors?.interest?.message}
                inputErrorMessage={formState?.formState?.errors?.otherInterest?.message}
                control={formState.control}
              />
            )}
          />
        )}

        {reason === '2' && (
          <Controller
            control={formState.control}
            name={`difficulties`}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <CheckBoxGroup
                title=""
                options={difficultiesOptions}
                onChange={onChange}
                value={value}
                name={name}
                errorMessage={formState?.formState?.errors?.interest?.message}
                inputErrorMessage={formState?.formState?.errors?.otherInterest?.message}
                control={formState?.formState?.control}
              />
            )}
          />
        )}

        {reason === '3' && (
          <div>
            <FormInputDecimalsField
              width="w-[335px] lg:w-[400px]"
              name="amount"
              title="Amount per pay period"
              InputAdornment={'$'}
              decimalsLimit={2}
              placeholder="0.00"
              step={0.01}
              decimalSeparator="."
              groupSeparator=","
              min={0}
              parentClasses="mt-[20px]"
              isRequired
              isDisabled={noProvider}
            />
            <Controller
              control={formState.control}
              name={`noProvider`}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <label className="mt-[16px] flex items-center">
                  <input
                    type="checkbox"
                    className="rounded-[4px] focus:outline-none focus:ring-0  focus:ring-offset-0"
                    onChange={e => {
                      onChange(e.target.checked);
                      setIsNoProvider(e.target.checked);
                    }}
                    onBlur={onBlur}
                    checked={isNoProvider}
                    value={value}
                    ref={ref}
                  />
                  <span className="ml-[8px] block">I don’t have payroll provider right now</span>
                </label>
              )}
            />
          </div>
        )}

        {reason === '4' && (
          <>
            <RadioButtonVerticalGroup
              options={[
                {
                  label: `I am a business owner and just need paystub(s) for my employee(s)`,
                  value: 1,
                },
                {
                  label: `I am an employee and need paystub for personal reason`,
                  value: 2,
                },
                {
                  label: `I just wanted to generate a one-time tax form`,
                  value: 3,
                },
                {
                  label: `Your solution doesn't support the number of employees that I have`,
                  value: 4,
                },
                {
                  label: `Another reason`,
                  value: 'other',
                },
              ]}
              label=""
              onChange={value => setLookingFor(value)}
            />
            {lookingFor === 'other' && (
              <textarea
                className="mt-4 h-[120px] w-full rounded-md border p-2 text-base leading-6 text-gray-700"
                placeholder="Add text"
                value={lookingForOther}
                onChange={e => setLookingForOther(e.target.value)}
              />
            )}
          </>
        )}

        {reason === 'other' && (
          <LastTabContent
            reason="other"
            handleCancelSubscription={handleCancelSubscription}
            handleScheduleCall={handleScheduleCall}
            handleCallNow={handleCallNow}
            isLoading={isLoading}
          />
        )}

        {reason !== 'other' && (
          <Button
            variant="primary"
            type="submit"
            children="Next"
            width="w-[335px] lg:w-[400px]"
            parentClasses="mt-[32px]"
            isDisabled={isButtonDisabled}
          />
        )}
      </form>
    </FormProvider>
  );
};
