import { Controller } from 'react-hook-form';
import InputCheckboxField from '../InputCheckboxField/InputCheckboxField';
import { FormInputCheckboxFieldProps } from './types';

const FormInputCheckboxField = ({
  name,
  isDisabled,
  rules,
  shouldUnregister = true,
  ...rest
}: FormInputCheckboxFieldProps) => {
  return (
    <Controller
      name={name}
      disabled={Boolean(isDisabled)}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState: { error } }) => (
        <InputCheckboxField
          errorMessage={error && !!error && error.message}
          disabled={Boolean(isDisabled)}
          {...rest}
          {...field}
        />
      )}
      rules={rules}
    />
  );
};

export default FormInputCheckboxField;
