import { ReactComponent as ArrowIcon } from '@/assets/arrowSmooth.svg';
import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';
import Tabs from '@/components/Tabs/Tabs';
import { useCancelSubscriptionReworked } from './hooks/useCancelSubscriptionReworked';
import { CalendlyModal } from './modals/CalendlyModal';
import { CancelSubscriptionReworkedType } from './types';

export const CancelSubscriptionReworked = ({ onClose }: CancelSubscriptionReworkedType) => {
  const {
    tabs,
    currentTab,
    setCurrentTab,
    renderSpanContent,
    previousTab,
    isContentHidden,
    reason,
    isCalendlyModalOpen,
    setIsCalendlyModalOpen,
    isBackButtonVisible,
  } = useCancelSubscriptionReworked({ onClose });

  return (
    <div className="h-full">
      <div className="flex h-[40px] items-center justify-between border-b border-gray-100 py-[10px]">
        <div className="w-1/3 pl-[16px]">
          {isBackButtonVisible ? (
            <Button variant="icon" icon={ArrowIcon} children="Back" onPress={previousTab} />
          ) : null}
        </div>
        <span className="block w-1/3 text-center font-F37Bolton-Medium text-sm lg:text-base">
          Cancel subscription
        </span>
        <div className="flex w-1/3 justify-end pr-[16px]">
          <Button variant="icon" icon={CloseIcon} onPress={onClose} />
        </div>
      </div>

      <div
        className={`flex flex-col items-center justify-center px-[20px] ${
          isContentHidden ? 'h-[calc(100%-48px)]' : 'h-auto'
        }`}
      >
        <span
          className={`mt-[24px] block font-F37Bolton-Medium text-[20px] ${
            isContentHidden && 'hidden'
          }`}
        >
          {renderSpanContent()}
        </span>
        <div className={`${!isContentHidden && 'mt-[32px]'}`}>
          <Tabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            width="max-w-[400px] w-[335px] lg:w-[400px]"
            additionalTabWidth={70}
            isProgressHidden={isContentHidden}
          />
        </div>
      </div>
      {isCalendlyModalOpen && (
        <DialogComponent
          open={isCalendlyModalOpen}
          onCancel={() => {
            setIsCalendlyModalOpen(false);
          }}
          children={
            <CalendlyModal
              onClose={() => {
                setIsCalendlyModalOpen(false);
              }}
            />
          }
          width="w-[100vw] lg:w-[610px]"
          height="h-[100vh] lg:max-h-[800px]"
        />
      )}
    </div>
  );
};
