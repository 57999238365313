import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { getCall } from '@/api/axios';

import { queryEndpoints } from './endpoints';
import { ContentBlockType, UseGetContentBlockTypes } from './types';

export const useGetContentBlock = ({
  identifier,
  queryParams,
}: UseGetContentBlockTypes): UseQueryResult<ContentBlockType, Error> => {
  const { queryKey, url } = queryEndpoints.contentBlock(identifier);

  return useQuery<ContentBlockType, Error>({
    queryKey,
    queryFn: () => getCall(url),
    select: data => data.data,
    retry: false,
    ...queryParams,
  });
};
