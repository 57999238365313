import { MutationEndpoint, QueryEndpoint } from '@/api/types';

export const queryEndpoints = {
  getAccountInfo: (): QueryEndpoint => ({
    queryKey: ['account-info'],
    url: '/account',
  }),
};

export const mutationEndpoints = {
  updateAccountInfo: (accountId: number): MutationEndpoint => ({
    mutationKey: ['account', accountId],
    url: `/account/${accountId}`,
    invalidateKeys: [],
  }),
  updateCompany: (companyId: number): MutationEndpoint => ({
    mutationKey: ['company', companyId],
    url: `/company/${companyId}`,
    invalidateKeys: [queryEndpoints.getAccountInfo().queryKey],
  }),
};
