import { createModel } from '@rematch/core';

import { callToast } from '@/utils/helpers';
import {
  getContractorsDetails,
  updateContractorsDetails,
  activateContractor,
  deactivateContractor,
  postCreateIncompleteContractor,
  putUpdateContractorsEmploymentInformation,
  putUpdateCompensationInformation,
  contactorProfile,
} from '@/api/services/contractor';
import {
  cancelContartorsPayment,
  confirmPaymentsContractorsById,
  getListOfActiveContractors,
  postPaymentContractors,
} from '@/api/services/payContractors';
import {
  deleteContractorOnAdd,
  deletePayrollContractorById,
  getContractorPaymentHistoryDetails,
  getContractorPayStubs,
  previewContractorPayStub,
  getContractorPayStubsDownload,
  getContractorPayStubsDownloadItem,
  getContractorsPayStubs,
  getListOfPayrollHistoryContractor,
  getPayStubsHistoryContractor,
} from '@/api/services/payrollHistory';
import {
  CurrentPaymentHistoryContractor,
  Contractor,
  PayStubs,
  PaymentHistory,
  PaymentsContractorsInformation,
  currentContractorInformation,
} from '@/redux/dto/contractor';
import { shareContractorPayrollEndpoint } from '@/api/services/sharePayroll';

import type { RootModel } from '.';

type contractorState = {
  erorr: unknown;
  incompleteContractor: contactorProfile;
  currentContractor: currentContractorInformation;
  activeContactors: Contractor[];
  listPayrollHistoryContractor: PaymentHistory;
  paymentsContractorsInformation: PaymentsContractorsInformation;
  currentPaymentHistoryContractor: CurrentPaymentHistoryContractor;
  payStubs: PayStubs;
};
export const contractor = createModel<RootModel>()({
  state: {
    error: null,
    incompleteContractor: null,
    employmentInformation: null,
    currentContractor: null,
    activeContactors: [],
    paymentsContractorsInformation: null,
    listPayrollHistoryContractor: null,
    currentPaymentHistoryContractor: null,
    payStubs: null,
    contractorPayStubPreviewData: null,
  } as unknown as contractorState,
  reducers: {
    POST_CREATE_INCOMPLETE_CONTRACTOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        incompleteContractor: payload,
      };
    },
    POST_CREATE_INCOMPLETE_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PUT_EMPLOYMENT_INFORMATION_CONTRACTOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        employmentInformation: payload,
      };
    },
    PUT_EMPLOYMENT_INFORMATION_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    PUT_COMPENSATION_INFORMATION_CONTRACTOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        compensation: payload,
      };
    },
    PUT_COMPENSATION_INFORMATION_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CURRENT_PAYMENT_INFORMATION_CURRENT_CONTRACTOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        currentPaymentHistoryContractor: payload,
      };
    },
    GET_CURRENT_PAYMENT_INFORMATION_CURRENT_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CONTRACTOR_DETAILS_BY_ID_SUCCESS: (state, payload) => {
      return {
        ...state,
        currentContractor: payload,
      };
    },
    GET_CONTRACTOR_DETAILS_BY_ID_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DEACTIVATE_CONTRACTOR_SUCCESS: state => {
      return {
        ...state,
        currentContractor: {
          ...state.currentContractor,
          isActive: false,
        },
      };
    },
    DEACTIVATE_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    ACTIVATE_CONTRACTOR_SUCCESS: state => {
      return {
        ...state,
        currentContractor: {
          ...state.currentContractor,
          isActive: true,
        },
      };
    },
    ACTIVATE_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_CONTRACTOR_INFORMATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        currentContractor: {
          ...state.currentContractor,
          ...payload,
        },
      };
    },
    UPDATE_CONTRACTOR_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_EMPLOYMENT_INFORMATION_CONTRACTOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentContractor: {
          ...state.currentContractor,
          ...payload,
        },
      };
    },
    UPDATE_EMPLOYMENT_INFORMATION_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_COMPENSATION_INFORMATION_CONTRACTOR_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        currentContractor: {
          ...state.currentContractor,
          ...payload,
        },
      };
    },
    UPDATE_COMPENSATION_INFORMATION_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_ACTIVE_CONTRACTORS_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        activeContactors: payload,
      };
    },
    GET_ACTIVE_CONTACTORS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        listPayrollHistoryContractor: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_TAB_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        listPayrollHistoryContractor: payload,
      };
    },
    GET_LIST_PAYROLL_HISTORY_TAB_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CONTRACTORS_PAY_STUBS_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        payStubs: payload,
      };
    },
    GET_CONTRACTORS_PAYSTUB_PREVIEW_DATA_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        contractorPayStubPreviewData: payload,
      };
    },
    GET_CONTRACTORS_PAYSTUB_PREVIEW_DATA_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CONTRACTORS_PAY_STUBS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CONTRACTORS_PAY_STUBS_DOWNLOAD_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        payStubs: payload,
      };
    },
    GET_CONTRACTORS_PAY_STUBS_DOWNLOAD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_CONTRACTORS_PAY_STUBS_DOWNLOAD_ITEM_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        payStubs: payload,
      };
    },
    GET_CONTRACTORS_PAY_STUBS_DOWNLOAD_ITEM_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DELETE_CONTRACTOR_ON_ADD_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    DELETE_CONTRACTOR_ON_ADD_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    DELETE_PAYROLL_CONTRACTOR_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    DELETE_PAYROLL_CONTRACTOR_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    POST_PAYMENTS_INFORMATION_CONTRACTORS_SUCCESS: (state, payload) => {
      const updatedPayments = payload.payments.map((payment: object) => ({
        ...payment,
        isCancled: false,
      }));

      return {
        ...state,
        error: null,
        paymentsContractorsInformation: {
          ...payload,
          payments: updatedPayments,
        },
      };
    },
    POST_PAYMENTS_INFORMATION_CONTRACTORS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },

    CANCELL_PAYMENT_INFORMATION_CONTRACTOR_BY_ID_SUCCESS: (state, paymentId) => {
      const updateStatusPayments = state.paymentsContractorsInformation?.payments?.map(
        (payment: Payment) => ({
          ...payment,
          isCancled: payment.isCancled ? true : payment.id === paymentId,
        }),
      );

      return {
        ...state,
        error: null,
        paymentsContractorsInformation: {
          ...state.paymentsContractorsInformation,
          payments: updateStatusPayments,
        },
      };
    },
    CANCELL_PAYMENT_INFORMATION_CONTRACTOR_BY_ID_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    CANCELL_PAYMENT_INFORMATION_CONTRACTOR_DETAILS_BY_ID_SUCCESS: (state, paymentId) => {
      const updateStatusPayments = state.currentPaymentHistoryContractor?.payments?.map(
        (payment: Payment) => ({
          ...payment,
          status: payment.status === 2 ? 2 : payment.id === paymentId ? 2 : 1,
        }),
      );

      return {
        ...state,
        error: null,
        currentPaymentHistoryContractor: {
          ...state.currentPaymentHistoryContractor,
          payments: updateStatusPayments,
        },
      };
    },

    CANCELL_PAYMENT_CURRENT_CONTRACTOR_BY_ID_SUCCESS: (state, paymentId) => {
      const updatedPayments = state.listPayrollHistoryContractor.data.map(item => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            status: item.attributes.status === 2 ? 2 : item.attributes._id === paymentId ? 2 : 1,
          },
        };
      });

      return {
        ...state,
        error: null,
        listPayrollHistoryContractor: {
          ...state.listPayrollHistoryContractor,
          data: updatedPayments,
        },
      };
    },
  },
  effects: dispatch => {
    const { contractor } = dispatch;
    return {
      async postCreateIncompleteContractor(payload): Promise<unknown> {
        try {
          const { companyId, data: state } = payload;
          const { data } = await postCreateIncompleteContractor(companyId, state);
          contractor.POST_CREATE_INCOMPLETE_CONTRACTOR_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.POST_CREATE_INCOMPLETE_CONTRACTOR_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateContractorInformation(payload): Promise<unknown> {
        try {
          const { companyId, contractorId, data: state } = payload;
          const { data } = await updateContractorsDetails(companyId, contractorId, state);
          contractor.UPDATE_CONTRACTOR_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.UPDATE_CONTRACTOR_INFORMATION_SUCCESS(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateEmploymentInformationContractor(payload): Promise<unknown> {
        try {
          const { companyId, contractorId, data: state } = payload;
          const { data } = await putUpdateContractorsEmploymentInformation(
            companyId,
            contractorId,
            state,
          );
          contractor.UPDATE_EMPLOYMENT_INFORMATION_CONTRACTOR_SUCCESS(data);
          return payload;
        } catch (error: any) {
          contractor.UPDATE_EMPLOYMENT_INFORMATION_CONTRACTOR_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateCompensetaionInformationContractor(payload): Promise<unknown> {
        try {
          const { companyId, contractorId, data: state } = payload;
          const { data } = await putUpdateCompensationInformation(companyId, contractorId, state);
          contractor.UPDATE_COMPENSATION_INFORMATION_CONTRACTOR_SUCCESS(data);
          return payload;
        } catch (error: any) {
          contractor.UPDATE_COMPENSATION_INFORMATION_CONTRACTOR_FAILURE(error);
          callToast(
            'error',
            error.response.data.detail === 'hourlyRate: This value should be greater than 0.'
              ? 'Hourly rate should be greater than 0'
              : error.response.data.detail,
          );
        }
      },
      async putEmploymentInformationContractor(payload): Promise<unknown> {
        try {
          const { companyId, contractorId, data: state } = payload;
          const { data } = await putUpdateContractorsEmploymentInformation(
            companyId,
            contractorId,
            state,
          );
          contractor.PUT_EMPLOYMENT_INFORMATION_CONTRACTOR_SUCCESS(data);
          return payload;
        } catch (error: any) {
          contractor.PUT_EMPLOYMENT_INFORMATION_CONTRACTOR_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
          // callToast('error', 'Address is not correct');
        }
      },
      async putCompensetaionInformationContractor(payload): Promise<unknown> {
        try {
          const { companyId, contractorId, data: state } = payload;
          const { data } = await putUpdateCompensationInformation(companyId, contractorId, state);
          contractor.PUT_COMPENSATION_INFORMATION_CONTRACTOR_SUCCESS(data);
          await dispatch.members.getTeamMembersList(companyId);
          return payload;
        } catch (error: any) {
          contractor.PUT_COMPENSATION_INFORMATION_CONTRACTOR_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getContractorDetailsById(payload): Promise<currentContractorInformation | undefined> {
        try {
          const { companyId, contractorId, isReturnResponse } = payload;
          const { data } = await getContractorsDetails(companyId, contractorId);
          contractor.GET_CONTRACTOR_DETAILS_BY_ID_SUCCESS(data);
          return isReturnResponse ? data : payload;
        } catch (error: any) {
          contractor.GET_CONTRACTOR_DETAILS_BY_ID_FAILURE(error);
          if (error.response.data.detail) {
            callToast('error', error.response?.data?.detail);
          } else {
            callToast('error', JSON.stringify(error.response));
          }
          console.log(error);
        }
      },
      async getPayrollHistoryContractors(payload): Promise<unknown> {
        try {
          const { companyId, page, contractorIds, dateOfPaymentBefore, dateOfPaymentAfter } =
            payload;
          const { data } = await getListOfPayrollHistoryContractor(
            companyId,
            page,
            contractorIds,
            dateOfPaymentBefore,
            dateOfPaymentAfter,
          );
          contractor.GET_LIST_PAYROLL_HISTORY_SUCCESS(data);
          return payload;
        } catch (error: any) {
          contractor.GET_LIST_PAYROLL_HISTORY_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getPaystubsHistoryContractor(payload): Promise<unknown> {
        try {
          const { companyId, page, contractorId, dateOfPaymentBefore, dateOfPaymentAfter } =
            payload;
          const { data } = await getPayStubsHistoryContractor(
            companyId,
            page,
            contractorId,
            dateOfPaymentBefore,
            dateOfPaymentAfter,
          );
          contractor.GET_LIST_PAYROLL_HISTORY_TAB_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.GET_LIST_PAYROLL_HISTORY_TAB_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async deactivateContractorById(payload): Promise<unknown> {
        try {
          const { companyId, contractorId } = payload;
          const { data } = await deactivateContractor(companyId, contractorId);
          contractor.DEACTIVATE_CONTRACTOR_SUCCESS(data);
          dispatch.members.getTeamMembersList(companyId);
          return payload;
        } catch (error: any) {
          contractor.DEACTIVATE_CONTRACTOR_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async activateContractorById(payload): Promise<unknown> {
        try {
          const { companyId, contractorId } = payload;
          const { data } = await activateContractor(companyId, contractorId);
          contractor.ACTIVATE_CONTRACTOR_SUCCESS(data);
          dispatch.members.getTeamMembersList(companyId);
          return payload;
        } catch (error: any) {
          contractor.ACTIVATE_CONTRACTOR_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getActiveContractors(payload): Promise<unknown> {
        try {
          const { companyId } = payload;
          const { data } = await getListOfActiveContractors(companyId);
          contractor.GET_ACTIVE_CONTRACTORS_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.GET_ACTIVE_CONTACTORS_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getDetailsPaymentHistoryById(payload): Promise<unknown> {
        try {
          const { companyId, paymentHistoryId } = payload;
          const { data } = await getContractorPaymentHistoryDetails(companyId, paymentHistoryId);
          contractor.GET_CURRENT_PAYMENT_INFORMATION_CURRENT_CONTRACTOR_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.GET_CURRENT_PAYMENT_INFORMATION_CURRENT_CONTRACTOR_SUCCESS(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async postPaymentContractorsInformation(payload): Promise<unknown> {
        try {
          const { companyId, state } = payload;
          const { data } = await postPaymentContractors(companyId, state);
          contractor.POST_PAYMENTS_INFORMATION_CONTRACTORS_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.POST_PAYMENTS_INFORMATION_CONTRACTORS_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async confirmPaymentContactors(payload): Promise<unknown> {
        try {
          const { companyId, contractorPaymentId } = payload;
          const { data } = await confirmPaymentsContractorsById(companyId, contractorPaymentId);
          contractor.POST_PAYMENTS_INFORMATION_CONTRACTORS_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.POST_PAYMENTS_INFORMATION_CONTRACTORS_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async cancelPaymentsContracorsInformation(payload): Promise<unknown> {
        try {
          const { companyId, paymentId, contractorId } = payload;
          const { data } = await cancelContartorsPayment(companyId, contractorId, paymentId);
          contractor.CANCELL_PAYMENT_INFORMATION_CONTRACTOR_BY_ID_SUCCESS(paymentId);
          return data;
        } catch (error: any) {
          contractor.CANCELL_PAYMENT_INFORMATION_CONTRACTOR_BY_ID_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async cancelPaymentsContracorsInformationDetails(payload): Promise<unknown> {
        try {
          const { companyId, paymentId, contractorId } = payload;
          const { data } = await cancelContartorsPayment(companyId, contractorId, paymentId);
          contractor.CANCELL_PAYMENT_INFORMATION_CONTRACTOR_DETAILS_BY_ID_SUCCESS(paymentId);
          return data;
        } catch (error: any) {
          contractor.CANCELL_PAYMENT_INFORMATION_CONTRACTOR_BY_ID_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async cancelPaymentCurrentContractor(payload): Promise<unknown> {
        try {
          const { companyId, paymentId, contractorId } = payload;
          const { data } = await cancelContartorsPayment(companyId, contractorId, paymentId);
          contractor.CANCELL_PAYMENT_CURRENT_CONTRACTOR_BY_ID_SUCCESS(paymentId);
          return data;
        } catch (error: any) {
          contractor.CANCELL_PAYMENT_INFORMATION_CONTRACTOR_BY_ID_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async getContractorsPayStubs(payload): Promise<unknown> {
        try {
          const { companyId, id } = payload;
          const { data } = await getContractorsPayStubs(companyId, id);
          contractor.GET_CONTRACTORS_PAY_STUBS_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.GET_CONTRACTORS_PAY_STUBS_FAILURE(error);
          callToast('error', error);
        }
      },
      async getContractorPayStubs(payload): Promise<unknown> {
        try {
          const { companyId, contractorId, id } = payload;
          const { data } = await getContractorPayStubs(companyId, contractorId, id);
          contractor.GET_CONTRACTORS_PAY_STUBS_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.GET_CONTRACTORS_PAY_STUBS_FAILURE(error);
          callToast('error', error);
        }
      },

      // Preview contractor paystub as PDF
      async previewContractorPayStub(payload): Promise<unknown> {
        try {
          const { companyId, contractorId, paymentId } = payload;
          const { data } = await previewContractorPayStub(companyId, contractorId, paymentId);
          contractor.GET_CONTRACTORS_PAYSTUB_PREVIEW_DATA_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.GET_CONTRACTORS_PAYSTUB_PREVIEW_DATA_FAILURE(error);
          callToast('error', error);
        }
      },

      // Share Contractor Payroll
      async shareContractorPayroll({ companyId, body }): Promise<unknown> {
        try {
          const { status } = await shareContractorPayrollEndpoint({ companyId, body });
          return status;
        } catch (error: any) {
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      //Download all paystubs
      async getContractorPayStubsDownload(payload): Promise<unknown> {
        try {
          const { companyId, id } = payload;
          const { data } = await getContractorPayStubsDownload(companyId, id);
          contractor.GET_CONTRACTORS_PAY_STUBS_DOWNLOAD_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.GET_CONTRACTORS_PAY_STUBS_DOWNLOAD_FAILURE(error);
          callToast('error', error);
        }
      },
      //Download one paystub
      async getContractorPayStubsDownloadItem(payload): Promise<unknown> {
        try {
          const { companyId, contractorId, id } = payload;
          const { data } = await getContractorPayStubsDownloadItem(companyId, contractorId, id);
          contractor.GET_CONTRACTORS_PAY_STUBS_DOWNLOAD_ITEM_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.GET_CONTRACTORS_PAY_STUBS_DOWNLOAD_ITEM_FAILURE(error);
          callToast('error', error);
        }
      },
      async deleteContractor(payload): Promise<unknown> {
        try {
          const { companyId, currentContractorId } = payload;
          const { data } = await deleteContractorOnAdd(companyId, currentContractorId);
          contractor.DELETE_CONTRACTOR_ON_ADD_SUCCESS(data);
          dispatch.members.getTeamMembersList(companyId);
          return data;
        } catch (error: any) {
          contractor.DELETE_CONTRACTOR_ON_ADD_FAILURE(error);
          callToast('error', error?.response?.data?.detail);
        }
      },
      async deletePayroll(payload): Promise<unknown> {
        try {
          const { companyId, id } = payload;
          const { data } = await deletePayrollContractorById(companyId, id);
          contractor.DELETE_PAYROLL_CONTRACTOR_SUCCESS(data);
          return data;
        } catch (error: any) {
          contractor.DELETE_PAYROLL_CONTRACTOR_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
