import axios from '../axios';

export const shareEmployeePayrollEndpoint = ({
  companyId,
  body,
}: {
  companyId: string;
  body: [];
}) => axios.post(`/company/${companyId}/employee-paystubs/share`, body);

export const shareContractorPayrollEndpoint = ({
  companyId,
  body,
}: {
  companyId: string;
  body: [];
}) => axios.post(`/company/${companyId}/contractor-paystubs/share`, body);
