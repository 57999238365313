import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TabType } from '@/components/ui/TabsComponent/types';
import useTabs from '@/components/ui/TabsComponent/useTabs';
import { Dispatch, RootState } from '@/redux/store';

import * as routes from '@/routes/routes';
import { useLocation } from 'react-router-dom';
import { AccountBasicInfo, BillingCenter } from './components';
import { MY_ACCOUNT_TAB_IDS } from './utils/constants';

const useMyAccount = () => {
  const dispatch = useDispatch<Dispatch>();
  const location = useLocation();
  const pathToCheck = routes.MY_ACCOUNT_BILLING_CENTER_MANAGE;

  const isManageBillingCenter = location.pathname.includes(pathToCheck);

  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState<TabType[]>([]);

  const tabController = useTabs({
    tabs,
  });

  const { setCurrentTab } = tabController;

  const initialTabSetup: TabType[] = useMemo(
    () => [
      {
        id: MY_ACCOUNT_TAB_IDS.myProfile,
        title: 'My Profile',
        disabled: false,
        component: AccountBasicInfo,
        props: {},
        isCompleted: false,
      },
      {
        id: MY_ACCOUNT_TAB_IDS.accountBilling,
        title: 'Billing center',
        disabled: false,
        component: BillingCenter,
        props: {
          isManageBillingCenter,
        },
        isCompleted: false,
      },
    ],
    [],
  );

  useEffect(() => {
    setTabs(initialTabSetup);
  }, [initialTabSetup]);

  const subscriptionInformation = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const getSubscriptionStatus = async () => {
    await dispatch.auth.getSubscriptionStatus();
    setIsLoading(false);
  };

  useEffect(() => {
    if (subscriptionInformation !== null) {
      getSubscriptionStatus();
    } else {
      setIsLoading(true);
      getSubscriptionStatus();
    }
  }, []);

  const fetchSubscriptionPlan = async () => {
    await dispatch.auth.getSubscriptionPlan();
  };

  useEffect(() => {
    if (isManageBillingCenter) {
      setCurrentTab(MY_ACCOUNT_TAB_IDS.accountBilling);
    }
    fetchSubscriptionPlan();
  }, []);

  return {
    subscriptionInformation,
    isLoading,
    tabController,
  };
};

export default useMyAccount;
