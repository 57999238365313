export default {
  TAX_FORMS: {
    CONTRACTOR_1099S: {
      FAQ: {
        LINK: 'FAQ: About the 1099-NEC form',
        HEADER_1: '',
      },
      TIP: 'The deadline for filing your 1099-NEC form is on Jan 31, 2024. Make sure all contractor details are accurate on their 1099 forms before saving and approving.',
    },
  },
};
