import { twMerge } from 'tailwind-merge';

import AccountSetupSupport from '../AccountSetupSupport/AccountSetupSupport';
import ContentBlockInfoBox from '../ContentBlockInfoBox/ContentBlockInfoBox';
import { AccountSetupInfoCardsProps } from './types';

const AccountSetupInfoCards = ({
  wrapperClasses,
  identifier,
  isTeamMemberRouts,
}: AccountSetupInfoCardsProps) => {
  return (
    <div
      className={twMerge(`w-full xl:w-[335px]`, isTeamMemberRouts && 'xl:w-full', wrapperClasses)}
    >
      <div
        className={twMerge(
          `flex items-center md:right-0 md:top-[88px]`,
          !isTeamMemberRouts && 'md:sticky ',
        )}
      >
        <div className="flex w-full flex-col gap-4 overflow-hidden ">
          {!!identifier && <ContentBlockInfoBox identifier={identifier} />}
          <div className="hidden w-full justify-center rounded-md border border-darkblue-100 bg-white px-5 py-[30px] sm:px-[30px] lg:flex">
            <div className="w-full max-w-[335px]">
              <AccountSetupSupport />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupInfoCards;
