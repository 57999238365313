import TagManager from 'react-gtm-module';

import { store } from '@/redux/store';

export const trackEvent = (eventName: string, eventData: object) => {
  const uuid = store.getState().auth?.user?.account?.uuid;

  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventData,
      userId: uuid,
    },
  });
};
