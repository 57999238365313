import { EditPasswordSchema } from '@/utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const useEditPasswordDrawer = ({ handlePasswordChange: updatePasswordChange, onClose }: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFormHasUnsavedChanges, setIsFormHasUnsavedChanges] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const form = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(EditPasswordSchema),
  });

  const handlePasswordChange = (data: any) => {
    updatePasswordChange(data);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { isDirty } = form.formState;

  useEffect(() => {
    if (isFormHasUnsavedChanges !== isDirty) {
      setIsFormHasUnsavedChanges(isDirty);
    }
  }, [isDirty, isFormHasUnsavedChanges]);

  const drawerCloseHandler = () => {
    if (isFormHasUnsavedChanges) {
      setIsConfirmationModalOpen(true);
    } else {
      onClose();
    }
  };

  const confirmUnsavedChangesHandler = () => {
    setIsConfirmationModalOpen(false);
    onClose();
  };

  const cancelUnsavedChangesHandler = () => {
    setIsConfirmationModalOpen(false);
  };

  return {
    form,
    isDirty,
    handlePasswordChange,
    handleShowPassword,
    showPassword,
    drawerCloseHandler,
    confirmUnsavedChangesHandler,
    cancelUnsavedChangesHandler,
    isConfirmationModalOpen,
  };
};
export default useEditPasswordDrawer;
