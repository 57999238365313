import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetTeamMembers } from '@/api';
import { useGetSubscriptionPlans } from '@/api/services/apis/subscriptionPlans/useGetSubscriptionPlans';
import { RootState } from '@/redux/store';
import { FREE_3_MONTH_TRIAL_SUBSCRIPTION } from '@/utils/constants';

import { SubscriptionPlanType } from './types';
import { UPGRADE_PLAN_STEPPER_STEP_IDS } from './utils/constants';

const useUpgradeSubscriptionPlan = () => {
  const companyId = useSelector((state: RootState) => state.auth?.user?.companyId);
  const numberOfContractors = useSelector(
    (state: RootState) => state.auth?.businessOnboardingInfo?.numberOfContractors,
  );
  const numberOfEmployees = useSelector(
    (state: RootState) => state.auth?.businessOnboardingInfo?.numberOfEmployees,
  );
  const numberOfTeamMembers = numberOfContractors + numberOfEmployees ?? 0;
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [plans, setPlans] = useState<SubscriptionPlanType[]>([]);
  const [recommendedPlan, setRecommendedPlan] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(UPGRADE_PLAN_STEPPER_STEP_IDS.selectPlanStep);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isMobileSize, setIsMobileSize] = useState<boolean>(false);

  const checkScreenWidth: () => void = () => {
    setIsMobileSize(window.innerWidth <= 1024);
  };

  useEffect(() => {
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const { data, isLoading: isLoadingSubscriptionPlans } = useGetSubscriptionPlans({});

  const { data: membersList, isLoading: isLoadingTeamMembers } = useGetTeamMembers(companyId);

  const employeeCount = membersList?.filter(member => member.status === 'active')?.length;

  useEffect(() => {
    setPlans(
      Array.isArray(data?.availableProducts)
        ? data.availableProducts
            .filter(plans => plans.name !== FREE_3_MONTH_TRIAL_SUBSCRIPTION)
            .map(plan => {
              return {
                ...plan,
                price:
                  plan.availablePrices.find(pr => pr.pricingStrategy === 'monthly')?.amount ?? 0,
                isDisabled: plan.metadata?.maxSeats < employeeCount ?? numberOfTeamMembers,
              };
            })
            .sort((a, b) => a.price - b.price)
        : [],
    );
  }, [data, employeeCount]);

  useEffect(() => {
    const sortedPlans = [...plans].sort((a, b) => a.metadata?.maxSeats - b.metadata?.maxSeats);
    const suitablePlan = sortedPlans.find(
      plan => plan.metadata?.maxSeats >= employeeCount ?? numberOfTeamMembers,
    );
    if (suitablePlan && !recommendedPlan) {
      setRecommendedPlan(suitablePlan.name);
    }
    if (suitablePlan && !selectedPlan) {
      setSelectedPlan(suitablePlan.name);
    }
  }, [plans, selectedPlan, recommendedPlan, employeeCount]);

  const handlePlanChange = (plan: string) => {
    setSelectedPlan(plan);
  };

  const confirmPlanHandler = () => {
    if (selectedPlan) {
      setCurrentStep(UPGRADE_PLAN_STEPPER_STEP_IDS.paymentStep);
      if (isMobileSize) {
        setIsPaymentModalOpen(true);
      } else {
        setIsPaymentModalOpen(false);
      }
    }
  };

  const closePaymentModalHandler = () => {
    setIsPaymentModalOpen(false);
  };

  return {
    isLoadingSubscriptionPlans: isLoadingSubscriptionPlans || isLoadingTeamMembers,
    plans,
    selectedPlan,
    handlePlanChange,
    recommendedPlan,
    confirmPlanHandler,
    isPaymentModalOpen,
    closePaymentModalHandler,
    isMobileSize,
  };
};

export default useUpgradeSubscriptionPlan;
