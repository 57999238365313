import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useRef } from 'react';
import { Tab, TabList } from 'react-tabs';
import { twMerge } from 'tailwind-merge';

import { CustomTabTitlesProps } from '../../types';

const AccountSetupTeamMember = ({
  tabs,
  currentTabIndex,
  handleTabClick,
  tabTitlesComponentProps,
}: CustomTabTitlesProps) => {
  const tabListContainerRef = useRef(null);
  const shouldHideCompletedCheckIcon = !!tabTitlesComponentProps?.shouldHideCompletedCheckIcon;

  useEffect(() => {
    const centerCurrentTab = () => {
      const tabListContainer = tabListContainerRef.current as unknown as HTMLElement;
      if (!tabListContainer) return;
      const activeTabElement = tabListContainer.querySelector(
        `[role="tab"]:nth-child(${currentTabIndex + 1})`,
      ) as unknown as HTMLElement;

      if (!activeTabElement) return;

      activeTabElement.scrollIntoView({
        behavior: 'smooth',
      });
    };

    centerCurrentTab();
  }, [tabs, currentTabIndex]);

  return (
    <div>
      <div>
        <div ref={tabListContainerRef} className="scrollbar-hide overflow-auto border-b-2">
          <TabList className="flex px-5 sm:gap-2 sm:px-6 xl:gap-4">
            {tabs.map((tab, tabIndex) => {
              const isStepCompleted = tab.isCompleted ?? tabIndex < currentTabIndex;
              return (
                <Tab
                  key={tab.id}
                  onClick={() => {
                    handleTabClick(tab, tabIndex);
                  }}
                  className={twMerge(
                    `flex cursor-pointer  justify-start gap-1 whitespace-nowrap p-2 focus:outline-none `,
                    currentTabIndex === tabIndex
                      ? 'border-b-2 border-b-emerald-500 text-emerald-500'
                      : isStepCompleted
                      ? 'text-gray-400'
                      : 'text-gray-200',
                    !!tab.hidden && 'hidden',
                  )}
                  id={tab.id}
                >
                  {tab.title}{' '}
                  {isStepCompleted && !shouldHideCompletedCheckIcon && (
                    <CheckCircleIcon className="w-5 text-emerald-500" />
                  )}
                </Tab>
              );
            })}
          </TabList>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupTeamMember;
