import { PopupButton, useCalendlyEventListener } from 'react-calendly';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '@/assets/logo.svg';
import Button from '@/components/Button/Button';
import * as routes from '@/routes/routes';
import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import InfoStepCard from './InfoStepCard/InfoStepCard';
import { GetStartType } from './type';

const GetStart = ({ firstName }: GetStartType) => {
  const navigate = useNavigate();
  useCalendlyEventListener({
    onEventTypeViewed: () => {
      trackEvent('buttonClick', {
        name: 'Schedule a call',
        currentPage: extractEventName(window.location.pathname),
      });
    },
    onEventScheduled: () => {
      trackEvent('buttonClick', {
        name: 'Calendly: Call Scheduled',
        currentPage: extractEventName(window.location.pathname),
      });
    },
  });

  return (
    <div className="mt-10 w-full px-5 py-12 sm:px-20  lg:pt-[107px]">
      <div className="mx-auto w-full max-w-[600px]">
        <h3 className="text-[28px] sm:text-[34px]">
          Happy to have you with us, <span className="capitalize">{firstName ?? ''}</span> 👋
        </h3>
        <h6 className="pt-4 text-xl text-gray-400">How would you like to get started?</h6>
        <InfoStepCard
          title="Assisted onboarding"
          description={
            <div>
              <h6 className="pb-4 text-darkblue-200">
                Our payroll specialist will help you with the set up step-by-step .
              </h6>
            </div>
          }
          subCardTitle="Jayson"
          subCardDescription={
            <div className="justify-between">
              <h6 className="pb-4 text-sm text-darkblue-300">Payroll Specialist</h6>
              <Logo className="w-[85px] fill-white" />
            </div>
          }
          actionLabel="Schedule a call"
          actionButton={
            <div className="mt-6 flex justify-between gap-2 sm:hidden">
              <a
                href={'tel:+18887781009'}
                className="flex h-[48px] items-center justify-center rounded-[8px] bg-emerald-500 px-6 text-white sm:hidden"
              >
                Call now
              </a>
              <PopupButton
                url="https://calendly.com/d/3xy-ywd-knc/chat-with-paystubs-com-team"
                rootElement={document.getElementById('root')}
                text="Schedule a call"
                className="h-[48px] w-auto rounded-[8px] border border-gray-200 px-6 text-white"
              />
            </div>
          }
          actionEventButton={
            <PopupButton
              url="https://calendly.com/d/3xy-ywd-knc/chat-with-paystubs-com-team"
              rootElement={document.getElementById('root')}
              text="Schedule a call"
              className="hidden h-[48px] w-full rounded-[8px] bg-emerald-500 px-6 text-white sm:block sm:w-fit"
            />
          }
        />
        <InfoStepCard
          cardContainerClasses="bg-light-brown text-black border border-gray-100"
          subCardClasses="bg-white"
          title="Setup payroll myself"
          description={
            <h6 className="pb-4 text-gray-400">
              Set up your company, employees, payroll schedule, and tax details.
            </h6>
          }
          subCardDescription={
            <div className="justify-between">
              <h6 className="text-gray-400 sm:py-4">
                “Don’t worry, I’m always available to help you at any step!”
              </h6>
            </div>
          }
          actionLabel="Get started"
          actionEventButton={
            <Button
              parentClasses="w-fit px-6 hidden sm:block"
              variant="primary"
              onPress={() => navigate(routes.ACCOUNT_SETUP_COMPANY)}
            >
              Get Started
            </Button>
          }
          actionButton={
            <Button
              parentClasses="w-auto px-6 sm:hidden block mt-6"
              variant="primary"
              onPress={() => navigate(routes.ACCOUNT_SETUP_COMPANY)}
            >
              Get Started
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default GetStart;
