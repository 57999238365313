import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import {
  Header,
  LoaderComponent,
  SideBarDesk as SideBar,
  SubscriptionReminderModal,
} from '@/components';
import { useAccountInfo, useBillingInfo } from '@/hooks';
import { RootState } from '@/redux/store';
import * as routes from '@/routes/routes';
import { checkAccountForSubscriptionReminder } from '@/utils/helpers';

const DashBoardLayout = () => {
  const location = useLocation();
  const [isShowingDayReminderPopup, setIsShowingDayReminderPopup] = useState<boolean>(false);

  // These routes have new design with white background
  const updatedDesignRoutes = [
    routes.DASHBOARD,
    routes.TAX_LIABILITY,
    routes.TAX_LIABILITY_REPORT.replace(':scheduleType', ''),
    routes.COMPANY,
    routes.RUN_PAYROLL,
    routes.PAY_CONTRACTORS,
    routes.COMPANY,
    routes.PAYROLL_SETTINGS,
  ];

  const { isLoading: isLoadingAuth0 } = useAuth0();
  const { isAccountLoading } = useAccountInfo();
  const { isBillingInfoLoading } = useBillingInfo();

  const isLoading = isAccountLoading || isBillingInfoLoading || isLoadingAuth0;

  const subscriptionInfo = useSelector((state: RootState) => state.auth.subscriptionInfo);
  const bannerInfo = checkAccountForSubscriptionReminder(subscriptionInfo);
  const { remainingDays, isFreeTrailOver, isShowingPayBillModal } = bannerInfo || {};

  useEffect(() => {
    const todayDate = moment().format('MM-DD-YY');
    const isAlreadyShowedPopup = sessionStorage.getItem(`subscriptionReminder-${todayDate}`);
    if (isAlreadyShowedPopup) return;
    if (isShowingPayBillModal) {
      setIsShowingDayReminderPopup(true);
    } else {
      setIsShowingDayReminderPopup(false);
    }
  }, [isShowingPayBillModal]);

  const toggleReminderPopup = () => {
    setIsShowingDayReminderPopup(!isShowingDayReminderPopup);
  };
  return (
    <div className="flex h-screen flex-col overflow-auto">
      <div className="sticky top-0 z-[45]">
        <Header showHelp showProfile showMobileMenuIcon />
      </div>
      <div className={`flex flex-1`}>
        <SideBar />
        <div className="flex w-full flex-1 flex-col">
          <main
            className={`relative flex w-full flex-1 flex-col lg:left-[270px] lg:max-w-[calc(100%-270px)] ${
              updatedDesignRoutes.some(item => location.pathname.includes(item))
                ? 'bg-white'
                : 'bg-light-brown'
            }`}
          >
            {isLoading ? <LoaderComponent /> : <Outlet />}
          </main>
        </div>
      </div>

      {isShowingDayReminderPopup && (
        <SubscriptionReminderModal
          isOpen={isShowingDayReminderPopup}
          onCancel={toggleReminderPopup}
          remainingDays={remainingDays}
          isFreeTrailOver={isFreeTrailOver}
        />
      )}
    </div>
  );
};
export default DashBoardLayout;
