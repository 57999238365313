import axios from '../axios';

interface PayrollShedulePaylaod {
  payFrequency: number;
  firstPayDate: string;
  payPeriodStartDate: string;
  payPeriodEndDate: string;
}

//Get Payroll Settings
export const getSchedule = (companyId: string) => {
  return axios.get(`/company/${companyId}/payroll-settings`);
};

// This will display only the consecutive payroll dates calculated after entering the data.

export const previewSchedule = (companyId: string, payload: PayrollShedulePaylaod) => {
  return axios.post(`/company/${companyId}/payroll-schedule/preview-schedule`, payload);
};

// Submit the Payroll schedule for the year

export const submitSchedule = (companyId: string, payload: PayrollShedulePaylaod) => {
  return axios.post(`/company/${companyId}/payroll-schedule/submit-schedule`, payload);
};
