import { CreditCardIcon } from '@heroicons/react/24/outline';

import Button from '@/components/Button/Button';
import { SubscriptionAgreement } from '@/redux/dto/auth';

import SubscriptionPlan from '../../../UpgradeSubscriptionPlan/SubscriptionPlan/SubscriptionPlan';
import { SubscriptionPlanType } from '../../../UpgradeSubscriptionPlan/types';

type SubscriptionPlanGroupProps = {
  plans: SubscriptionPlanType[];
  selectedPlan: string | null;
  recommendedPlan: string | null;
  onSelectSubscriptionPlan: (plan: string) => void;
  onConfirmPlan: () => void;
  isPending: boolean;
  openCancelModalHandler: () => void;
  activeSubscriptionAgreement: SubscriptionAgreement;
  onCancel: () => void;
};

const SubscriptionPlanGroup = ({
  plans,
  selectedPlan,
  onSelectSubscriptionPlan,
  recommendedPlan,
  onConfirmPlan,
  isPending,
  openCancelModalHandler,
  activeSubscriptionAgreement,
  onCancel,
}: SubscriptionPlanGroupProps) => {
  return (
    <div className="flex h-full flex-col justify-center gap-5">
      <div className="flex flex-col items-center">
        <div className="rounded-full bg-emerald-50 p-2">
          <div className="rounded-[28px] bg-emerald-100 p-2">
            <CreditCardIcon className="size-6 rounded-[28px] text-emerald-500" />
          </div>
        </div>
        <span className="mt-4 font-F37Bolton-Medium text-[24px]">Manage your subscription</span>
        <span className="mt-2 w-[312px] text-center text-gray-400 md:w-[384px]">
          Take control of your plan. Upgrade, downgrade, or make changes to fit your needs.
        </span>
      </div>
      <div className="flex flex-col gap-3">
        {plans.map(plan => (
          <SubscriptionPlan
            key={plan.name}
            {...plan}
            isSelected={plan.name === selectedPlan}
            isRecommended={recommendedPlan === plan.name}
            amount={plan.price}
            planName={plan.name}
            maxEmployee={plan.metadata?.maxSeats ?? 0}
            pricingStrategy={'monthly'}
            onSelectSubscriptionPlan={() => !plan.isDisabled && onSelectSubscriptionPlan(plan.name)}
          />
        ))}
      </div>
      {activeSubscriptionAgreement && (
        <Button
          variant="tertiary"
          children="Cancel subscription"
          width="w-full"
          onPress={openCancelModalHandler}
        />
      )}
      <div className="flex gap-3">
        <Button variant="tertiary" parentClasses="w-full py-2.5" height="h-auto" onPress={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          parentClasses="w-full py-2.5"
          height="h-auto"
          onPress={() => onConfirmPlan()}
          isLoading={isPending}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlanGroup;
