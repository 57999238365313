import { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';
import { LoaderComponent } from '@/components/ui';

import SubscriptionPaymentContainer from '../SubscriptionPaymentContainer/SubscriptionPaymentContainer';
import SubscriptionPlanGroup from './SubscriptionPlanGroup/SubscriptionPlanGroup';
import useUpgradeSubscriptionPlan from './useUpgradeSubscriptionPlan';

type UpgradeSubscriptionPlanProps = {
  paymentSuccessHandler: (paymentData: { amount: number; planName: string }) => void;
  choosePlanTitle: string;
  subTitle?: ReactNode;
  onCancel: () => void;
  subTitleStyle?: string;
};

const UpgradeSubscriptionPlan = ({
  paymentSuccessHandler,
  choosePlanTitle,
  subTitle,
  onCancel,
  subTitleStyle,
}: UpgradeSubscriptionPlanProps) => {
  const {
    isLoadingSubscriptionPlans,
    plans,
    selectedPlan,
    handlePlanChange,
    recommendedPlan,
    confirmPlanHandler,
    isPaymentModalOpen,
    closePaymentModalHandler,
    isMobileSize,
  } = useUpgradeSubscriptionPlan();

  return (
    <div className="w-full">
      {isLoadingSubscriptionPlans ? (
        <LoaderComponent />
      ) : (
        <div className="flex gap-2 divide-x">
          <div className="w-full lg:pr-7">
            <SubscriptionPlanGroup
              plans={plans}
              onSelectSubscriptionPlan={handlePlanChange}
              selectedPlan={selectedPlan}
              recommendedPlan={recommendedPlan}
              confirmPlanHandler={confirmPlanHandler}
              choosePlanTitle={choosePlanTitle}
              subTitle={subTitle}
              subTitleStyle={subTitleStyle}
              onCancel={onCancel}
            />
          </div>
          {!!plans.length && !isMobileSize && (
            <>
              <div className="hidden w-full max-w-[420px] lg:block">
                <SubscriptionPaymentContainer
                  plans={plans}
                  selectedPlan={selectedPlan}
                  paymentSuccessHandler={paymentSuccessHandler}
                />
              </div>
            </>
          )}
        </div>
      )}

      {isPaymentModalOpen && isMobileSize && (
        <DialogComponent
          open={isPaymentModalOpen}
          onCancel={closePaymentModalHandler}
          height="h-auto"
          width="w-[95vw] sm:w-[480px]"
          modalClasses={`sm:p-5 rounded-lg`}
        >
          <div className="sm:px-6">
            <div className="absolute right-2 top-2 flex justify-end pr-[16px] ">
              <Button variant="icon" icon={CloseIcon} onPress={closePaymentModalHandler} />
            </div>
            <div className="mx-auto max-w-[420px] pt-12">
              <SubscriptionPaymentContainer
                plans={plans}
                selectedPlan={selectedPlan}
                paymentSuccessHandler={paymentSuccessHandler}
              />
            </div>
          </div>
        </DialogComponent>
      )}
    </div>
  );
};

export default UpgradeSubscriptionPlan;
