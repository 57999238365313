import { putCall } from '@/api/axios';
import useQueryHandlers from '@/hooks/useQueryHandlers/useQueryHandlers';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { mutationEndpoints } from './endpoints';

const usePutUpgradePlan = ({ mutationParams }: { mutationParams?: UseMutationOptions }) => {
  const { url, invalidateKeys, mutationKey } = mutationEndpoints.upgradePlan();

  const { onSuccess } = useQueryHandlers({
    mutationParams,
    invalidateKeys,
  });

  return useMutation({
    mutationKey,
    mutationFn: payload => putCall(url, payload),
    ...mutationParams,
    onSuccess,
  });
};

export default usePutUpgradePlan;
