import { createModel } from '@rematch/core';

import { callToast } from '@/utils/helpers';
import { getIndustries } from '@/api/services/industries';

import type { RootModel } from '.';

type membersState = {
  erorr: unknown;
  industries: string[];
};

export const industry = createModel<RootModel>()({
  state: {
    error: null,
    industries: [],
  } as unknown as membersState,
  reducers: {
    GET_INDUSTRIES_SUCCESS: (state, payload) => ({
      ...state,
      industries: payload.industry,
    }),
    GET_INDUSTRIES_FAILURE: (state, payload) => ({
      ...state,
      error: payload,
    }),
  },
  effects: dispatch => {
    const { industry } = dispatch;
    return {
      async getIndustriesList(): Promise<unknown> {
        try {
          const { data } = await getIndustries();
          industry.GET_INDUSTRIES_SUCCESS(data);
          return data;
        } catch (error: any) {
          industry.GET_INDUSTRIES_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
