import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import DialogComponent from '@/components/Dialog/DialogComponent';

import { ConfirmationActionModalType } from './types';

const ConfirmationActionModal = ({
  imageSrc,
  title,
  description,
  onCancel,
  isOpen,
  imageClass = '',
  actions,
  width,
  height,
  dialogClasses,
  modalClasses,
  showCloseIcon = true,
}: ConfirmationActionModalType) => {
  return (
    <DialogComponent
      open={isOpen}
      onCancel={onCancel}
      children={
        <div className="flex h-full flex-col">
          {showCloseIcon && (
            <div className="absolute right-2 top-2 flex justify-end pr-[16px] ">
              <Button variant="icon" icon={CloseIcon} onPress={onCancel} />
            </div>
          )}
          <div className="">
            {!!imageSrc && (
              <div className="mb-4 flex justify-center">
                <img src={imageSrc} className={`${imageClass ? imageClass : ''}`} alt={title} />
              </div>
            )}
            <div>
              <span className="block text-center font-F37Bolton-Medium text-[22px]">{title}</span>
              <span className="mt-3 block text-center font-F37Bolton-Regular text-lg text-gray-400">
                {description}
              </span>
            </div>
            <div className="mt-4">{actions}</div>
          </div>
        </div>
      }
      width={`w-[95vw] sm:w-[480px] ${width ? width : ''}`}
      height={`h-auto ${height ? height : ''}`}
      dialogClasses={`z-[200] ${dialogClasses ? dialogClasses : ''}`}
      modalClasses={`px-[16px] py-12 sm:px-[68px] rounded-[8px] relative ${
        modalClasses ? modalClasses : ''
      }`}
    />
  );
};

export default ConfirmationActionModal;
