import { createModel } from '@rematch/core';

import { callToast } from '@/utils/helpers';
import { getTeamMembers } from '@/api/services/teamMembers';
import { TeamMember } from '@/redux/dto/members';

import type { RootModel } from '.';

type membersState = {
  erorr: unknown;
  teamMembers: TeamMember[];
};

export const members = createModel<RootModel>()({
  state: {
    error: null,
    teamMembers: [],
  } as unknown as membersState,
  reducers: {
    GET_TEAM_MEMBERS_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        teamMembers: payload.teamMembers,
      };
    },
    GET_TEAM_MEMBERS_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },
  effects: dispatch => {
    const { members } = dispatch;
    return {
      async getTeamMembersList(payload): Promise<unknown> {
        try {
          const { data } = await getTeamMembers(payload);
          members.GET_TEAM_MEMBERS_SUCCESS(data);
          return data;
        } catch (error: any) {
          members.GET_TEAM_MEMBERS_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
