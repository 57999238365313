import * as Yup from 'yup';

export const BusinessProfileValidation = Yup.object().shape({
  businessType: Yup.string().required('Selecting a legal structure is required'),
  industry: Yup.string().required('Selecting an industry is required'),
  chooseEinOrSsn: Yup.number().nullable(),
  ein: Yup.string().when('chooseEinOrSsn', {
    is: (chooseEinOrSsn: number) => chooseEinOrSsn === 1,
    then: () =>
      Yup.string()
        .required('EIN is required')
        .matches(/^\d{2}-\d{7}$/, 'EIN must be 9 characters'),
    otherwise: formSchema => formSchema.nullable().notRequired(),
  }),
  ssn: Yup.string().when('chooseEinOrSsn', {
    is: (chooseEinOrSsn: number) => chooseEinOrSsn === 2,
    then: formSchema => formSchema.required('SSN is required').min(9, 'SSN must be 9 characters'),
    otherwise: formSchema => formSchema.nullable().notRequired(),
  }),
});
