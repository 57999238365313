import { getCall } from '@/api/axios';
import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { queryEndpoints } from './endpoints';
import { UseGetFastSpringAccountType } from './types';

const useGetFastSpringAccount = ({
  queryParams,
}: {
  queryParams?: Omit<UseQueryOptions, 'queryKey'>;
}): UseQueryResult<UseGetFastSpringAccountType, Error> => {
  const { queryKey, url } = queryEndpoints.fastSpringAuthenticatedAccount();

  return useQuery<UseQueryResult<UseGetFastSpringAccountType, Error>>({
    queryKey,
    queryFn: () => getCall(url),
    select: (data: any) => data.data,
    ...queryParams,
  });
};

export default useGetFastSpringAccount;
