import { useAuth0 } from '@auth0/auth0-react';
import {
  BuildingOfficeIcon,
  CalendarDaysIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useMemo } from 'react';
import { useCalendlyEventListener } from 'react-calendly';
import { useLocation, useNavigate } from 'react-router-dom';

import { VERTICAL_STEPPER_SIDEBAR_STATUS } from '@/components/ui/VerticalStepperSidebar/constants';
import { VerticalStepperSidebarStepType } from '@/components/ui/VerticalStepperSidebar/types';
import useVerticalStepperSidebar from '@/components/ui/VerticalStepperSidebar/useVerticalStepperSidebar';
import { useAccountInfo, useBillingInfo } from '@/hooks';
import * as Routes from '@/routes/routes';
import { CONTENT_BLOCKS } from '@/utils/constants';
import {
  checkAccountForAccountSetup,
  checkAccountForSubscriptionReminder,
  extractEventName,
} from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { ONBOARDING_STEPPER_IDS, ONBOARDING_STRUCTURE } from './constants';

const { completed, current, pending } = VERTICAL_STEPPER_SIDEBAR_STATUS;

const { company, employees, payrollSettings, taxes, finishOnboarding } = ONBOARDING_STEPPER_IDS;

const { contractor } = ONBOARDING_STRUCTURE;

const isAppModeDemo = import.meta.env.APP_MODE === 'DEMO';

const getStepStatus = ({
  isStepCompleted,
  step,
  currentStep,
}: {
  isStepCompleted: boolean;
  step: string;
  currentStep: string;
}) => {
  return isStepCompleted ? completed : currentStep === step ? current : pending;
};

const useAccountSetupLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useCalendlyEventListener({
    onEventTypeViewed: () => {
      trackEvent('buttonClick', {
        name: 'Book a call',
        currentPage: extractEventName(window.location.pathname),
      });
    },
    onEventScheduled: () => {
      trackEvent('buttonClick', {
        name: 'Calendly: Call Scheduled',
        currentPage: extractEventName(window.location.pathname),
      });
    },
  });

  const { isLoading: isLoadingAuth0 } = useAuth0();
  const {
    account,
    isAccountLoading,
    isAuthenticatedAsImpersonate,
    company: companyInfo,
  } = useAccountInfo();
  const { billingInfo, isBillingInfoLoading } = useBillingInfo();

  const isLoading = isAccountLoading || isBillingInfoLoading || isLoadingAuth0;

  const bannerInfo = checkAccountForSubscriptionReminder(billingInfo);

  const location = useLocation();
  const currentPath = location.pathname;

  const {
    isCompanyStepCompleted,
    isScheduleStepCompleted,
    isTaxesStepCompleted,
    isEmployeesStepCompleted,
    isContractorStepCompleted,
    isFinishOnboardingStepCompleted,
    onBoardingStructure,
    shouldShowAccountSetup,
    isAccountSetupCompleted,
    isTeamMemberStepCompleted,
  } = checkAccountForAccountSetup({
    account,
  });

  useEffect(() => {
    if (account && companyInfo) {
      const { phoneNumber, firstName, lastName, metadata } = account;
      const { name, businessRole } = companyInfo;

      const isPersonalDetailsCompleted = !!(
        firstName &&
        lastName &&
        phoneNumber &&
        name &&
        businessRole
      );

      const isStartedFreeTrail = !!metadata?.['startedFreeTrial'];

      const isSignUpStepperCompleted = isPersonalDetailsCompleted && isStartedFreeTrail;

      if (!isSignUpStepperCompleted) {
        navigate(Routes.SIGNUP_STEPPER);
      } else if (!shouldShowAccountSetup || (shouldShowAccountSetup && isAccountSetupCompleted)) {
        navigate(Routes.DASHBOARD);
      }
    }
  }, [account, shouldShowAccountSetup, isAccountSetupCompleted, navigate, companyInfo]);

  const currentStep = useMemo(() => {
    if (!isCompanyStepCompleted) return company;

    const isOnlyContractorPlan = onBoardingStructure === contractor;

    const unfinishedSteps = [
      !isOnlyContractorPlan && !isScheduleStepCompleted && payrollSettings,
      !isOnlyContractorPlan && !isTaxesStepCompleted && taxes,
      !((isEmployeesStepCompleted && isContractorStepCompleted) || isTeamMemberStepCompleted) &&
        employees,
    ].filter(Boolean);

    return unfinishedSteps.length > 0 ? unfinishedSteps[0] : finishOnboarding;
  }, [
    isCompanyStepCompleted,
    isScheduleStepCompleted,
    isTaxesStepCompleted,
    isEmployeesStepCompleted,
    isContractorStepCompleted,
    isTeamMemberStepCompleted,
    onBoardingStructure,
  ]);

  const steps = useMemo(
    () => [
      {
        key: company,
        title: 'Company',
        icon: BuildingOfficeIcon,
        status: isCompanyStepCompleted ? completed : current,
        isHidden: false,
        contentBlock: CONTENT_BLOCKS.accountSetupCompany,
      },
      {
        key: payrollSettings,
        title: 'Payroll Settings',
        icon: CalendarDaysIcon,
        status: getStepStatus({
          isStepCompleted: isScheduleStepCompleted,
          step: payrollSettings,
          currentStep,
        }),
        isHidden: onBoardingStructure === contractor,
        contentBlock: CONTENT_BLOCKS.accountSetupPayrollSchedule,
      },
      {
        key: taxes,
        title: 'Taxes',
        icon: DocumentTextIcon,
        status: getStepStatus({ isStepCompleted: isTaxesStepCompleted, step: taxes, currentStep }),
        isHidden: onBoardingStructure === contractor,
        contentBlock: CONTENT_BLOCKS.accountSetupTaxes,
      },
      {
        key: employees,
        title: 'Employees',
        icon: UserGroupIcon,
        status: getStepStatus({
          isStepCompleted:
            (isEmployeesStepCompleted && isContractorStepCompleted) || isTeamMemberStepCompleted,
          step: employees,
          currentStep,
        }),
        isHidden: false,
        contentBlock: CONTENT_BLOCKS.accountSetupEmployees,
        additionalKeysForRoute: [contractor],
      },
      // {
      //   key: contractors,
      //   title: 'Contractors',
      //   icon: UsersIcon,
      //   status: getStepStatus({
      //     isStepCompleted: isContractorStepCompleted,
      //     step: contractors,
      //     currentStep,
      //   }),
      //   isHidden: onBoardingStructure === employee,
      //   contentBlock: CONTENT_BLOCKS.accountSetupContractors,
      // },
      {
        key: finishOnboarding,
        title: 'Review Summary',
        icon: DocumentCheckIcon,
        status: getStepStatus({
          isStepCompleted: isFinishOnboardingStepCompleted,
          step: finishOnboarding,
          currentStep,
        }),
      },
    ],
    [
      onBoardingStructure,
      isCompanyStepCompleted,
      isScheduleStepCompleted,
      isTaxesStepCompleted,
      isEmployeesStepCompleted,
      isContractorStepCompleted,
      isFinishOnboardingStepCompleted,
      currentStep,
      isTeamMemberStepCompleted,
    ],
  );

  const activeStepIdentifier = steps.find(step => currentPath?.includes(step.key))?.contentBlock;

  const handleClick = (item: VerticalStepperSidebarStepType) => {
    if (item.status !== pending) {
      navigate(`/account-setup/${item.key}`);
    }
  };

  const verticalStepper = useVerticalStepperSidebar({ steps, defaultStep: company, handleClick });

  const isTeamMemberRouts = pathname === Routes.ACCOUNT_SETUP_EMPLOYEE;
  const shouldShowWithoutCard = [
    Routes.ACCOUNT_SETUP_CURRENT_EMPLOYEE.replace(':employeeId', ''),
    Routes.ACCOUNT_SETUP_CURRENT_CONTRACTOR.replace(':contractorId', ''),
    Routes.ACCOUNT_SETUP_PAYROLL_SETTINGS,
  ].some(teamMemberPath => pathname.includes(teamMemberPath));

  const { bannerVariantToShow } = bannerInfo || {};

  // Additional height of header from the demo banner/subscription reminder banner/Impersonation Reminder banner
  const isAdditionHeaderBanner =
    isAppModeDemo || bannerInfo?.bannerVariantToShow || isAuthenticatedAsImpersonate;

  return {
    verticalStepper,
    activeStepIdentifier,
    isTeamMemberRouts,
    shouldShowWithoutCard,
    bannerVariantToShow,
    isAdditionHeaderBanner,
    isLoading,
  };
};

export default useAccountSetupLayout;
