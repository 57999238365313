import moment from 'moment';

import { ReactComponent as SubscriptionIllustration } from '@/assets/illustration/subscriptionIllustration5.svg';
import Button from '@/components/Button/Button';

type PaymentSuccessProps = {
  planName: string;
  amount: number;
  billingDate?: string;
  onConfirm: () => void;
  nextProcessingDate: string;
};

const PaymentSuccess = ({
  planName,
  amount,
  nextProcessingDate,
  onConfirm,
}: PaymentSuccessProps) => {
  const billingDate = nextProcessingDate && moment(nextProcessingDate).format('DD/MM/YYYY');
  return (
    <div className="flex flex-col items-center gap-6">
      <SubscriptionIllustration />

      <div className="flex flex-col gap-1 text-center">
        <p className="font-F37Bolton-Medium text-4xl">Your plan is now active!</p>
        <p className="text-gray-400">
          Your trial has ended, and you're now subscribed to the{' '}
          <span className="capitalize">{planName}</span> plan. You'll be charged{' '}
          <span>${amount ?? ''}</span>
          <span>{billingDate && ` on ${billingDate}.`}</span>
        </p>
      </div>

      <div className="flex w-full flex-col items-center gap-2">
        <Button
          variant="primary"
          parentClasses="flex gap-2"
          width="w-full max-w-[420px]"
          onPress={() => onConfirm()}
        >
          Got it
        </Button>
        <div className="flex flex-wrap justify-center text-sm text-gray-300">
          Have questions? Our{' '}
          <Button
            variant="text"
            width="w-auto"
            parentClasses="text-emerald-500 mx-1"
            height="h-auto"
            onPress={() => {
              onConfirm();
              window.fcWidget.open();
            }}
          >
            support team
          </Button>
          is here to help!
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
