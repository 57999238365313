import React from 'react';
import { Controller } from 'react-hook-form';

import InputTextField from '../InputTextField';
import { FormInputTextFieldProps } from './types';

const FormInputTextField: React.FC<FormInputTextFieldProps> = ({
  name,
  isRequired,
  rules,
  isDisabled,
  shouldUnregister = true,
  showErrorMessage = true,
  ...rest
}) => (
  <Controller
    name={name}
    shouldUnregister={shouldUnregister}
    disabled={Boolean(isDisabled)}
    render={({ field: { value, ...restField }, fieldState: { error, isTouched } }) => (
      <InputTextField
        errorMessage={showErrorMessage ? error && !!error && error.message : null}
        isRequired={Boolean(isRequired || rules?.required)}
        value={value ?? ''}
        isDisabled={isDisabled}
        {...rest}
        {...restField}
      />
    )}
    rules={rules}
  />
);

export default FormInputTextField;
