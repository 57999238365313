import axios from '../axios';

// Get Checkout Session URL for a product

export const getCheckoutSession = (product: string, coupon?: string) => {
  const params = coupon ? { coupon } : {};
  const url = `/stripe/checkout/${product}`;
  return axios.get(url, { params });
};

// *Paylll Section*

//Setup paypal token
export const getSetupPayPallToken = () => {
  return axios.get(`/billing/paypal/setup-token`);
};

//Create a paypal payment method
export const postCreatePaymentMethod = (approvalToken: string) => {
  return axios.post(
    `/billing/paypal/create-payment-method
  `,
    { approvalToken },
  );
};

//Verify FastSpring subscription
export const postVerifyFsSubscription = (fsSubscriptionId: string) => {
  return axios.post(`/billing/fastspring/verify-subscription`, { fsSubscriptionId });
};

//Renew subscription agreement

export const renewSubscription = (id: number) => {
  return axios.put(`/subscription-agreement/renew`, { subscriptionAgreementId: id });
};

//Create a stripe payment method
export const postCreatePaymentMethodStripe = (paymentMethodId: string, email: string) => {
  return axios.post(
    `/billing/stripe/create-payment-method
  `,
    { paymentMethodId, email },
  );
};

//Create a subscription agreement

export const postSubscruptuionAgreementCreate = (product: string, coupon: string) => {
  return axios.post(
    `/subscription-agreement/create
  `,
    { product, coupon },
  );
};

export const checkDiscount = (code: string, product: string) => {
  return axios.post(`/discount/verify`, { product, code });
};

//Set to default payment method
export const patchByIdDefaultPaymentMethod = (id: number) => {
  return axios.patch(`/billing/payment-method/${id}`, { id });
};

// Delete payment method
export const deleteByIdDefaultPaymentMethod = (id: number) => {
  return axios.delete(`/billing/payment-method/${id}`);
};

//Cancel subscription agreement

export const putSubscriptionAgreementCancel = (
  subscriptionAgreementId: number,
  cancelReason: string,
  cancelFeedback: string,
) => {
  return axios.put(`/subscription-agreement/cancel`, {
    subscriptionAgreementId: subscriptionAgreementId,
    cancelReason: cancelReason,
    cancelFeedback: cancelFeedback,
  });
};

// *Paylll Section* //

// Get Account's Subscription Status

export const getAccountSubscriptionStatus = () => {
  return axios.get(`/billing-center-info`);
};

// Get Subscription Plan list
export const getSubscriptionPlanList = () => {
  return axios.get(`/subscription/plans`);
};

// Upgrade plan

export const upgradeProduct = (product: string, subscriptionAgreementId: number) => {
  return axios.put(`/subscription-agreement/upgrade`, { product, subscriptionAgreementId });
};

// Download invoice
export const downloadInvoice = (id: number) => {
  return axios.get(`/invoice/${id}/download`, {
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
  });
};

// Download receipt
export const downloadReceipt = (id: number) => {
  return axios.get(`/invoice/${id}/download/receipt`, {
    responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },
  });
};
