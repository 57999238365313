import { BasicInfoSchema } from '@/utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseAccountInfoFormProps } from './types';

const useAccountBasicInfoForm = ({ account, onClose, handleSaveInfo }: UseAccountInfoFormProps) => {
  const [isFormHasUnsavedChanges, setIsFormHasUnsavedChanges] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const form = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(BasicInfoSchema),
    defaultValues: {
      firstName: account?.firstName,
      lastName: account?.lastName,
      phoneNumber: account?.phoneNumber,
      email: account?.email,
    },
  });

  const handleEditBasicInfo = (data: any) => {
    const { email, ...rest } = data;
    handleSaveInfo({ ...rest });
  };
  const { isDirty } = form.formState;

  useEffect(() => {
    if (isFormHasUnsavedChanges !== isDirty) {
      setIsFormHasUnsavedChanges(isDirty);
    }
  }, [isDirty, isFormHasUnsavedChanges]);

  const drawerCloseHandler = () => {
    if (isFormHasUnsavedChanges) {
      setIsConfirmationModalOpen(true);
    } else {
      onClose();
    }
  };

  const confirmUnsavedChangesHandler = () => {
    setIsConfirmationModalOpen(false);
    onClose();
  };

  const cancelUnsavedChangesHandler = () => {
    setIsConfirmationModalOpen(false);
  };

  return {
    form,
    handleEditBasicInfo,
    isDirty,
    drawerCloseHandler,
    confirmUnsavedChangesHandler,
    cancelUnsavedChangesHandler,
    isConfirmationModalOpen,
  };
};

export default useAccountBasicInfoForm;
