import moment from 'moment';
import { twMerge } from 'tailwind-merge';

import Button from '@/components/Button/Button';
import { Status } from '@/components/ui';

import {
  PRICING_STRATEGY_TO_NAME_MAP,
  SUBSCRIPTION_STATUS_TO_STATUS_TYPE_MAP,
} from './utils/constants';

export type SubscriptionBillingCardProps = {
  /** Subscription Plan name */
  planName: string;

  /** Subscription status */
  status: 'active' | 'expired' | 'cancelled';

  /** Subscription plan description */
  description: string;

  /** Subscription plan price */
  price: number;

  /** Subscription plan strategy */
  pricingStrategy: 'monthly' | 'yearly';

  /** Should show the plan progress */
  isShowProgress: boolean;

  /** Show progress in employee or days */
  progressType: 'employee' | 'days';

  /** Current value of the progress in days or employee */
  currentValue: number;

  /** Max value of the plan in days or employee */
  maxValue: number;

  /** Should show the upgrade plan option */
  isUpgradePlanAvailable: boolean;

  /** Upgrade plan click handler */
  onUpgradePlanClick?: () => void;

  /** Is free trial active */
  isFreeTrial: boolean;

  /** free trial end date when free trial is active */
  freeTrialEndDate?: string | null;

  /** Next invoice date when user subscribed for the plan */
  nextInvoiceDate?: string | null;

  onRenewPlanClick: () => void;
};

const SubscriptionBillingCard = ({
  planName,
  status,
  description,
  price,
  pricingStrategy,
  isShowProgress,
  currentValue,
  isFreeTrial,
  maxValue,
  progressType,
  freeTrialEndDate,
  nextInvoiceDate,
  isUpgradePlanAvailable,
  onUpgradePlanClick,
  onRenewPlanClick,
}: SubscriptionBillingCardProps) => {
  const formattedMaxValue = maxValue >= 0 ? maxValue : 0;

  // formate current value for the days variant to not show current value more than max value
  const formattedCurrentValue =
    progressType === 'days'
      ? currentValue > formattedMaxValue
        ? formattedMaxValue
        : currentValue
      : currentValue;

  const progress = ((currentValue ?? 0) / formattedMaxValue) * 100;

  const formattedProgress = progress > 100 ? 100 : parseInt(progress.toString());

  const formattedUpgradePlanCheck = isUpgradePlanAvailable && onUpgradePlanClick;

  return (
    <div
      className={
        'flex w-full flex-col gap-[18px] rounded-lg border border-gray-100 bg-surface-white p-4'
      }
    >
      <div className="flex flex-col items-start justify-between gap-4 sm:flex-row sm:items-center">
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex items-center gap-1.5">
            <span className="font-F37Bolton-Medium text-sm capitalize">
              {planName?.replace('-', ' ')}
            </span>
            <Status
              variant={SUBSCRIPTION_STATUS_TO_STATUS_TYPE_MAP[status]}
              className="text-xs capitalize"
            >
              {status}
            </Status>
          </div>
          <div className="text-sm text-gray-300">{description}</div>
        </div>
        <div className="flex items-end justify-end">
          <span className="font-F37Bolton-Medium text-2xl">${Number(price ?? 0).toFixed(2)}</span>
          <span className="text-lg text-gray-300">
            /{PRICING_STRATEGY_TO_NAME_MAP[pricingStrategy]}
          </span>
        </div>
      </div>
      {isShowProgress && (
        <div className="flex flex-col gap-2.5">
          <div className="font-F37Bolton-Medium text-sm">
            {formattedCurrentValue} of{' '}
            <span className="font-F37Bolton-Medium text-sm">{formattedMaxValue}</span>{' '}
            <span className="font-F37Bolton-Medium text-sm">
              {progressType === 'employee' ? 'employees' : 'days used'}
            </span>
          </div>
          <div className="flex h-2 w-full justify-start rounded-full bg-gray-50">
            <div
              className={twMerge(
                `h-full rounded-full bg-emerald-500`,
                ['expired', 'cancelled'].includes(status) && 'bg-red-500',
              )}
              style={{
                width: `${formattedProgress}%`,
              }}
            />
          </div>
        </div>
      )}
      <hr />

      <div className={twMerge(`flex flex-wrap justify-between gap-y-2`)}>
        <div className="text-sm">
          {isFreeTrial ? (
            <div className="flex gap-1">
              {moment(freeTrialEndDate).isValid() && (
                <>
                  <span className="text-gray-300 sm:hidden">Ends on</span>
                  <span className="hidden font-F37Bolton-Medium text-gray-300 sm:flex">
                    Free trial ends on
                  </span>
                  <span className="font-F37Bolton-Medium">
                    {moment(freeTrialEndDate).format('MMM DD, YYYY')}
                  </span>
                </>
              )}
            </div>
          ) : (
            <div className="flex gap-1">
              {moment(nextInvoiceDate).isValid() && (
                <>
                  <span className="text-gray-300">Next invoice:</span>
                  <span>{moment(nextInvoiceDate).format('MMM DD, YYYY')}</span>
                </>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center gap-1 font-F37Bolton-Medium text-emerald-500">
          <Button
            variant="text"
            height="h-auto"
            parentClasses="text-sm"
            onPress={() => {
              status === 'cancelled' ? onRenewPlanClick() : onUpgradePlanClick();
            }}
          >
            {status === 'cancelled' ? 'Renew plan' : 'Manage plans'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionBillingCard;
