import { Dialog } from '@headlessui/react';
import { useEffect } from 'react';

import { Button } from '@/components';
import { ReactComponent as WarningIcon } from '@/assets/warningTriangle.svg';
import { ReactComponent as WarningPurpleIcon } from '@/assets/purpleWarning.svg';
import { ReactComponent as XMarkIcon } from '@/assets/circleXMark.svg';
import { trackEvent } from '@/utils/track';

interface ActionModalProps {
  isOpen: boolean;

  //In the feature must me added new types
  iconType: 'warningYellow' | 'warningRed' | 'warningPurple';
  toggleModal: (isOpen: boolean) => void;
  onAction: () => void;
  title?: string;
  description?: string;
  actionButtonTitle?: string;
  linkButtonTitle?: string;
  isCritical: boolean;
  bgColor?: string;
  isNewModal?: boolean;
}

const ActionModal: React.FC<ActionModalProps> = ({
  isOpen,
  toggleModal,
  onAction,
  title,
  description,
  actionButtonTitle,
  linkButtonTitle,
  isCritical = false,
  iconType = 'warningYellow',
  bgColor,
  isNewModal,
}: ActionModalProps) => {
  const renderIconByType = (iconType: string) => {
    switch (iconType) {
      case 'warningYellow':
        return <WarningIcon className="min-h-[48px] min-w-[48px]" />;
      case 'warningPurple':
        return <WarningPurpleIcon className="min-h-[48px] min-w-[48px]" />;
      case 'XMarkIcon':
        return <XMarkIcon className="min-h-[48px] min-w-[48px]" />;
      default:
        break;
    }
  };
  useEffect(() => {
    trackEvent('modalMount', {
      name: 'action_modal',
    });
  }, []);

  const handleActionButtonClick = () => {
    trackEvent('buttonClick', {
      name: 'action_modal',
      clickedButton: actionButtonTitle,
    });
  };

  const handleLinkButtonClick = () => {
    trackEvent('modalUnmount', {
      name: 'action_modal',
      clickedButton: linkButtonTitle,
    });
  };

  return (
    <Dialog open={isOpen} onClose={() => null} className="relative z-50">
      <div className="fixed inset-0 flex items-center justify-center backdrop-blur-sm backdrop-brightness-75">
        <Dialog.Panel>
          <div className="flex h-[105vh] max-h-[100vh] w-full items-center justify-center overflow-y-auto overflow-x-hidden  lg:h-auto lg:w-[500px] lg:rounded-2xl lg:px-[40px]">
            <div className="w-full px-[40px]">
              <div className="relative flex flex-col items-center rounded-2xl bg-light-brown px-[20px] py-[40px]">
                {renderIconByType(iconType)}
                {title && <h1 className="mt-[16px] text-center font-F37Bolton-Medium">{title}</h1>}
                {description && (
                  <h1 className="mt-[16px] text-center text-gray-900">{description}</h1>
                )}
                {actionButtonTitle && (
                  <div className="mt-[20px]">
                    <Button
                      type={'primary'}
                      children={actionButtonTitle}
                      width="w-[180px]"
                      onClick={() => {
                        if (isNewModal) {
                          handleLinkButtonClick();
                          onAction();
                          toggleModal(!isOpen);
                        } else {
                          handleActionButtonClick();
                          toggleModal(!isOpen);
                        }
                      }}
                      bgColor={bgColor}
                    />
                  </div>
                )}
                {linkButtonTitle && (
                  <div className="mt-[20px]">
                    <Button
                      type={'link'}
                      children={linkButtonTitle}
                      onClick={() => {
                        if (isNewModal) {
                          handleActionButtonClick();
                          toggleModal(!isOpen);
                        } else {
                          handleLinkButtonClick();
                          onAction();
                          toggleModal(!isOpen);
                        }
                      }}
                      isCritical={isCritical}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default ActionModal;
