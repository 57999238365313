import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/redux/store';

import { useGetFastSpringAccount } from './api';

type FastSpringEmbeddedPaymentProviderProps = {
  children: React.ReactNode;
};

type FastSpringPaymentMethodContextType = {
  isLoadingFastSpringData: boolean;
  isFastSpringIdAvailable: boolean;
  loadEmbeddedFastSpringPayment: () => void;
};

const FastSpringPaymentMethodContext = createContext({} as FastSpringPaymentMethodContextType);

export const useFastSpringPaymentMethodContext = () => {
  const context = useContext(FastSpringPaymentMethodContext);
  if (!context) {
    throw new Error(
      'useFastSpringPaymentMethodContext must be used within a FastSpringEmbeddedPaymentProvider',
    );
  }

  return context;
};

const FastSpringEmbeddedPaymentProvider = ({
  children,
}: FastSpringEmbeddedPaymentProviderProps) => {
  const { refetch, isLoading } = useGetFastSpringAccount({
    queryParams: {
      enabled: false,
      retry: false,
    },
  });
  const subscriptionInfo = useSelector((state: RootState) => state.auth.subscriptionInfo);

  const fastSpringId = useMemo(() => {
    const formattedSubscriptionAgreement =
      subscriptionInfo?.activeSubscriptionAgreement ??
      Array.isArray(subscriptionInfo?.subscriptionAgreements)
        ? subscriptionInfo?.subscriptionAgreements.find(
            agreement =>
              ['trialing', 'active'].includes(agreement.status) && !!agreement.fastSpringId,
          )
        : null;
    return formattedSubscriptionAgreement?.fastSpringId;
  }, [subscriptionInfo]);

  const script = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://epml.onfastspring.com/epml/epml.min.js';
    script.id = 'fsc-epml';
    script.setAttribute('data-payment-component-id', 'payment-manage-payment-methods');
    script.onload = () => {
      refetch().then(({ data }) => {
        const accountManagementPortalLink = data?.accounts?.[0]?.url;
        if (accountManagementPortalLink && fastSpringId) {
          window.fastspring.epml.init(accountManagementPortalLink);
          window.fastspring.epml.paymentManagementComponent(fastSpringId);
        }
      });
    };
    document.head.appendChild(script);
  };

  const isFastSpringIdAvailable = !!fastSpringId;

  return (
    <FastSpringPaymentMethodContext.Provider
      value={{
        isLoadingFastSpringData: isLoading,
        loadEmbeddedFastSpringPayment: script,
        isFastSpringIdAvailable,
      }}
    >
      {children}
    </FastSpringPaymentMethodContext.Provider>
  );
};

export default FastSpringEmbeddedPaymentProvider;
