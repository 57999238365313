// import url from 'utils/url';
import axios from '../axios';

export interface PayDate {
  startDate: string;
  endDate: string;
}

export interface Payment {
  contractorId: number;
  wageType: number;
  amount: number;
  reimbursementAmount: number;
  invoiceNumber: string;
  bonus: number;
  numberOfHours: number;
  totalAmount: number;
}

export interface PaymentPayload {
  payDate: PayDate;
  payments: Payment[];
}
// Get list of active Contractors in a company

export const getListOfActiveContractors = (companyId: string) => {
  return axios.get(`/company/${companyId}/pay-contractors/get-active`);
};

// Get list of active Contractors in a company

export const getSubmittedPayment = (companyId: string, contractorPaymentId: string) => {
  return axios.get(`/company/${companyId}/pay-contractors/${contractorPaymentId}`);
};

// Submit payment for contractors

export const postPaymentContractors = (companyId: string, payload: PaymentPayload) => {
  return axios.post(`/company/${companyId}/pay-contractors/submit`, payload);
};

// Confirm payment for contractors

export const confirmPaymentsContractorsById = (companyId: string, contractorPaymentId: string) => {
  return axios.patch(`/company/${companyId}/pay-contractors/${contractorPaymentId}/confirm`, {});
};

//Cancel Contractor's Payment

export const cancelContartorsPayment = (
  companyId: string,
  contractorId: string,
  paymentId: string,
) => {
  return axios.patch(
    `/company/${companyId}/pay-contractors/${contractorId}/cancel/${paymentId}`,
    {},
  );
};
