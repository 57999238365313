import { createModel } from '@rematch/core';

import { callToast } from '@/utils/helpers';
import {
  getCurrentUserCompanies,
  getInformationForRunPayroll,
  getMissingTaxInformationGroupedByState,
  getTaxInformationCompanyByid,
  pathUpdateCompanyTaxInformation,
  putUpdateCompanyResourse,
} from '@/api/services/company';
import { postWorkLocation } from '@/api/services/workLocations';
import {
  missingTaxInformation,
  Company,
  taxInformation,
  AvaliableTeamMembers,
} from '@/redux/dto/company';

import type { RootModel } from '.';

type companyState = {
  erorr: unknown;
  companies: Company[];
  missingTaxInformation: missingTaxInformation;
  taxInformation: [taxInformation];
  avaliableTeamMembers: AvaliableTeamMembers;
};

export const company = createModel<RootModel>()({
  state: {
    error: null,
    companies: [],
    taxInformation: null,
    missingTaxInformation: null,
    avaliableTeamMembers: null,
  } as unknown as companyState,
  reducers: {
    GET_CURRENT_USER_COMPANIES_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        companies: payload,
      };
    },
    GET_CURRENT_USER_COMPANIES_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_CURRENT_USER_COMPANIES_SUCCESS: (state, payload) => {
      const updatedCompanies = state.companies.map(company => {
        if (company.id === payload.id) {
          return { ...company, ...payload };
        }
        return company;
      });

      if (!state.companies.some(company => company.id === payload.id)) {
        updatedCompanies.push(payload);
      }

      return {
        ...state,
        error: null,
        companies: updatedCompanies,
      };
    },
    UPDATE_CURRENT_USER_COMPANIES_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    UPDATE_COMPANY_TAX_INFORMATION_SUCCESS: state => {
      return {
        ...state,
        error: null,
      };
    },
    UPDATE_COMPANY_TAX_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_TAX_INFORMATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        taxInformation: payload,
      };
    },
    GET_TAX_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_MISSING_TAXS_INFORMATION_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        missingTaxInformation: payload,
      };
    },
    GET_MISSING_TAXS_INFORMATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    GET_PAYROLL_CHECK_INFORMATION_RUN_SUCCESS: (state, payload) => {
      return {
        ...state,
        error: null,
        avaliableTeamMembers: payload,
      };
    },
    GET_PAYROLL_CHECK_INFORMATION_RUN_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
    POST_NEW_WORK_LOCATION_SUCCESS: (state, payload) => {
      const { companyId, newWorkLocation } = payload;
      const updatedCompanies = state.companies.map(company => {
        if (company.id === companyId) {
          return {
            ...company,
            workLocations: [...company.workLocations, newWorkLocation],
          };
        } else {
          return company;
        }
      });
      return {
        ...state,
        error: null,
        companies: updatedCompanies,
      };
    },
    POST_NEW_WORK_LOCATION_FAILURE: (state, payload) => {
      return {
        ...state,
        error: payload,
      };
    },
  },
  effects: dispatch => {
    const { company } = dispatch;
    return {
      async getCurrentUserCompanies(): Promise<unknown> {
        try {
          const { data } = await getCurrentUserCompanies();
          company.GET_CURRENT_USER_COMPANIES_SUCCESS(data);
          return data;
        } catch (error: any) {
          company.GET_CURRENT_USER_COMPANIES_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateCompanyInformation(payload, state): Promise<unknown> {
        try {
          const { data } = await putUpdateCompanyResourse(
            state.company.companies[0].id.toString(),
            payload,
          );
          company.UPDATE_CURRENT_USER_COMPANIES_SUCCESS(data);
          return data;
        } catch (error: any) {
          company.UPDATE_CURRENT_USER_COMPANIES_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
          return error.response.data.detail;
        }
      },
      async getTaxInformation(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await getTaxInformationCompanyByid(companyId.toString());
          company.GET_TAX_INFORMATION_SUCCESS(data);
          return data;
        } catch (error: any) {
          company.GET_TAX_INFORMATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async updateCompanyTaxInformation(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await pathUpdateCompanyTaxInformation(companyId.toString(), payload);
          company.UPDATE_COMPANY_TAX_INFORMATION_SUCCESS(data);
          dispatch.company.getTaxInformation(payload, state);
          return data;
        } catch (error: any) {
          company.UPDATE_COMPANY_TAX_INFORMATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async addNewWorlLocation(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await postWorkLocation(companyId.toString(), payload);
          company.POST_NEW_WORK_LOCATION_SUCCESS({ companyId, newWorkLocation: data });
          return data;
        } catch (error: any) {
          company.POST_NEW_WORK_LOCATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async addNewWorkLocationFromRadio(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await postWorkLocation(companyId.toString(), payload.data.workAddress);
          company.POST_NEW_WORK_LOCATION_SUCCESS({ companyId, newWorkLocation: data });
          return data;
        } catch (error: any) {
          company.POST_NEW_WORK_LOCATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
      async addNewWorkLocationFromRadioHome(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await postWorkLocation(companyId.toString(), payload);
          company.POST_NEW_WORK_LOCATION_SUCCESS({ companyId, newWorkLocation: data });
          return data;
        } catch (error: any) {
          company.POST_NEW_WORK_LOCATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      async getInformationForRunPayroll(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await getInformationForRunPayroll(companyId.toString());
          company.GET_PAYROLL_CHECK_INFORMATION_RUN_SUCCESS(data);
          return data;
        } catch (error: any) {
          company.GET_PAYROLL_CHECK_INFORMATION_RUN_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },

      async getMissingTaxInformationGroupedByState(payload, state): Promise<unknown> {
        try {
          const companyId = state.auth.user.companyId;
          const { data } = await getMissingTaxInformationGroupedByState(companyId.toString());
          company.GET_MISSING_TAXS_INFORMATION_SUCCESS({ companyId, newWorkLocation: data });
          return data;
        } catch (error: any) {
          company.GET_MISSING_TAXS_INFORMATION_FAILURE(error);
          callToast('error', error.response?.data?.detail);
          console.log(error);
        }
      },
    };
  },
});
