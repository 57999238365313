import React from 'react';
import { Controller } from 'react-hook-form';
import InputMaskTextField from '../InputMaskTextField';
import { FormInputMaskTextFieldProps } from './types';

const FormInputMaskTextField: React.FC<FormInputMaskTextFieldProps> = ({
  name,
  isRequired,
  rules,
  isDisabled,
  shouldUnregister = true,
  ...rest
}) => (
  <Controller
    name={name}
    shouldUnregister={shouldUnregister}
    disabled={Boolean(isDisabled)}
    render={({ field: { value, ...restField }, fieldState: { error } }) => (
      <InputMaskTextField
        errorMessage={error && !!error && error.message}
        isRequired={Boolean(isRequired || rules?.required)}
        value={value || ''}
        isDisabled={isDisabled}
        {...rest}
        {...restField}
      />
    )}
    rules={rules}
  />
);

export default FormInputMaskTextField;
