import { ReactComponent as CloseIcon } from '@/assets/close.svg';
import Button from '@/components/Button/Button';
import { InlineWidget } from 'react-calendly';
import { CalendlyModalType } from '../types';

export const CalendlyModal = ({ onClose }: CalendlyModalType) => {
  return (
    <div className="h-full">
      <Button
        variant="icon"
        icon={CloseIcon}
        onPress={onClose}
        parentClasses="absolute left-[16px]"
      />
      <InlineWidget
        url="https://calendly.com/d/3xy-ywd-knc/chat-with-paystubs-com-team"
        styles={{ height: '100%' }}
      />
    </div>
  );
};
