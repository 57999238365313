import Button from '@/components/Button/Button';
import ConfirmationActionModal from '../ConfirmationActionModal';

type UnsavedDataConfirmationModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title?: string;
  description?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  showCloseIcon?: boolean;
};

const UnsavedDataConfirmationModal = ({
  isOpen,
  onConfirm,
  onCancel,
  title = 'You have some unsaved changes!',
  description = 'Continue to review and edit, ensuring all changes are saved',
  confirmBtnText = 'Exit anyway',
  cancelBtnText = 'Continue',
  showCloseIcon,
}: UnsavedDataConfirmationModalProps) => {
  return (
    <ConfirmationActionModal
      onCancel={() => onCancel()}
      width="sm:w-[480px]"
      modalClasses="sm:px-6 py-8 max-w-[380px]"
      isOpen={isOpen}
      title={title}
      showCloseIcon={showCloseIcon}
      description={description}
      actions={
        <div className="flex flex-col-reverse items-center justify-center gap-2 sm:flex-row">
          <Button
            variant="tertiary"
            onPress={() => onConfirm()}
            parentClasses="font-F37Bolton-Medium w-full"
          >
            {confirmBtnText}
          </Button>
          <Button variant="primary" onPress={() => onCancel()} parentClasses="w-full">
            {cancelBtnText}
          </Button>
        </div>
      }
    />
  );
};

export default UnsavedDataConfirmationModal;
