import { FC, useEffect, useRef } from 'react';
import { Tabs as ReactTabs, TabPanel } from 'react-tabs';

import { extractEventName } from '@/utils/helpers';
import { trackEvent } from '@/utils/track';

import { LoaderComponent } from '../ui';
import TabTitles from './TabTitles';
import { TabsProps } from './types';

/**
 * @deprecated Use new Tab Component from src/components/ui/TabsComponent/Tabs.tsx
 */

const Tabs: FC<TabsProps> = ({
  tabs,
  width,
  currentTab,
  setCurrentTab,
  isLoading,
  variant = 'progressBar',
  additionalTabWidth = 0,
  isProgressHidden = false,
}) => {
  const tabListContainerRef = useRef(null);

  useEffect(() => {
    const centerCurrentTab = () => {
      const tabListContainer = tabListContainerRef.current;
      if (!tabListContainer) return;
      const activeTabElement = tabListContainer.querySelector(
        `[role="tab"]:nth-child(${tabs.findIndex(tab => tab.id === currentTab) + 1})`,
      );
      if (!activeTabElement) return;
      const activeTabWidth = activeTabElement.offsetWidth;
      let newScrollLeft = tabListContainer.scrollLeft;

      newScrollLeft =
        activeTabElement.offsetLeft -
        tabListContainer.offsetLeft -
        tabListContainer.offsetWidth / 2 +
        activeTabWidth / 2;

      tabListContainer.scroll({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    };

    if (variant === 'progressBar') centerCurrentTab();
  }, [currentTab, tabs, variant]);

  useEffect(() => {
    if (variant !== 'progressBar') {
      return;
    }
    const tabListContainer = tabListContainerRef.current;

    if (!tabListContainer) return;
    const tabListContainerWidth = tabListContainer.offsetWidth;

    const firstTabElement = tabListContainer.querySelector(`[role="tab"]:nth-child(1)`);
    const lastTabElement = tabListContainer.querySelector(`[role="tab"]:nth-child(${tabs.length})`);

    if (!firstTabElement || !lastTabElement) return;

    firstTabElement.style.marginLeft = `${
      tabListContainerWidth / 2 - firstTabElement.offsetWidth / 2
    }px`;

    const lastTabElementPreviousPadding =
      parseInt(getComputedStyle(lastTabElement, null)?.paddingRight) || 0;

    lastTabElement.style.paddingRight = `${
      tabListContainerWidth / 2 - (lastTabElement.offsetWidth - lastTabElementPreviousPadding) / 2
    }px`;

    return () => {
      if (lastTabElement) {
        lastTabElement.style.paddingRight = `${lastTabElementPreviousPadding}px`;
      }
    };
  }, [tabs.length, variant]);

  useEffect(() => {
    trackEvent('tabChange', {
      currentTabName: tabs[Number(currentTab) - 1]?.title,
      currentPage: extractEventName(window.location.pathname),
    });
  }, [currentTab]);

  return (
    <ReactTabs
      className={`${width ? width : 'w-[336px]'}`}
      selectedIndex={Number(currentTab) - 1}
      onSelect={() => undefined}
    >
      {!isProgressHidden && (
        <TabTitles
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabs={tabs}
          ref={tabListContainerRef}
          variant={variant}
          additionalTabWidth={additionalTabWidth}
        />
      )}
      {tabs.map(tab => (
        <TabPanel key={tab.id}>
          {isLoading ? <LoaderComponent classNames="mt-[24px]" /> : tab.content}
        </TabPanel>
      ))}
    </ReactTabs>
  );
};

export default Tabs;
