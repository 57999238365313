export type PayrollScheduleTypes = {
  payFrequency: string | null;
  payDay: string | number | null;
  payDate: string | null;
  payPeriod: string | null;
  firstPayDate: number;
  secondPayDate: number;
  firstPayPeriod: string;
};

export const quarters = {
  'Q1: January - March': 1,
  'Q2: April - June': 2,
  'Q3: July - September': 3,
  'Q4: October - December': 4,
};

export type ConfirmationType = {
  isOpen?: boolean;
  handleCancel: () => void;
  setIsConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export enum PayFrequency {
  'weekly' = 1,
  'biweekly' = 2,
  'semimonthly' = 3,
  'monthly' = 4,
  'quarterly' = 5,
}

export const PayFrequencyList = {
  weekly: 1,
  biweekly: 2,
  semimonthly: 3,
  monthly: 4,
  quarterly: 5,
};

export enum WeekDays {
  'Monday' = 1,
  'Tuesday' = 2,
  'Wednesday' = 3,
  'Thursday' = 4,
  'Friday' = 5,
}

export const WeekDaysList = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
};

export interface PayrollScheduleProps {
  isOpen?: boolean;
  isEdit: boolean;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  refetchDashboard?: () => void;
}
