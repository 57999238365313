import { RootState } from '@/redux/store';
import { callToast } from '@/utils/helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateAccountInfo } from '../../api';

const useAccountBasicInfo = () => {
  const dispatch = useDispatch();
  const [isEditBasicInfo, setIsEditBasicInfo] = useState<boolean>(false);
  const [isEditPassword, setIsEditPassword] = useState<boolean>(false);

  const handleEditBasicInfoDrawer = () => {
    setIsEditBasicInfo(!isEditBasicInfo);
  };
  const handleEditPasswordDrawer = () => {
    setIsEditPassword(!isEditPassword);
  };

  const { user, isLoading } = useAuth0();
  const account = useSelector((state: RootState) => state.auth.user.account);
  const isLoggedInViaGoogle = user?.sub?.includes('google');

  const profileData = [
    {
      id: 'name',
      label: 'Name',
      value: account?.firstName + ' ' + account?.lastName,
    },
    {
      id: 'phone_num',
      label: 'Phone number',
      value: account?.phoneNumber,
    },
    {
      id: 'email_address',
      label: 'Email Address',
      value: account?.email,
    },
  ];

  const getAccountInfo = async () => {
    await dispatch.auth.getOnboardinUserStatus();
  };

  const { mutate: updateAccountInfo, isPending: isUpdatingAccountInfo } = useUpdateAccountInfo({
    accountId: account?.id,
    mutationParams: {
      onSuccess: () => {
        getAccountInfo();
        if (isEditPassword) {
          handleEditPasswordDrawer();
          callToast('success', 'Password updated successfully!');
        } else {
          handleEditBasicInfoDrawer();
          callToast('success', 'Basic Info updated successfully!');
        }
      },
    },
  });

  return {
    isEditBasicInfo,
    handleEditBasicInfoDrawer,
    profileData,
    isLoggedInViaGoogle,
    account,
    isLoading,
    user,
    handleEditPasswordDrawer,
    isEditPassword,
    isUpdatingAccountInfo,
    updateAccountInfo,
  };
};
export default useAccountBasicInfo;
