import { LightBulbIcon } from '@heroicons/react/24/outline';
import { lazy, useCallback, useEffect, useState, useTransition } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, LoaderComponent } from '@/components';
import { RootState } from '@/redux/store';
import { PAY_CONTRACTORS, TEAM_MEMBERS } from '@/routes/routes';
import labels from '@/utils/labels';

import ContractorCard from './ContractorCard';

import { Props } from '@/interface';

const FAQModal = lazy(() => import('@/components/shared/FAQModal/FAQModal'));
let isLoaded = false;

const Contractor1099s = ({ years }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { control } = useForm();

  const [, startTransition] = useTransition();
  const [isLoadingList, setIsLoadingList] = useState(false);
  const _1099necContractors = useSelector((state: RootState) => state._1099nec.contractors);

  const [isFaqModalOpen, setIsFaqModalOpen] = useState(false);

  const getContractors = useCallback(async () => {
    setIsLoadingList(true);
    await dispatch._1099nec.getContractors1099NecFormsPerYearList('2023');
    setIsLoadingList(false);
  }, []);

  const toggleFaqModal = useCallback(() => {
    if (isLoaded) {
      setIsFaqModalOpen(prev => !prev);
      return;
    }

    startTransition(() => setIsFaqModalOpen(prev => !prev));
    isLoaded = true;
  }, []);
  useEffect(() => {
    getContractors();
  }, []);

  return (
    <div className="flex grow flex-col justify-between px-[20px] py-[28px] lg:w-[770px] lg:grow-0 lg:px-[58px]">
      <div className="flex flex-col">
        <div className="mb-[28px] flex w-full flex-col lg:w-[430px]">
          <label className="mb-2 font-F37Bolton-Medium" htmlFor="year" children="Year" />
          <Controller
            control={control}
            name="year"
            render={({ field }) => (
              <select {...field} className="rounded-lg">
                {years.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            )}
          />
        </div>

        <p
          className="mb-4 cursor-pointer font-F37Bolton-Medium font-medium text-blue-500 underline"
          onClick={toggleFaqModal}
          children={labels.TAX_FORMS.CONTRACTOR_1099S.FAQ.LINK}
        />

        <div className="col-span-4 mb-4 rounded-2xl border border-lightblue-400 bg-white p-[20px] lg:mb-[28px]">
          <div className="mb-3 flex items-center">
            <LightBulbIcon className="me-3 inline-block size-10 rounded-full border-[6px] border-lightblue-400 bg-lightblue-400 text-gray-400" />
            <span className="text-base font-medium">Tip</span>
          </div>
          <p className="text-base lg:leading-9" children={labels.TAX_FORMS.CONTRACTOR_1099S.TIP} />
        </div>

        <div className="mb-7 flex flex-col gap-y-4">
          {isLoadingList ? (
            <LoaderComponent />
          ) : (
            _1099necContractors.map(data => <ContractorCard key={data.contractor.id} data={data} />)
          )}
        </div>
      </div>

      <div className="flex h-fit flex-col items-center gap-y-4 lg:items-end">
        <Button
          type="primary"
          children="Create contractor pay stub"
          className="h-fit py-[12px] lg:w-fit lg:px-[32px]"
          fontSize="text-sm lg:text-base"
          onClick={() => navigate(PAY_CONTRACTORS)}
        />
        <Button
          type="link"
          children="View all contractors"
          width="w-[160px] lg:w-[220px]"
          height="h-[24px]"
          fontSize="text-sm lg:text-base"
          onClick={() => navigate(TEAM_MEMBERS)}
        />
      </div>

      {isFaqModalOpen && <FAQModal isOpen={isFaqModalOpen} toggleModal={toggleFaqModal} />}
    </div>
  );
};

export default Contractor1099s;
