import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountInfo, useBillingInfo } from '@/hooks';
import * as Routes from '@/routes/routes';
import { customizationOptions } from '@/utils/constants';
import {
  checkAccountForAccountSetup,
  checkAccountForOnboardingCustomization,
  checkAccountForSubscriptionReminder,
} from '@/utils/helpers';

const useAccountSetup = () => {
  const navigate = useNavigate();

  const [shouldShowCustomizationModal, setShouldShowCustomizationModal] = useState(false);
  const [isSavingCustomization, setIsSavingCustomization] = useState(false);

  const { isLoading: isLoadingAuth0 } = useAuth0();
  const { account, patchAccount, isAccountLoading } = useAccountInfo();
  const { billingInfo, isBillingInfoLoading } = useBillingInfo();

  const { firstName = '', payrollManagement, createdAt, metadata } = account ?? {};

  const bannerInfo = checkAccountForSubscriptionReminder(billingInfo);

  useEffect(() => {
    setShouldShowCustomizationModal(
      !payrollManagement && !!createdAt && checkAccountForOnboardingCustomization(createdAt),
    );
  }, [payrollManagement, createdAt]);

  const customizationModalCancelHandler = () => {
    setShouldShowCustomizationModal(false);
  };

  const onSaveCustomizationHandler = async (value: string | number | boolean | null) => {
    setIsSavingCustomization(true);
    try {
      await patchAccount({ payrollManagement: value });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSavingCustomization(false);
    }
  };

  const { shouldShowAccountSetup, isCompanyStepCompleted } = checkAccountForAccountSetup({
    account,
  });

  const isStartedFreeTrail =
    metadata && !Array.isArray(metadata) && metadata['startedFreeTrial']
      ? metadata['startedFreeTrial']
      : false;

  useEffect(() => {
    if (account) {
      if (!isStartedFreeTrail) {
        navigate(Routes.SIGNUP_STEPPER);
      } else if (!shouldShowAccountSetup || (shouldShowAccountSetup && isCompanyStepCompleted)) {
        navigate(Routes.DASHBOARD);
      }
    }
  }, [account, shouldShowAccountSetup, isCompanyStepCompleted, navigate, isStartedFreeTrail]);

  const { bannerVariantToShow } = bannerInfo ?? {};

  const isLoading = isAccountLoading || isBillingInfoLoading || isLoadingAuth0;

  return {
    shouldShowCustomizationModal,
    customizationModalCancelHandler,
    onSaveCustomizationHandler,
    customizationOptions,
    isSavingCustomization,
    firstName,
    bannerVariantToShow,
    isLoading,
  };
};

export default useAccountSetup;
