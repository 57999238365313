import { useGetContentBlock } from '@/api/services/apis/contentBlocks/useGetContentBlock';

import { useContentBlockInfoBoxProps } from './types';

const useAccountSetupStepInfoBox = ({ identifier }: useContentBlockInfoBoxProps) => {
  const { data } = useGetContentBlock({ identifier });
  const markup = data?.content ? { __html: data?.content } : null;

  return { markup };
};

export default useAccountSetupStepInfoBox;
