import axios from '../axios';

export const getContractors1099NecFormsPerYearEndpoint = (year: number | string) =>
  axios.get(`/tax-forms/1099-nec/list/${year}`);

export const getContractor1099NecFormPerYearDetailsEndpoint = ({
  contractorId,
  year,
}: {
  contractorId: number | string;
  year: number | string;
}) => axios.get(`/tax-forms/1099-nec/contractor/${contractorId}/${year}`);

export const saveContractors1099NecFormsForPreviewEndpoint = ({
  id,
  body,
}: {
  id: string;
  body: Record<string, any>;
}) => axios.put(`/tax-forms/1099-nec/${id}/update`, body);

//Draft Contractor’s 1099-NEC form (PUT)
export const putFormAsDraft = (formId: string, payload: Object) => {
  return axios.put(`/tax-forms/1099-nec/${formId}/draft`, payload);
};

//Mark as Draft Contractor’s 1099-NEC form (PATCH)
export const patchFormAsDraft = (formId: string) => {
  return axios.patch(`/tax-forms/1099-nec/${formId}/mark-as-draft`, {});
};

//Cancel Contractor’s 1099-NEC form
export const cancelForm = (formId: string) => {
  return axios.delete(`/tax-forms/1099-nec/${formId}/cancel`);
};

//Preview Contractor's 1099-NEC form (GET)
export const getPreviewForm = (formId: string) => {
  return axios.get(`/tax-forms/1099-nec/${formId}/preview`);
};

//Download Contractor's 1099-NEC form as PDF file
export const getDownloadForm = (formId: string) => {
  return axios.get(`/tax-forms/1099-nec/${formId}/download`, { responseType: 'blob' });
};

//Mark as Approved Contractor’s 1099-NEC form (PATCH)
export const patchFormAsApproved = (formId: string) => {
  return axios.patch(`/tax-forms/1099-nec/${formId}/mark-as-approved`, {});
};

//Share Contractor’s 1099-NEC form via email
export const postShareViaEmail = (formId: string, payload: Object) => {
  return axios.post(`/tax-forms/1099-nec/${formId}/share`, payload);
};
