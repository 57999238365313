// import url from 'utils/url';
import { Benefit, CustomPostTax, Garnishment } from '@/components/shared/BonusCard/BonusCard';
import { TaxFormEmployee } from '@/redux/dto/employee';

import axios from '../axios';

export interface employeeProfile {
  firstName: string;
  lastName: string;
  middleName: string;
  ssn: string;
}

export type OnBoardingStatus =
  | 'personal_details_missing'
  | 'home_address_missing'
  | 'work_address_missing'
  | 'compensation_info_missing'
  | 'benefits_missing'
  | 'federal_withholding_missing'
  | 'state_withholding_missing'
  | 'compensation_should_be_updated'
  | 'YTD_should_be_updated'
  | 'ytd_missing'
  | 'ytd_confirmation_missing'
  | 'completed'
  | 'employer_withholding_tax_approval_pending'
  | 'pending_employee_withholding_tax_submission';

export type TaxWithholdingSubmissionStatusType =
  | 'pending'
  | 'waiting_for_employee_submission'
  | 'waiting_for_employee_signature'
  | 'waiting_for_employer_approval'
  | 'waiting_for_employee_modification'
  | 'approved';

export type EmployeeSignatureType = {
  type: string;
  printedName: string;
  email: string;
  void: boolean;
  createdAt: string;
};

export type TaxDetailsSubmissionType = 'add_myself' | 'invite_employee';
export interface EmploymentInformationEmployee {
  title: string;
  email: string;
  dateOfEmployment: string;
  chooseWorkLocation: number;
  workLocationId: number | null;
  homeAddress: HomeAddress;
  workAddress: WorkAddress;
  canBeDeleted: boolean;
  confirmedYTDTaxDeductions: boolean | undefined;
  firstName: string;
  id: number;
  inComplete: boolean;
  isActive: boolean;
  lastName: string;
  middleName: string;
  ssn: string;
  onBoardingStatus: OnBoardingStatus;
  incompleteSteps: OnBoardingStatus[];
  withholdingInvitations: WithholdingInvitationType[];
  signatures: EmployeeSignatureType[];
  taxDetailsSubmissionType: TaxDetailsSubmissionType;
  employeeTaxWithholdingDetails: TaxFormEmployee;
}

export interface HomeAddress {
  streetAddress: string;
  aptSteNumber: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface WorkAddress {
  streetAddress: string;
  aptSteNumber: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface CompensationInformation {
  employeeType: number;
  amount: number;
  per: number;
  isPaidThisYear: boolean;
  grossPayYtd: number;
}
export interface TaxRecord {
  state: State[];
  federal: Federal[];
}

export interface State {
  fieldName: string;
  type: 'Select' | 'Checkbox' | 'Integer' | 'Dollar' | 'select' | 'Percentage';
  options: [];
  listValues: any[];
  defaultValue: string;
  required: boolean;
  description: string;
  helpText: string;
  regex: string;
  value: string;
  label: string;
}

export interface Federal {
  fieldName: string;
  type: 'Select' | 'Checkbox' | 'Integer' | 'Dollar' | 'select' | 'checkbox';
  options: [];
  listValues: any[];
  defaultValue: string;
  required: boolean;
  description: string;
  helpText: string;
  regex: string;
  value: string;
  label: string;
}

export interface YtdTaxPayload {
  taxDeductions: TaxDeduction[];
}

export interface TaxDeduction {
  name: string;
  value: string;
}

export type WithholdingInvitationType = {
  id: number;
  email: string;
  token: string;
  expirationDate: string;
  canceled: boolean;
};

// Get Employee’s Details

export const getEmployeeDetails = (companyId: string, id: string) => {
  return axios.get(`/company/${companyId}/employee/${id}`);
};

// Creates an incomplete Employee

export const postCreateIncompleteEmployee = (companyId: string, payload: employeeProfile) => {
  return axios.post(`/company/${companyId}/employee/create-incomplete`, payload);
};

// Update general details of Employee

export const updateGeneralDetailsOfEmployee = (
  companyId: string,
  emplyeeId: string,
  payload: employeeProfile,
) => {
  return axios.put(`/company/${companyId}/employee/update-details/${emplyeeId}`, payload);
};

// Get Employee's compensation information

export const getCompensationInformation = (companyId: string, employeeId: string) => {
  return axios.get(`/company/${companyId}/employee/${employeeId}/compensation`);
};

// Update Employee’s Compensation information

export const putUpdateCompensationInformation = (
  companyId: string,
  employeeId: string,
  payload: CompensationInformation,
) => {
  return axios.put(`/company/${companyId}/employee/${employeeId}/compensation`, payload);
};

// Get Employee’s Benefits

export const getBenefitsEmployee = (companyId: string, employeeId: string) => {
  return axios.get(`/company/${companyId}/employee/${employeeId}/benefit`);
};

// Submit Employee’s TAx Benefit

export const postBenefitEmployee = (companyId: string, employeeId: string, payload: Benefit) => {
  return axios.post(`/company/${companyId}/employee/${employeeId}/benefit`, payload);
};

// Update Employee’s Tax Benefit

export const updateBenefitsEmployee = (
  companyId: string,
  employeeId: string,
  benefitId: string,
  payload: Benefit,
) => {
  return axios.put(`/company/${companyId}/employee/${employeeId}/benefit/${benefitId}`, payload);
};

// Delete Employee’s Tax Benefit

export const deleteBenefitEmployee = (companyId: string, employeeId: string, benefitId: string) => {
  return axios.delete(`/company/${companyId}/employee/${employeeId}/benefit/${benefitId}`);
};

// Get Employee’s Garnishments

export const getGarnishmentsEmployee = (companyId: string, employeeId: string) => {
  return axios.get(`/company/${companyId}/employee/${employeeId}/garnishment`);
};

// Submit Employee’s TAx Garnishment

export const postGarnishmentEmployee = (
  companyId: string,
  employeeId: string,
  payload: Garnishment,
) => {
  return axios.post(`/company/${companyId}/employee/${employeeId}/garnishment`, payload);
};

// Update Employee’s Tax Garnishment

export const updateGarnishmentEmployee = (
  companyId: string,
  employeeId: string,
  garnishmentId: string,
  payload: Garnishment,
) => {
  return axios.put(
    `/company/${companyId}/employee/${employeeId}/garnishment/${garnishmentId}`,
    payload,
  );
};

// Delete Employee’s Tax Garnishment

export const deleteGarnishmentEmployee = (
  companyId: string,
  employeeId: string,
  garnishmentId: string,
) => {
  return axios.delete(`/company/${companyId}/employee/${employeeId}/garnishment/${garnishmentId}`);
};

// Get Employee’s Custom Post Tax Deduction

export const getCustomPostTaxEmployee = (companyId: string, employeeId: string) => {
  return axios.get(`/company/${companyId}/employee/${employeeId}/custom-post-tax-deduction`);
};

// Submit Employee’s Custom Post Tax Deduction

export const postCustomPostTaxEmployee = (
  companyId: string,
  employeeId: string,
  payload: CustomPostTax,
) => {
  return axios.post(
    `/company/${companyId}/employee/${employeeId}/custom-post-tax-deduction`,
    payload,
  );
};

// Update Custom Post Tax Deduction

export const updateCustomPostTaxEmployee = (
  companyId: string,
  employeeId: string,
  customPostTaxId: string,
  payload: CustomPostTax,
) => {
  return axios.put(
    `/company/${companyId}/employee/${employeeId}/custom-post-tax-deduction/${customPostTaxId}`,
    payload,
  );
};

// Delete Custom Post Tax Deduction

export const deleteCustomPostTaxEmployee = (
  companyId: string,
  employeeId: string,
  customPostTaxId: string,
) => {
  return axios.delete(
    `/company/${companyId}/employee/${employeeId}/custom-post-tax-deduction/${customPostTaxId}`,
  );
};

// Activate a Employee
export const activateEmployee = (companyId: string, employeeId: string) => {
  return axios.patch(`/company/${companyId}/employee/${employeeId}/activate`, {});
};

// Deactivate a Emoloyee
export const deactivateEmployee = (companyId: string, employeeId: string) => {
  return axios.patch(`/company/${companyId}/employee/${employeeId}/de-activate`, {});
};

// Preview Pay stubs for an employee
export const previewPaystubEmployee = (
  companyId: string,
  employeeId: string,
  paymentId: string,
) => {
  return axios.get(`/company/${companyId}/employee/${employeeId}/preview-pay-stubs/${paymentId}`);
};

// Generate Employee’s Tax Form

export const generateTaxDetailsEmployee = (companyId: string, employeeId: string) => {
  return axios.get(`/company/${companyId}/employee/${employeeId}/show-tax-record`);
};

//Get Employee’s Tax record

export const getTaxDetailsEmployee = (companyId: string, taxId: string) => {
  return axios.get(`/company/${companyId}/employee/${taxId}/show-tax-record`);
};

//Submit Employee’s Tax record

export const postTaxDetailsEmployee = (
  companyId: string,
  employeeId: string,
  payload: TaxRecord,
) => {
  return axios.post(`/company/${companyId}/employee/${employeeId}/submit-tax-record`, payload);
};

// Generate Employee’s YTD Tax Deductions

export const generateYtdTaxDeductions = (companyId: string, employeeId: string) => {
  return axios.get(`/company/${companyId}/employee/${employeeId}/ytd-tax-deductions`);
};

// Update Employee’s YTD Tax Deductions

export const patchYtdTaxDeductions = (
  companyId: string,
  employeeId: string,
  payload: YtdTaxPayload,
) => {
  return axios.patch(`/company/${companyId}/employee/${employeeId}/ytd-tax-deductions`, payload);
};

// Delete employee while adding incomplete

export const deleteEmployee = (companyId: string, currentEmployeeId: string) => {
  return axios.delete(`/company/${companyId}/employee/${currentEmployeeId}`);
};

// Skip benefits step on adding employee

export const skipBenefits = (companyId: string, employeeId: string) => {
  return axios.patch(
    `/company/${companyId}/employee/${employeeId}/skipped-benefits-garnishment`,
    {},
  );
};

//Get dates for Gross pay YTD subtitle

export const getYTD = (companyId: string, employeeId: number) => {
  return axios.get(`/company/${companyId}/first-recent-pay-dates-ytd/${employeeId}`);
};

//Get All Employee's W2 form
export const getEmployeeW2 = (year: string) => {
  return axios.get(`/tax-forms/w2/list/${year}`);
};

//Preview Employee's W2 form
export const previewEmployeeW2 = (id: number) => {
  return axios.get(`/tax-forms/w2/${id}/preview`);
};

//Update Form W2
export const patchEmployeeW2 = (id: string | number, body: Object) => {
  return axios.patch(`/tax-forms/w2/${id}`, body);
};

//Share Employee's W2 form via email
export const postShareViaEmailEmployee = (formId: string, payload: Object) => {
  return axios.post(`/tax-forms/w2/${formId}/share`, payload);
};

//Download Employee's W2 form as PDF file
export const downloadEmployeeW2 = (id: number) => {
  return axios.get(`/tax-forms/w2/${id}/download`, { responseType: 'blob' });
};

//Update an existing Employee’s employment information
export const putEmployeeInformationService = (
  companyId: string,
  employeeId: string,
  payload: Object,
) => {
  return axios.put(`/company/${companyId}/employee/${employeeId}/employment`, payload);
};
