// import url from 'utils/url';
import axios from '../axios';

export interface WorkLocation {
  streetAddress: string;
  aptSteNumber?: string;
  city: string;
  state: string;
  zipCode: string;
}

// Add a work location to a company

export const postWorkLocation = (companyId: string, payload: WorkLocation) => {
  return axios.post(`/company/${companyId}/work-location`, payload);
};

// Delete a work location

export const deleteWorkLocationById = (companyId: string, workLocationId: string) => {
  return axios.delete(`/company/${companyId}/work-location/${workLocationId}`);
};

//Update a work location

export const updateWorkLocationById = (
  companyId: string,
  workLocationId: string,
  payload: WorkLocation,
) => {
  return axios.patch(`/company/${companyId}/work-location/${workLocationId}`, payload);
};
