import { FC, useState } from 'react';
import { AppCheckboxGroupProps } from './types';
import Input from '../Input/Input';
import { Controller } from 'react-hook-form';
import { trackEvent } from '@/utils/track';
import { extractEventName } from '@/utils/helpers';

const CheckBoxGroup: FC<AppCheckboxGroupProps> = ({
  title,
  subtitle,
  isRequired = false,
  options,
  errorMessage,
  onChange,
  value,
  name,
  control,
  inputErrorMessage,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(value ? value : []);
  const [otherText, setOtherText] = useState('');

  const handleOnChange = (value: string) => {
    trackEvent('inputChange', {
      name: name,
      currentPage: extractEventName(location.pathname),
    });
    setSelectedOptions(prev => {
      const newSelectedOptions = prev.includes(value)
        ? prev.filter(option => option !== value)
        : [...prev, value];

      if (onChange) {
        onChange(newSelectedOptions);
      }

      return newSelectedOptions;
    });
  };

  return (
    <>
      <span className="font-F37Bolton-Medium text-base">
        {title} {isRequired && <span className="text-red-500">*</span>}
      </span>
      <div className="flex flex-col">
        {options.map(option =>
          option.value === 'other' ? (
            <label
              key={option.value}
              className={`${
                selectedOptions.includes(option.value) && 'bg-[#F2F9F8]'
              } mt-[16px] inline-flex cursor-pointer items-center rounded-[8px]
                border border-gray-300 px-[20px]
              py-[10px]`}
            >
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 cursor-pointer rounded-[4px] text-emerald-500 focus:outline-none focus:ring-0 focus:ring-offset-0"
                checked={selectedOptions.includes('other')}
                onChange={() => handleOnChange('other')}
              />
              <span className="ml-2 text-gray-700">Other</span>
            </label>
          ) : (
            <label
              key={option.value}
              className={`mt-[16px] inline-flex cursor-pointer items-center rounded-[8px] border border-gray-300 px-[20px] py-[10px] ${
                selectedOptions.includes(option.value) && 'bg-[#F2F9F8]'
              }`}
            >
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 cursor-pointer rounded-[4px] text-emerald-500 focus:outline-none focus:ring-0 focus:ring-offset-0"
                value={option.value}
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleOnChange(option.value)}
              />
              <span className="ml-2 text-gray-700">{option.label}</span>
            </label>
          ),
        )}
        {selectedOptions.includes('other') && (
          <Controller
            name="otherInterest"
            control={control}
            render={({ field: { onChange, onBlur, value, ref, name } }) => (
              <Input
                title=""
                placeholder="Tell us what you&#39;re looking for..."
                width="w-full"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                errorMessage={inputErrorMessage}
                name={name}
              />
            )}
          />
        )}
      </div>
      {errorMessage ? (
        <span className="mt-[4px] block text-sm text-red-500">{errorMessage}</span>
      ) : (
        subtitle && <span className="mt-[4px] block text-sm text-gray-400">{subtitle}</span>
      )}
    </>
  );
};

export default CheckBoxGroup;
