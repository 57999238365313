import { twMerge } from 'tailwind-merge';

import { LoaderComponent } from '@/components';
import PublicHeader from '@/components/shared/Header/PublicHeader';
import { CustomizationModal } from '@/modules/auth/modals';

import GetStart from './components/GetStart/GetStart';
import WhatTodo from './components/WhatToDo/WhatToDo';
import useAccountSetup from './useAccountSetup';

const AccountSetup = () => {
  const {
    shouldShowCustomizationModal,
    customizationModalCancelHandler,
    onSaveCustomizationHandler,
    customizationOptions,
    isSavingCustomization,
    firstName,
    bannerVariantToShow,
    isLoading,
  } = useAccountSetup();

  if (isLoading) {
    return (
      <div className="flex h-dvh items-center justify-center">
        <LoaderComponent />
      </div>
    );
  }

  return (
    <>
      <PublicHeader showProfile />
      <div className={twMerge('flex flex-col lg:flex-row ', bannerVariantToShow ? 'pt-14' : '')}>
        <GetStart firstName={firstName} />
        <WhatTodo />
      </div>
      <CustomizationModal
        isOpen={shouldShowCustomizationModal}
        onCancel={customizationModalCancelHandler}
        onSaveClickHandler={onSaveCustomizationHandler}
        options={customizationOptions}
        isSaving={isSavingCustomization}
      />
    </>
  );
};

export default AccountSetup;
