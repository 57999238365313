import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Dispatch } from '@/redux/store';

const usePaymentMethod = () => {
  const dispatch = useDispatch<Dispatch>();
  const [isStripeSelected, setIsStripeSelected] = useState(false);
  const [isPaypalLoading, setIsPaypalLoading] = useState(false);

  const handlePayPalClick = async () => {
    setIsPaypalLoading(true);
    const response = await dispatch.auth.setupPayPallToken();
    setIsPaypalLoading(false);
    window.location.assign(response.approveUrl);
  };

  const handleStripeClick = () => {
    setIsStripeSelected(true);
  };

  const cancelStripeCardHandler = () => {
    setIsStripeSelected(false);
  };

  return {
    handleStripeClick,
    handlePayPalClick,
    isStripeSelected,
    isPaypalLoading,
    cancelStripeCardHandler,
  };
};
export default usePaymentMethod;
