import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { tv } from 'tailwind-variants';
import { InfoAlertProps } from './types';

export const infoAlertVariants = tv({
  base: 'w-full max-w-full px-2  items-center flex gap-2 py-2 px-8  text-xs leading-4',
  variants: {
    variant: {
      success: 'bg-emerald-500 text-white',
      primary: 'bg-blue-500 text-white',
      warning: 'bg-yellow-100 text-white',
      error: 'bg-red-500 text-white',
    },
  },
});

const InfoAlert: FC<InfoAlertProps> = ({ variant, className, children }) => {
  return (
    <div className={infoAlertVariants({ variant, className })}>
      <InformationCircleIcon className="size-6" /> {children}
    </div>
  );
};

export default InfoAlert;
