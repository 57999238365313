import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { CheckListCardType } from './type';

const CheckListCard = ({ text }: CheckListCardType) => {
  return (
    <div className="flex items-center gap-3 rounded-lg bg-white p-3">
      <CheckCircleIcon className="size-5 flex-none  fill-emerald-500" /> <h6>{text}</h6>
    </div>
  );
};

export default CheckListCard;
