import { ReactNode } from 'react';

import Button from '@/components/Button/Button';

import { twMerge } from 'tailwind-merge';
import SubscriptionPlan from '../SubscriptionPlan/SubscriptionPlan';
import { SubscriptionPlanType } from '../types';

type SubscriptionPlanGroupProps = {
  plans: SubscriptionPlanType[];
  selectedPlan: string | null;
  recommendedPlan: string | null;
  onSelectSubscriptionPlan: (plan: string) => void;
  confirmPlanHandler: () => void;
  onCancel: () => void;
  choosePlanTitle: string;
  subTitle?: string | ReactNode;
  subTitleStyle?: string;
};

const SubscriptionPlanGroup = ({
  plans,
  selectedPlan,
  onSelectSubscriptionPlan,
  recommendedPlan,
  confirmPlanHandler,
  choosePlanTitle = 'Your free trial is over!',
  subTitle,
  onCancel,
  subTitleStyle,
}: SubscriptionPlanGroupProps) => {
  return (
    <div className="flex h-full flex-col justify-center gap-5">
      <div>
        <h2 className="font-F37Bolton-Medium text-2xl sm:text-2xl lg:text-4xl">
          {choosePlanTitle}
        </h2>
        {subTitle && (
          <p className={twMerge('text-sm sm:text-xl lg:text-base', subTitleStyle)}>{subTitle}</p>
        )}
      </div>
      <div className="flex flex-col gap-3">
        {plans.map(plan => (
          <SubscriptionPlan
            key={plan.name}
            {...plan}
            isSelected={plan.name === selectedPlan}
            isRecommended={recommendedPlan === plan.name}
            amount={plan.price}
            planName={plan.name}
            maxEmployee={plan.metadata?.maxSeats ?? 0}
            pricingStrategy={'monthly'}
            onSelectSubscriptionPlan={() => !plan.isDisabled && onSelectSubscriptionPlan(plan.name)}
          />
        ))}
      </div>
      <div className="flex gap-3 lg:hidden">
        <Button variant="tertiary" parentClasses="w-full py-2.5" height="h-auto" onPress={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          parentClasses="w-full py-2.5"
          height="h-auto"
          onPress={() => confirmPlanHandler()}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlanGroup;
