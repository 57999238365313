import { RefObject, forwardRef } from 'react';
import { Text } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';

import { InputCheckboxFieldProps } from './types';

const InputCheckboxField = forwardRef(
  (
    {
      onChange,
      value,
      label,
      inputClasses,
      parentClasses,
      wrapperClasses,
      errorMessage,
      customOnChange,
      ...rest
    }: InputCheckboxFieldProps,
    ref,
  ) => {
    return (
      <div className={`${wrapperClasses ? wrapperClasses : ''}`}>
        <label className={`flex items-center ${parentClasses ? parentClasses : ''}`}>
          <input
            type="checkbox"
            className={twMerge(
              `mr-[8px] mt-1 cursor-pointer rounded-[4px]  text-emerald-500 focus:outline-none  focus:ring-0 focus:ring-offset-0`,
              rest.disabled && 'border-gray-200',
              inputClasses,
            )}
            onChange={e => {
              if (onChange) {
                onChange(e);
              }
              if (customOnChange) {
                customOnChange(e);
              }
            }}
            checked={!!value}
            {...rest}
            ref={ref as RefObject<HTMLInputElement>}
          />
          {label}
        </label>
        {errorMessage && (
          <Text slot="description" className={`text-[14px] text-red-500`}>
            {errorMessage}
          </Text>
        )}
      </div>
    );
  },
);

InputCheckboxField.displayName = 'InputCheckboxField';

export default InputCheckboxField;
