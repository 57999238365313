import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useImperativeHandle } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FieldType, useBusinessProfileFormType } from './types';
import { BusinessProfileValidation } from './validations';

const businessTypes: Record<string, number> = {
  'Sole proprietorship': 1,
  'Limited Partnership': 2,
  'Limited Liability Company (LLC)': 3,
  'C-Corporation': 4,
  'S-Corporation': 5,
  Other: 6,
};

const useBusinessProfileForm = ({
  onSaveBusinessProfile,
  initialValues,
  ref,
}: useBusinessProfileFormType) => {
  const numberOfEmployees = initialValues?.numberOfEmployees;

  const form = useForm({
    defaultValues: {
      businessType: null,
      industry: null,
      chooseEinOrSsn: 1,
      ein: null,
      ssn: null,
    },
    resolver: yupResolver(BusinessProfileValidation),
  });

  const { control, watch, reset } = form;

  const canChooseEinOrSsn = Number(numberOfEmployees) === 0;

  useEffect(() => {
    let selectedType = null;

    for (const type in businessTypes) {
      if (initialValues?.businessType && initialValues?.businessType === businessTypes[type]) {
        selectedType = type;
      }
    }

    reset({
      businessType: selectedType,
      industry: initialValues?.industry,
      chooseEinOrSsn: initialValues?.chooseEinOrSsn ? initialValues.chooseEinOrSsn : 1,
      ein: initialValues?.ein,
      ssn: initialValues?.ssn,
    });
  }, [initialValues, canChooseEinOrSsn]);

  const isEinSelected = watch('chooseEinOrSsn') === 1;

  const onSubmit: SubmitHandler<FieldType> = values => {
    let selectedType = null;

    for (const type in businessTypes) {
      if (values.businessType === type) {
        selectedType = businessTypes[type];
      }
    }
    const formattedData = {
      ...values,
      ein: values.chooseEinOrSsn === 1 ? values.ein : null,
      ssn: values.chooseEinOrSsn === 2 ? values.ssn : null,
      businessType: selectedType ?? 6,
    };

    onSaveBusinessProfile(formattedData);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      form.handleSubmit(onSubmit)();
    },
  }));

  return { control, form, onSubmit, isEinSelected, canChooseEinOrSsn };
};

export default useBusinessProfileForm;
