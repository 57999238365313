interface MembersListInitialsProps {
  list: Array<Record<string, any>>;
}

const MembersListInitials = ({ list }: MembersListInitialsProps) => (
  <div className="flex">
    {list?.map(({ id, firstName, lastName, contractorType, businessName }, index) => {
      const zIndex = `z-${1 + index}`;
      return (
        <div
          key={id || index}
          className={`flex h-9 w-9 items-center justify-center rounded-full border border-light-brown bg-darkblue-100 font-F37Bolton-Medium text-sm uppercase ${
            index === 0 ? '' : '-ms-2'
          } ${zIndex}`}
        >
          {index === 3 && list.length > 4
            ? `+${list.length - 3}`
            : contractorType === 1
            ? `${firstName?.[0] || ''}${lastName?.[0] || ''}`
            : `${businessName?.[0] || ''}`}
        </div>
      );
    })}
  </div>
);

export default MembersListInitials;
