import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useQueryHandlersProps } from './types';

const useQueryHandlers = <T>({ mutationParams, invalidateKeys }: useQueryHandlersProps<T>) => {
  const queryClient = useQueryClient();
  const onSuccess = (response: AxiosResponse<T>, variables: Partial<T>, context: unknown) => {
    if (mutationParams?.onSuccess) {
      mutationParams?.onSuccess(response, variables as T, context);
    }
    invalidateKeys.forEach(key => queryClient.invalidateQueries({ queryKey: key }));
  };

  return { onSuccess };
};

export default useQueryHandlers;
