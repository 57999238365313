import AccountBasicInfoForm from '../AccountBasicInfoForm/AccountBasicInfoForm';

interface EditBasicInfoDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  account: any;
  isLoading?: boolean;
  handleSaveInfo: () => void;
}

const EditBasicInfoDrawer = ({
  isOpen,
  onClose,
  account,
  handleSaveInfo,
  ...rest
}: EditBasicInfoDrawerProps) => {
  return (
    <AccountBasicInfoForm
      isOpen={isOpen}
      onClose={onClose}
      userInfo={account}
      handleSaveInfo={handleSaveInfo}
      {...rest}
    />
  );
};

export default EditBasicInfoDrawer;
