import Lottie from 'lottie-react';

import loaderPage from '@/assets/lottie/loader.json';
const Loader = () => {
  return (
    <div className="fixed inset-y-0 left-0 z-[1000] flex w-[100%] items-center justify-center bg-white/30 backdrop-blur-sm">
      <Lottie animationData={loaderPage} style={{ height: 100 }} loop={true} />
    </div>
  );
};

export default Loader;
