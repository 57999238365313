import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { patchCall } from '@/api/axios';
import useQueryHandlers from '@/hooks/useQueryHandlers/useQueryHandlers';
import { Account } from '@/redux/dto/auth';

import { mutationEndpoints } from './endpoints';

type AccountResponse = AxiosResponse<Account>;
type PartialAccountType = Partial<Account>;

const useUpdateAccountInfo = ({
  accountId,
  mutationParams,
}: {
  accountId: number;
  mutationParams?: UseMutationOptions<AccountResponse, Error, PartialAccountType>;
}) => {
  const { mutationKey, url, invalidateKeys } = mutationEndpoints.updateAccountInfo(accountId);

  const { onSuccess } = useQueryHandlers({
    invalidateKeys,
    mutationParams,
  });

  return useMutation<AccountResponse, Error, PartialAccountType>({
    mutationKey,
    mutationFn: data => patchCall(url, data),
    ...mutationParams,
    onSuccess,
  });
};

export default useUpdateAccountInfo;
