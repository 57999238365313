import moment from 'moment';

// function to get last payment date from the receipt from the FastSpring/ Stripe/ PayPal
export const getPaymentDateFromReceipt = (receiptDetail: any) => {
  if (receiptDetail) {
    // Payment using Stripe
    if (receiptDetail.client_secret && receiptDetail.confirmation_method) {
      return receiptDetail.created ? moment(receiptDetail.created * 1000).toISOString() : null;
    }
    // Payment using Paypal
    else if (receiptDetail.payment_source && receiptDetail.purchase_units) {
      const purchaseUnit = Array.isArray(receiptDetail.purchase_units)
        ? receiptDetail.purchase_units[0]
        : null;

      const capture = Array.isArray(purchaseUnit?.payments?.captures)
        ? purchaseUnit.payments.captures[0]
        : null;

      const createTime = capture?.create_time;
      return createTime;
    }
    // Payment using the FastSpring
    else if (receiptDetail.orders?.length && receiptDetail.payment) {
      return receiptDetail?.changed ? receiptDetail.changed : null;
    }
  } else {
    return null;
  }
};
